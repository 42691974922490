import React, { useMemo, useState } from 'react';
import { SelectedIdleValue } from '../type';
import { useAppSelector, useAsync, useTranslation } from '@hooks';
import useModal from '../../../util/useModal';
import useMultiOptionsByRole from '../../util/useMultiOptionsByRole';
import { postAssetRental } from '@api/am/management';
import { OptionType } from '@components/type';
import styled from 'styled-components';
import { Button, Label, Modal, QuestionTooltip, Select } from '@components';
import { SingleValue } from 'react-select';
import DatePickerGroupAM from '../../Components/DatePickerGroupAM';
import moment from 'moment';
import lentImage from '@asset/images/asset-lent.png';
import returnImage from '@asset/images/asset-return.png';
import GeofenceStatus from './GeofenceStatus';
import useRangeDate from '../../../util/useRangeDate';

interface Props {
    handleClear: () => void;
    options: SelectedIdleValue;
}

interface UserWard {
    groupName: string;
    groupNum: number;
}

const IdleRentalRequest = ({ handleClear, options }: Props) => {
    const t = useTranslation('AssetManagement');
    const [isOpen, handleToggle, handleOpen] = useModal();
    const [successModal, setSuccessModal] = useState(false);
    const toggleSuccessModal = () => setSuccessModal(prev => !prev);

    const { categoryList } = useAppSelector(state => state.CategoryInfo);

    //대여부서
    const { userWardList } = useMultiOptionsByRole();
    const userWardOptions = userWardList?.map(({ groupNum, groupName }: UserWard) => ({
        value: groupNum,
        label: groupName,
    }));
    const [selectedUserWard, setSelectedUserWard] = useState(userWardOptions?.length ? userWardOptions[0] : null);
    const [selectedWard, setSelectedWard] = useState<OptionType>({});

    const selectedCategory = useMemo(() => {
        const { categoryName, categoryCode } = categoryList.find(v => v.categoryCode === options.categoryCode) ?? {};

        return {
            label: categoryName,
            value: categoryCode,
        };
    }, [categoryList]);

    const wardOptions = options.wards.map(({ ownDepNum, ownDepName }) => ({
        value: ownDepNum,
        label: ownDepName,
    }));

    const { startDate, handleStartChange, endDate, handleEndChange, handleDateReset } = useRangeDate({
        subStart: 0,
        subEnd: -5,
    });

    const { promise: saveAssetRental } = useAsync({
        promise: postAssetRental,
        param: {},
        resolve: () => {
            toggleSuccessModal();
        },
    });

    const handleSaveClick = () => {
        if (String(selectedUserWard?.value) === String(selectedWard?.value)) {
            handleOpen();
            return;
        }

        saveAssetRental({
            categoryCode: selectedCategory.value,
            requestStartDate: startDate,
            requestEndDate: endDate,
            applyDepNum: selectedUserWard?.value,
            ownDepNum: selectedWard?.value,
        });
    };
    const handleUserWardOption = (selected: SingleValue<OptionType>) => {
        setSelectedUserWard(selected);
    };

    const handleSelectedWard = (selected: any) => {
        setSelectedWard(selected);
    };

    const handleStartTimeChange = (date: Date) => {
        const endDate = moment(date).add(5, 'days');
        handleStartChange(date);
        handleEndChange(endDate.toDate());
    };

    return (
        <>
            <Wrapper>
                <div className={'flx-col justify-content-center gap-3'}>
                    <Label
                        name={t('Ward')}
                        labelGroupClassName={'mb-3'}
                        value={
                            <Select
                                options={userWardOptions}
                                value={selectedUserWard}
                                onChange={handleUserWardOption}
                                disabled={userWardOptions?.length === 1}
                            />
                        }
                    />
                    <Label
                        name={t('Category Name')}
                        labelGroupClassName="mb-3"
                        value={
                            <Select
                                value={selectedCategory}
                                options={[selectedCategory]}
                                onChange={selected => {}}
                                disabled={true}
                            />
                        }
                    />
                    <Label
                        name={
                            <>
                                <span>{t('Owner Ward')}</span>
                                <QuestionTooltip
                                    contents={
                                        <div className="text-start">
                                            {t(
                                                'Displays the departments in which the selected category assets are available for rental.',
                                            )}
                                        </div>
                                    }
                                />
                            </>
                        }
                        labelGroupClassName="mb-3"
                        value={
                            <Select
                                value={selectedWard}
                                options={wardOptions}
                                onChange={handleSelectedWard}
                                disabled={!selectedCategory?.value}
                            />
                        }
                    />
                    {selectedCategory.value && wardOptions.length === 0 && (
                        <Label
                            labelGroupClassName="mb-3"
                            value={
                                <span className="pnt-txt s-9 color-primary">
                                    {t('There are no departments available for rental.')}
                                </span>
                            }
                        />
                    )}
                    <Label
                        name={t('Rental Period')}
                        labelGroupClassName="mb-3"
                        value={
                            <DatePickerGroupAM
                                startDate={startDate}
                                handleStartChange={handleStartTimeChange}
                                endDate={endDate}
                                handleEndChange={handleEndChange}
                                minStartDate={moment().valueOf()}
                            />
                        }
                    />
                </div>
                <GeofenceStatus floorId={options.floor} fcNum={options.fcNum} />
            </Wrapper>
            <div className="d-flex gap-2 justify-content-center h-auto">
                <Button className="btn-gray" onClick={handleClear}>
                    {t('Cancel')}
                </Button>
                <Button
                    className="btn-gray"
                    disabled={!selectedWard?.value || !selectedUserWard?.value}
                    onClick={handleSaveClick}
                >
                    {t('Save')}
                </Button>
            </div>
            <ImageContainer>
                <img src={lentImage} alt="lent-example" height={350} />
                <img src={returnImage} alt="return-exmaple" height={350} />
            </ImageContainer>
            {isOpen && (
                <Modal
                    headerTitle={t('Notification')}
                    removeCancel
                    initModal={isOpen}
                    toggleModal={handleToggle}
                    bodyText={t('The application department and the owned department cannot be the same.')}
                />
            )}
            <Modal
                headerTitle={t('Notification')}
                removeCancel
                initModal={successModal}
                toggleModal={toggleSuccessModal}
                bodyText={t('Your application has been completed.')}
                okCallback={() => handleClear()}
            />
        </>
    );
};

export default IdleRentalRequest;

const ImageContainer = styled.div`
    width: 100%;
    margin-top: 1rem;
    display: flex;

    & > img {
        width: 50%;
    }
`;

const Wrapper = styled.div`
    display: grid;
    height: 100%;
    grid-template-columns: 1fr 1fr;
`;
