export const geofenceFakeDataByPermission = [
    {
        fcName: '회의실',
        fcNum: 10244,
        permitted: 33,
        restricted: 5,
    },
    {
        fcName: '대회의실',
        fcNum: 10240,
        permitted: 20,
        restricted: 5,
    },
    {
        fcName: '프론트엔드',
        fcNum: 10244,
        permitted: 15,
        restricted: 5,
    },
    {
        fcName: '백엔드',
        fcNum: 10244,
        permitted: 9,
        restricted: 5,
    },
];

export const geofenceFakeDataByCategory = [
    {
        fcName: '회의실',
        fcNum: 10244,
        categoryInfo: [
            {
                categoryName: 'PEOPLE',
                categoryCount: 16,
            },
            {
                categoryName: 'ASSET',
                categoryCount: 10,
            },
            {
                categoryName: 'SENSOR',
                categoryCount: 5,
            },
            {
                categoryName: 'EUNJI',
                categoryCount: 1,
            },
        ],
    },
    {
        fcName: '대회의실',
        fcNum: 10244,
        categoryInfo: [
            {
                categoryName: 'PEOPLE',
                categoryCount: 15,
            },
            {
                categoryName: 'ASSET',
                categoryCount: 11,
            },
            {
                categoryName: 'SENSOR',
                categoryCount: 2,
            },
        ],
    },
    {
        fcName: '화장실',
        fcNum: 10244,
        categoryInfo: [
            {
                categoryName: 'PEOPLE',
                categoryCount: 10,
            },
            {
                categoryName: 'ASSET',
                categoryCount: 8,
            },
            {
                categoryName: 'SENSOR',
                categoryCount: 1,
            },
        ],
    },
];
