import { TagKey } from '../tags';

/**
 *
 * 파라미터 값에 falsy한 값을 빼주기 위한 함수
 * @param {object} searchRequirement 파라미터에 필요한 값
 * @param {boolean} useIsAll 파라미터 값이 없을 경우 isAll 보낼것인지에 대한 여부
 * @return {object} 파라미터 값 리턴
 * @author create by 정진범
 */
export const makeParameter = ({
    searchRequirement,
    useIsAll = false,
}: {
    searchRequirement: any;
    useIsAll: boolean;
}): any => {
    const param: any = {};
    for (const property in searchRequirement) {
        if (searchRequirement[property]) {
            param[property] = searchRequirement[property];
        }
    }
    if (useIsAll) {
        if (Object.keys(param).length === 0) {
            param['isAll'] = 'Y';
        }
    }
    return param;
};

/**
 * category property 정보를 사용자가 인식 가능한 데이터로 변환
 *
 * @param target 변경할 카테고리 속성 정보
 * @param base 카테고리 속성의 기본값
 * @returns {{[p: string]: any}}
 */
export const convertProperties = (target: any, base: any) => {
    return Object.fromEntries(
        base.map(({ propertyId, inputValues }: { propertyId: string; inputValues: any[] }) => {
            if (!target[propertyId]) {
                return [propertyId, null];
            }
            if (!inputValues.length) {
                return [propertyId, target[propertyId]];
            }
            // 속성값 타입이 다중인 경우
            const findValue = inputValues.find(({ value: inputValue }) => inputValue === target[propertyId]);
            if (!findValue) {
                return [propertyId, null];
            }
            return [propertyId, findValue.name];
        }),
    );
};

export const addServiceTag = (tags: TagKey[] | undefined = [], additionalTag: TagKey) => {
    return [additionalTag, ...tags];
};
