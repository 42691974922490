import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from '@util/type/util';

type ScreenMode = 'monitoring' | 'edit' | 'preview';

interface ScreenInfo {
    mode: Nullable<ScreenMode>;
    fullScreen: boolean;
    hidden: boolean;
    pubData: { [id: string]: any };
    validatedPubDataList: { [id: string]: any };
}

export const SCREEN_MODE_MONITORING = 'monitoring';
export const SCREEN_MODE_EDIT = 'edit';
export const SCREEN_MODE_PREVIEW = 'preview';

export const isMonitoring = (mode: ScreenMode | null) => {
    return mode === SCREEN_MODE_MONITORING;
};

export const isEditing = (mode: ScreenMode | null) => {
    return mode === SCREEN_MODE_EDIT;
};

const initialState: ScreenInfo = {
    mode: null,
    fullScreen: false,
    hidden: false,

    // 화면의 위젯들이 공유한 데이터
    pubData: {},

    // 화면의 위젯들이 공유하기로 설정한 데이터 목록(실제 위젯에서 데이터가 공유되었는지 체크함)
    validatedPubDataList: {},
};

const { actions, reducer } = createSlice({
    name: 'screenInfo',
    initialState,
    reducers: {
        setMode: (state, action: PayloadAction<Nullable<ScreenMode>>) => {
            state.mode = action.payload;
        },
        setFullScreen: (state, action: PayloadAction<boolean>) => {
            state.fullScreen = action.payload;
        },
        setHidden: (state, action: PayloadAction<boolean>) => {
            state.hidden = action.payload;
        },
        setPubData: (state, action) => {
            const {
                pubDataInfo: { id, pubData },
                publishableDataInfo,
            } = action.payload;
            state.pubData[id] = pubData;
            state.validatedPubDataList[id] = publishableDataInfo;
        },
        clearPubData: (state, action) => {
            const { id } = action.payload;
            state.pubData[id] = null;
            delete state.pubData[id];

            state.validatedPubDataList[id] = null;
            delete state.validatedPubDataList[id];
        },
    },
});

export const { setMode, setFullScreen, setHidden, setPubData, clearPubData } = actions;
export default reducer;
