import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Button, ConfirmModal, Modal, Table, TextInput } from '@components';
import { ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { useAsync, useColumns, useConfirm, useTranslation } from '@hooks';
import { deleteDeviceMappingApi, getTargetInfoApi } from '@api/sh/deviceManagement';
import { MODEL_CODE, SENSOR_TYPE, SENSOR_TYPE_TO_NAME, SensorType } from '@util/staticData/sensorMeta';
import * as col from './columns';
import DeviceSearchModal from './DeviceSearchModal';
import sensor_guide from '@asset/images/sensingItem-guide_ko.webp';
import { Table as SimpleTable, TableBody, TableHead, Td, Th, Tr } from '@components/Table/SimpleTable';
import NoDataBlock from '../../../../Components/NoDataBlock';
import styled from 'styled-components';
import {
    getSensorRangeInfoApi,
    SensorRangeInfo,
    updateSensorRangeInfoApi,
} from '@api/sh/biometricInformationMonitoring';
import { NullableNumber } from '@util/type/util';

type DeviceType = 'SENSOR' | 'SCANNER' | 'BEACON';

interface Props {
    isOpen: boolean;
    toggleModal: () => void;
    targetNum: number;
}

const SENSOR = 'SENSOR';

type SensorRange = Omit<SensorRangeInfo, 'sensorNum' | 'modelCode'>;
type SensorRangeKey = keyof SensorRange;

const RANGE_ORDER: SensorRangeKey[] = [
    'minRangeLimit', // 경고 최소값 (범위 최저값)
    'minRiskLimit', // 주의 최소값 (위험 최저값)
    'minControlLimit', // 정상 최소값 (정상 최저값)
    'maxControlLimit', // 정상 최대값 (정상 최대값)
    'maxRiskLimit', // 주의 최대값 (위험 최대값)
    'maxRangeLimit', // 경고 최대값 (범위 최대값)
];

const RANGE_KEY_TO_LABEL: Record<SensorRangeKey, string> = {
    minRangeLimit: 'Min Range Limit', // 경고 최소값 (범위 최저값)
    minRiskLimit: 'Min Risk Limit', // 주의 최소값 (위험 최저값)
    minControlLimit: 'Min Control Limit', // 정상 최소값 (정상 최저값)
    maxControlLimit: 'Max Control Limit', // 정상 최대값 (정상 최대값)
    maxRiskLimit: 'Max Risk Limit', // 주의 최대값 (위험 최대값)
    maxRangeLimit: 'Max Range Limit', // 경고 최대값 (범위 최대값)
};

// 7 = th + 센서 범위 td 갯수
const INPUT_BORDER = 2;
const CELL_HEIGHT = 30;
const HEADER_HEIGHT = 43; // 세줄(최대)
const SENSOR_VALUE_TABLE_HEIGHT = {
    height: CELL_HEIGHT * 6 + INPUT_BORDER + HEADER_HEIGHT,
};

const SENSOR_TYPE_ORDER = [
    SENSOR_TYPE.HEARTBEAT,
    SENSOR_TYPE.RESPIRATION_RATE,
    SENSOR_TYPE.RESPIRATION_RATE2,
    SENSOR_TYPE.TEMPERATURE,
] as const;
type UsageSensorType = typeof SENSOR_TYPE_ORDER[number];

const CUSTOM_SENSOR_TYPE_TO_NAME: { [key in SensorType]?: string } = {
    [SENSOR_TYPE.TEMPERATURE]: 'Body Temperature',
    [SENSOR_TYPE.RESPIRATION_RATE]: 'Respiration Rate 0 Condition',
    [SENSOR_TYPE.RESPIRATION_RATE2]: 'Respiration Rate 1 Condition',
};

const defaultSensorRange = {
    minRangeLimit: {
        HEARTBEAT: 0,
        RESPIRATION_RATE: 0,
        RESPIRATION_RATE2: 0,
        TEMPERATURE: 0,
    },
    minRiskLimit: {
        HEARTBEAT: 0,
        RESPIRATION_RATE: 0,
        RESPIRATION_RATE2: 0,
        TEMPERATURE: 0,
    },
    minControlLimit: {
        HEARTBEAT: 0,
        RESPIRATION_RATE: 0,
        RESPIRATION_RATE2: 0,
        TEMPERATURE: 0,
    },
    maxControlLimit: {
        HEARTBEAT: 0,
        RESPIRATION_RATE: 0,
        RESPIRATION_RATE2: 0,
        TEMPERATURE: 0,
    },
    maxRiskLimit: {
        HEARTBEAT: 0,
        RESPIRATION_RATE: 0,
        RESPIRATION_RATE2: 0,
        TEMPERATURE: 0,
    },
    maxRangeLimit: {
        HEARTBEAT: 0,
        RESPIRATION_RATE: 0,
        RESPIRATION_RATE2: 0,
        TEMPERATURE: 0,
    },
};

const DeviceInfoModal = ({ isOpen, toggleModal, targetNum }: Props) => {
    const t = useTranslation('DeviceMappingManagement');
    const [showDeviceSearchModal, setShowDeviceSearchModal] = useState(false);
    const [originalSensorRange, setOriginalSensorRange] = useState<SensorRange>(defaultSensorRange);
    const [sensorRange, setSensorRange] = useState<SensorRange>(defaultSensorRange);
    const [selectedDeviceNum, setSelectedDeviceNum] = useState<NullableNumber>(null);

    const unMappingModalProps = useConfirm({
        initModal: false,
        confirmText: (
            <div>
                <div>{t('Do you want to unmap?')}</div>
                <div>{t('If you unmap, you will no longer be able to monitor biometrics.')}</div>
            </div>
        ),
        okCallback: () => {
            deleteDeviceMapping({ targetNum, sensorNumList: selectedDeviceNum });
        },
    });

    const confirmUnmappingModalProps = useConfirm({
        initModal: false,
        confirmText: (
            <div>
                <div>{t('The mapping is unmapped.')}</div>
                <div>{t('To remap, search for your device and select the device you want to map.')}</div>
            </div>
        ),
        removeCancel: true,
        okCallback: () => {
            // 상세정보 리프레쉬
            getTargetInfo();
        },
    });

    const confirmUpdateRangeSetting = useConfirm({
        initModal: false,
        confirmText: t('Do you want to save your settings?'),
        okCallback: () => {
            updateSensorRangeInfo({ sensorNum: selectedDeviceNum!, targetNum, ...sensorRange });
        },
    });

    const successUpdateRangeSetting = useConfirm({
        initModal: false,
        confirmText: t('Save is complete.'),
        removeCancel: true,
        okCallback: () => {
            toggleModal();
        },
    });

    const [rangeUpdateErrorMsg, setRangeUpdateErrorMsg] = useState('The save failed.');
    const failureUpdateRangeSetting = useConfirm({
        initModal: false,
        confirmText: t(rangeUpdateErrorMsg),
        removeCancel: true,
    });

    // 대상 정보 상세 조회
    const { promise: getTargetInfo, state: targetInfo } = useAsync({
        promise: getTargetInfoApi,
        fixedParam: {
            modelCodeList: [MODEL_CODE.MEZOO_SMARTPATCH],
            targetNum,
            sensorNum: selectedDeviceNum ?? undefined,
        },
        keepState: true,
        immediate: isOpen, // 초기 렌더링 시 API 호출 방지(모달을 열었을 때만 실행시키기 위함)
        deps: [isOpen],
    });

    const { wardName, room, bed, targetName } = targetInfo?.response || {
        wardName: null,
        room: null,
        bed: null,
        targetName: null,
    };

    const targetDeviceList = useMemo(() => {
        if (targetInfo?.response && targetInfo.response.targetDevice) {
            return targetInfo.response.targetDevice.filter(
                ({ deviceType }: { deviceType: DeviceType }) => deviceType === SENSOR,
            );
        }
        return [];
    }, [targetInfo]);

    useEffect(() => {
        setSelectedDeviceNum(targetDeviceList[0]?.deviceNum);
    }, [targetDeviceList]);

    // 언매핑 API
    const { promise: deleteDeviceMapping } = useAsync({
        promise: deleteDeviceMappingApi,
        resolve: () => {
            confirmUnmappingModalProps.toggleModal();
        },
    });

    const { promise: getSensorRangeInfo } = useAsync({
        promise: getSensorRangeInfoApi,
        fixedParam: {
            modelCodeList: [MODEL_CODE.MEZOO_SMARTPATCH],
            targetNum,
        },
        resolve: response => {
            const { sensorNum, modelCode, ...restInfo } = response;
            setSensorRange(restInfo);
            setOriginalSensorRange(restInfo);
        },
    });

    useEffect(() => {
        if (selectedDeviceNum) {
            getSensorRangeInfo({
                sensorNum: selectedDeviceNum,
            });
        }
    }, [selectedDeviceNum]);

    const { promise: updateSensorRangeInfo } = useAsync({
        promise: updateSensorRangeInfoApi,
        resolve: () => {
            successUpdateRangeSetting.toggleModal();
        },
        reject: error => {
            const { data } = error;
            if (data?.errorResponse && data.code === '1104') {
                setRangeUpdateErrorMsg('The save failed. Please check the range of sensor items.');
            } else {
                setRangeUpdateErrorMsg('The save failed.');
            }
            failureUpdateRangeSetting.toggleModal();
        },
    });

    const deviceColumns = useColumns(
        [
            col.deviceName(),
            col.actions({
                Cell: () => {
                    return (
                        <Button
                            className="btn-icon-only btn-trans br-5 icon-big"
                            iconName={'link_off'}
                            onClick={() => {
                                unMappingModalProps.toggleModal();
                            }}
                        />
                    );
                },
            }),
        ],
        t,
        [],
    );

    const handleChangeInput = (e: ChangeEvent<HTMLInputElement>, rangeKey: SensorRangeKey, sensorType: SensorType) => {
        const { value } = e.target;
        if (/^-?\d*(\.\d?)?$/.test(value)) {
            setSensorRange({ ...sensorRange, [rangeKey]: { ...sensorRange[rangeKey], [sensorType]: value } });
        }
    };

    const handleBlurInput = (e: ChangeEvent<HTMLInputElement>, rangeKey: SensorRangeKey, sensorType: SensorType) => {
        const { value } = e.target;
        const validValue = String(Number(value));
        if (/^-?\d+(\.\d?)?$/.test(validValue)) {
            setSensorRange({
                ...sensorRange,
                [rangeKey]: { ...sensorRange[rangeKey], [sensorType]: Number(validValue) },
            });
        } else {
            setSensorRange({
                ...sensorRange,
                [rangeKey]: { ...sensorRange[rangeKey], [sensorType]: originalSensorRange[rangeKey][sensorType] },
            });
        }
    };

    return (
        <>
            <Modal
                initModal={isOpen}
                toggleModal={toggleModal}
                removeModalClose={false}
                headerTitle={<div>{t('Biometric monitoring device info')}</div>}
                bodyText={
                    <div className={'h-100'}>
                        <div className={'d-flex flex-column gap-1 mb-3'}>
                            <div className={'pnt-txt txt-dot txt-bold s-6'}>{t('Patient information')}</div>
                            <div className={'d-flex ml-2 pl-1'}>
                                <span className={'w-30'}>{t('Ward/Room/Bed')}</span>
                                <span>
                                    {wardName || '-'} / {room || '-'} / {bed || '-'}
                                </span>
                            </div>
                            <div className={'d-flex ml-2 pl-1'}>
                                <span className={'w-30'}>{t('Patient name')}</span>
                                <span>{targetName || '-'}</span>
                            </div>
                        </div>
                        <div className={'d-flex justify-content-between mb-2'}>
                            <div className={'pnt-txt txt-dot txt-bold s-6'}>{t('Device information')}</div>
                            <Button
                                className={'btn-secondary'}
                                onClick={() => {
                                    setShowDeviceSearchModal(true);
                                }}
                                disabled={!!targetDeviceList.length}
                            >
                                {t('Search device')}
                            </Button>
                        </div>
                        <div className={'mb-2'} style={{ height: '80px' }}>
                            <Table columns={deviceColumns} data={{ rows: targetDeviceList }} paging={false} />
                        </div>
                        <div>
                            <div className={'d-flex gap-1 mb-1'}>
                                <span className={'pnt-txt txt-dot s-8 w-auto'}>{t('Range Setting')}</span>
                                <div className={'d-flex align-items-center'}>
                                    <span
                                        id={'tooltip_range_help'}
                                        className="material-icons-round md-18 cursor-default"
                                    >
                                        help
                                    </span>
                                    <CustomQuestionTooltip target={'tooltip_range_help'} placement={'right'}>
                                        <div className={'d-flex flex-column'}>
                                            <div className={'d-flex justify-content-center tooltip-title'}>
                                                {t('Help')}
                                            </div>
                                            <img alt={t('Sensor Range Guide')} src={sensor_guide} width={700} />
                                        </div>
                                    </CustomQuestionTooltip>
                                </div>
                            </div>
                            <div className={'mb-1'} style={SENSOR_VALUE_TABLE_HEIGHT}>
                                {!!selectedDeviceNum ? (
                                    <div className={'h-100'}>
                                        <RangeSettingTable>
                                            <TableHead>
                                                <Tr>
                                                    <Th className={'justify-content-center p-0 flex-2'}>
                                                        {t('Range')}
                                                    </Th>
                                                    {SENSOR_TYPE_ORDER.map(sensorType => {
                                                        return (
                                                            <Th
                                                                key={sensorType}
                                                                className={'justify-content-center p-0 text-center'}
                                                            >
                                                                {t(
                                                                    CUSTOM_SENSOR_TYPE_TO_NAME[sensorType] ??
                                                                        SENSOR_TYPE_TO_NAME[sensorType] ??
                                                                        sensorType,
                                                                    'SensingType',
                                                                )}
                                                            </Th>
                                                        );
                                                    })}
                                                </Tr>
                                            </TableHead>
                                            <TableBody
                                                style={{
                                                    height: `${SENSOR_VALUE_TABLE_HEIGHT.height - CELL_HEIGHT}px`,
                                                }}
                                            >
                                                <div>
                                                    {RANGE_ORDER.map(rangeKey => {
                                                        return (
                                                            <Tr key={rangeKey}>
                                                                <Td className={'justify-content-center p-0 flex-2'}>
                                                                    {t(RANGE_KEY_TO_LABEL[rangeKey])}
                                                                </Td>
                                                                {SENSOR_TYPE_ORDER.map(sensorType => {
                                                                    return (
                                                                        <Td key={sensorType} className={'p-0'}>
                                                                            <TextInput
                                                                                name={rangeKey}
                                                                                className={'text-center'}
                                                                                maxlength={13}
                                                                                inputGroupClassName={'table-input'}
                                                                                value={String(
                                                                                    sensorRange[rangeKey][sensorType] ??
                                                                                        0,
                                                                                )}
                                                                                handleChange={e => {
                                                                                    handleChangeInput(
                                                                                        e,
                                                                                        rangeKey,
                                                                                        sensorType,
                                                                                    );
                                                                                }}
                                                                                handleBlur={e => {
                                                                                    handleBlurInput(
                                                                                        e,
                                                                                        rangeKey,
                                                                                        sensorType,
                                                                                    );
                                                                                }}
                                                                                style={{ width: '100%' }}
                                                                            />
                                                                        </Td>
                                                                    );
                                                                })}
                                                            </Tr>
                                                        );
                                                    })}
                                                </div>
                                            </TableBody>
                                        </RangeSettingTable>
                                    </div>
                                ) : (
                                    <NoDataBlock text={t('Please map your device first.')} />
                                )}
                            </div>
                            {!!selectedDeviceNum && (
                                <div>
                                    <small>
                                        * {t('Respiration Rate 0 Condition', 'SensingType')}:{' '}
                                        {t('Resting and lying down')}
                                    </small>
                                    <br />
                                    <small>
                                        * {t('Respiration Rate 1 Condition', 'SensingType')}:{' '}
                                        {t('Taking a break and are sitting or standing')}
                                    </small>
                                </div>
                            )}
                        </div>
                    </div>
                }
                modalFooter={
                    <ModalFooter className={'d-flex justify-content-end'}>
                        <Button
                            className={'btn-gray'}
                            onClick={() => {
                                toggleModal();
                            }}
                        >
                            {t('Close', 'Button')}
                        </Button>
                        {!!selectedDeviceNum && (
                            <Button
                                className={'btn-secondary'}
                                onClick={() => {
                                    confirmUpdateRangeSetting.toggleModal();
                                }}
                            >
                                {t('Save range settings')}
                            </Button>
                        )}
                    </ModalFooter>
                }
            />
            <ConfirmModal {...unMappingModalProps} />
            <ConfirmModal {...confirmUnmappingModalProps} />
            <ConfirmModal {...confirmUpdateRangeSetting} />
            <ConfirmModal {...successUpdateRangeSetting} />
            <ConfirmModal {...failureUpdateRangeSetting} />
            <DeviceSearchModal
                isOpen={showDeviceSearchModal}
                targetNum={targetNum}
                toggleModal={() => {
                    setShowDeviceSearchModal(prev => !prev);
                }}
                closeCallback={() => {
                    getTargetInfo();
                }}
            />
        </>
    );
};

const CustomQuestionTooltip = styled(UncontrolledTooltip)`
    .tooltip-title {
        background: gray;
    }
    .tooltip-inner {
        max-width: none;
        padding: 0;
    }
    .tooltip.show {
        opacity: 1;
    }
`;

const RangeSettingTable = styled(SimpleTable)`
    .table-input {
        min-width: unset;
        width: 100%;
        height: 100%;

        input {
            border: none !important;
            color: inherit !important;
        }

        & input:focus {
            outline: 1px #c6c6ff solid !important;
            border: 1px solid #c6c6ff !important;
        }
    }

    .flex-2 {
        flex: 2 !important;
    }

    .table-input.pnt-input--group:not(.form-disable) input.pnt-input {
        background-color: transparent !important;
    }

    .table-input.form-disable.pnt-input--group input.pnt-input {
        filter: grayscale(1);
    }
`;

export default DeviceInfoModal;
