import React, { useEffect, useMemo } from 'react';
import cx from 'classnames';
import { FilterList } from '../../../../../MainPages/Components/FilterSearchGroup/Components/Part';
import FilterSearchGroup from '../../../../../MainPages/Components/FilterSearchGroup';
import FilterGroup from '../../../../../MainPages/Components/FilterSearchGroup/Components/FilterGroup';
import { useAppSelector, useAsync, useTranslation } from '@hooks';
import SearchableSelect from '@components/Select/SearchableSelect';
import {
    setMonitoringFilter,
    useDeviceMappingManagementDispatchContext,
    useDeviceMappingManagementStateContext,
} from '../../slice';
import { getRoomListApi } from '@api/sh/deviceManagement';

const DeviceMappingManagementFilterGroup = () => {
    const t = useTranslation('Common');
    const dispatch = useDeviceMappingManagementDispatchContext();
    const { monitoringFilter } = useDeviceMappingManagementStateContext();

    const userInfo: { groupNums?: number[] } = useAppSelector(state => state.UserInfo.userInfo);
    const wardList = useAppSelector(state =>
        Object.entries(state.DepartmentsInfo.wardMapInfo).map(([wardNum, wardName]) => ({ wardNum, wardName })),
    );

    const {
        state: { response: roomListInfo },
    } = useAsync({
        promise: getRoomListApi,
        fixedParam: { wardList: monitoringFilter.wardList ?? [] },
        deps: [monitoringFilter.wardList],
        immediate: true,
    });

    const roomList = useMemo(() => {
        return (roomListInfo?.rows ?? []).reduce(
            (acc: { roomNum: string; roomName: string }[], roomInfo: { ward: string; room: string }) => {
                if (
                    !monitoringFilter.wardList ||
                    !monitoringFilter.wardList.length ||
                    monitoringFilter.wardList?.includes(roomInfo.ward)
                ) {
                    acc.push({
                        roomNum: roomInfo.room,
                        roomName: roomInfo.room,
                    });
                }
                return acc;
            },
            [],
        );
    }, [roomListInfo, monitoringFilter.wardList]);

    useEffect(() => {
        if (userInfo.groupNums && userInfo.groupNums.length) {
            dispatch(setMonitoringFilter({ wardList: userInfo.groupNums.map(groupNum => groupNum.toString()) }));
        }
    }, [userInfo]);

    useEffect(() => {
        if (roomListInfo?.rows) {
            dispatch(
                setMonitoringFilter({
                    roomList: roomList.map(({ roomNum }: { roomNum: string }) => roomNum),
                }),
            );
        }
    }, [roomList]);

    return (
        <FilterSearchGroup className={cx('absolute-filter', 'w-100')}>
            <FilterGroup className={''} label={t('Filter', 'Filter')}>
                <FilterList className={''}>
                    <SearchableSelect
                        data={wardList}
                        labelKey={'wardName'}
                        valueKey={'wardNum'}
                        selected={monitoringFilter.wardList}
                        onChange={selected => {
                            dispatch(
                                setMonitoringFilter({
                                    wardList: (selected ?? []).map((ward: { wardNum: string }) => ward.wardNum),
                                }),
                            );
                        }}
                    />
                    <SearchableSelect
                        data={roomList}
                        labelKey={'roomName'}
                        valueKey={'roomNum'}
                        selected={monitoringFilter.roomList}
                        onChange={selected => {
                            dispatch(
                                setMonitoringFilter({
                                    roomList: (selected ?? []).map((room: { roomNum: string }) => room.roomNum),
                                }),
                            );
                        }}
                    />
                </FilterList>
            </FilterGroup>
        </FilterSearchGroup>
    );
};

export default DeviceMappingManagementFilterGroup;
