import React, { forwardRef, useContext, useMemo, useState } from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { ConfirmModal } from '@components';
import { Card as RSCard, CardHeader } from 'reactstrap';
import { getUsableWidgetInfo } from '../../MainPages/Components/Screen/util';
import { useTranslation } from '@hooks';
import { useSettings } from '../util/useSettings';
import { SCREEN_MODE_EDIT } from '@reducer/ScreenInfo';
import { deleteWidget } from '../../MainPages/Settings/ScreenManagement/ScreenEdit/reducer';
import { ScreenEditDispatchContext } from '../../MainPages/Settings/ScreenManagement/ScreenEdit';
import Button from '@components/Button';
import widgetList from '../index';
import PerfectScrollbar from 'react-perfect-scrollbar';
import WidgetSettingModal from './WidgetSettingModal';
import { WIDGET_TYPE_TEMP } from './TemporaryWidget';

const HeaderButton = ({ children, ...restProps }) => {
    return (
        <Button className="btn-lightgray btn-icon-only ml-2" iconName="settings" {...restProps}>
            {children}
        </Button>
    );
};

const WidgetCard = (
    {
        children,
        subTitle,
        widgetInfo,
        headerAction,
        className,
        bodyClassName,
        handleGoBackClick,
        searchFilter,
        ...restProps
    },
    ref,
) => {
    children = [...children];
    const { id, type, config, name } = widgetInfo;
    const t = useTranslation('Widget');
    const resizeHandler = children.pop();
    const { settings: customSettings } = config;
    const settings = useSettings(config);
    const { SettingComponent } = getUsableWidgetInfo(type);
    const dispatch = useContext(ScreenEditDispatchContext);
    const { mode } = useSelector(state => state.ScreenInfo);

    const thumbnail = useMemo(
        () => Object.values(widgetList).find(e => e.name === widgetInfo.name)?.thumbnailClassName,
        [widgetInfo],
    );

    const [deleteModal, setDeleteModal] = useState(false);
    const toggleDeleteModal = () => {
        setDeleteModal(!deleteModal);
    };
    const handleDelete = e => {
        dispatch(deleteWidget(id));
    };

    const [settingModal, setSettingModal] = useState(false);
    const toggleSettingModal = () => {
        setSettingModal(!settingModal);
    };

    const displayName = () => {
        if (customSettings && customSettings.name && customSettings.name !== t(`Widget;${name}`)) {
            return customSettings.name;
        } else {
            return type !== WIDGET_TYPE_TEMP ? t(name) : t('New widget');
        }
    };

    return (
        <>
            <RSCard {...restProps} className={cx(className, 'widget-card')} innerRef={ref}>
                <CardHeader
                    className={cx(
                        'widget-header',
                        mode !== SCREEN_MODE_EDIT && settings.hiddenHeader && 'hidden-header',
                        !!headerAction && 'action-header',
                    )}
                >
                    <div className={'card-header__title'}>
                        {thumbnail && <span className={cx(thumbnail)} />}
                        <div className={'title-wrap'}>
                            <h3 className={'title__main'}>{displayName()}</h3>
                            {!!subTitle && <p className={'title__sub'}>{subTitle}</p>}
                        </div>
                    </div>
                    {(headerAction || handleGoBackClick) && (
                        <div className={'card-header__function'}>
                            {headerAction}
                            {handleGoBackClick && (
                                <Button className={'btn-gray'} onClick={handleGoBackClick}>
                                    {t('Back', 'Button')}
                                </Button>
                            )}
                        </div>
                    )}
                    {mode === SCREEN_MODE_EDIT && (
                        <div className={'widget-edit-btn'}>
                            {type !== WIDGET_TYPE_TEMP && <HeaderButton onClick={toggleSettingModal}></HeaderButton>}
                            <Button
                                className="btn-lightgray btn-icon-only ml-2"
                                iconName="close"
                                onClick={toggleDeleteModal}
                            />
                        </div>
                    )}
                </CardHeader>
                <div className={'widget-search'}>{searchFilter}</div>
                <PerfectScrollbar className={cx('card-body', bodyClassName)}>{children}</PerfectScrollbar>
                {resizeHandler}
            </RSCard>
            {mode === SCREEN_MODE_EDIT && (
                <ConfirmModal
                    initModal={deleteModal}
                    toggleModal={toggleDeleteModal}
                    header={{ title: t('Confirm', 'ConfirmModal') }}
                    confirmText={t('Do you want to delete the widget?')}
                    okCallback={handleDelete}
                />
            )}
            {SettingComponent ? (
                <SettingComponent widgetInfo={widgetInfo} modal={settingModal} toggleModal={toggleSettingModal} />
            ) : (
                <WidgetSettingModal widgetInfo={widgetInfo} modal={settingModal} toggleModal={toggleSettingModal} />
            )}
        </>
    );
};

export default forwardRef(WidgetCard);
