import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { Table } from '@components';
import { usePatientsTableColumns } from './columns';
import useTranslation from '@hooks/useTranslation';
import {
    DisplayedMonitoringPatient,
    MonitoringGroup,
    setSelectedPatient,
    useBiometricInformationMonitoringDispatchContext,
} from '../../slice';

const TABLE_HEADER_HEIGHT = 34;
const TABLE_ROW_HEIGHT = 35;
const TABLE_MAX_HEIGHT = 210;

export const getTableHeight = (patients: DisplayedMonitoringPatient[]) => {
    return Math.min(patients.length * TABLE_ROW_HEIGHT + TABLE_HEADER_HEIGHT, TABLE_MAX_HEIGHT);
};

const PatientsTable = ({ roomName, wardName, patients, style }: MonitoringGroup & { style?: CSSProperties }) => {
    const t = useTranslation('BiometricInformationMonitoring');
    const columns = usePatientsTableColumns();
    const dispatch = useBiometricInformationMonitoringDispatchContext();
    return (
        <div style={style}>
            <TableTitle>
                <TitleLabel>{t('Ward')}</TitleLabel>
                <TitleValue>{wardName}</TitleValue>
                <TitleLabel>{t('Room')}</TitleLabel>
                <TitleValue>{roomName}</TitleValue>
            </TableTitle>
            <TableWrapper style={{ height: getTableHeight(patients) + 'px' }}>
                <Table
                    paging={false}
                    columns={columns}
                    data={{ rows: patients }}
                    onTrClick={patient => {
                        dispatch(setSelectedPatient(patient));
                    }}
                />
            </TableWrapper>
        </div>
    );
};

const TableTitle = styled.div`
    display: grid;
    grid-gap: 0.25rem;
    grid-template-columns: repeat(4, minmax(50px, max-content));
    font-size: 0.8rem;
`;

const TitleLabel = styled.span`
    text-align: center;
    font-weight: bold;
    background-color: var(--dark-gray);
    color: var(--depth-1) !important;
    margin-bottom: 0.5rem;
    padding: 0 0.5rem;
`;

const TitleValue = styled.span`
    text-align: center;
    font-weight: bold;
    background-color: var(--light-gray);
    color: var(--depth-1);
    margin-bottom: 0.5rem;
    padding: 0 0.5rem;
`;

const TableWrapper = styled.div`
    max-height: ${TABLE_MAX_HEIGHT}px;
    width: 100%;
`;

export default PatientsTable;
