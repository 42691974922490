import React, { useEffect, useState } from 'react';
import Card from '../../Components/WidgetCard';
import List from './Component/List';
import Search from './Component/Search';
import { useAppSelector, useAsync } from '@hooks';
import { getLoginLogApi, getLoginLogListApi } from '@api/common/log';
import { getApiURL } from '@api/index';

/**
 *
 * 로그인 이력 위젯
 *
 * useAsync 커스텀 후 리팩토링 예정
 *
 * @author jinbeom-jung
 * */

export const LOGIN_TYPE = {
    oAuth: 'oAuth',
    API: 'api',
};
const LoginHistoryLog = ({ children, widgetInfo, ...restProps }) => {
    const [loginLogData, setLoginLogData] = useState({ totalCount: 0, totalPage: 1, rows: [] });
    const [loginType, setLoginType] = useState(null);
    const { userInfo } = useAppSelector(state => state.UserInfo);

    const { promise: getoAuthLogin, state: oAuthLoginInfo } = useAsync({
        promise: getLoginLogListApi,
        fixedParam: { pageSize: 15, userID: userInfo.userID, page: 1 },
        resolve: res => setLoginLogData(res),
        reject: err => console.error(err),
    });

    const { promise: getLoginLogList, state: apiLoginInfo } = useAsync({
        promise: getLoginLogApi,
        fixedParam: { pageSize: 15, userID: userInfo.userID, page: 1 },
        resolve: res => setLoginLogData(res),
        reject: err => console.error(err),
    });

    useEffect(() => {
        getApiURL().then(key => {
            if (key.hasOwnProperty('companyUUID') && key.hasOwnProperty('companyToken')) {
                setLoginType(LOGIN_TYPE.API);
                getLoginLogList();
            } else {
                setLoginType(LOGIN_TYPE.oAuth);
                getoAuthLogin();
            }
        });
    }, []);

    const loginLogListInfo = loginType === LOGIN_TYPE.oAuth ? oAuthLoginInfo : apiLoginInfo;
    const promise = loginType === LOGIN_TYPE.oAuth ? getoAuthLogin : getLoginLogList;

    return (
        <Card widgetInfo={widgetInfo} {...restProps}>
            <Search promise={promise} loginType={loginType} />
            <List list={loginLogData} state={loginLogListInfo} promise={promise} loginType={loginType} />
            {children}
        </Card>
    );
};

export default LoginHistoryLog;
