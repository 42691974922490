import React from 'react';
import { useSensorStatusContext, useSensorStatusDispatch } from '../../../../../index';
import { Button, Label } from '@components';
import { useTranslation } from '@hooks';
import { openLocationPopup } from '../../../../../sensorStatusSlice';

const SensorInfo = () => {
    const t = useTranslation('SensorStatus');
    const dispatch = useSensorStatusDispatch();
    const {
        selectedSensor: { targetId, targetName, sensorName, lat, lng, sensorId },
    } = useSensorStatusContext();
    const handleSeeLocationClick = () => {
        dispatch(openLocationPopup());
    };

    return (
        <div className={'flx-col gap-4 pnt-label-5 sensor-report-item'}>
            <Label name={t('Target Id', 'CommonColumn')} value={targetId} />
            <Label name={t('Target Name', 'CommonColumn')} value={targetName} labelGroupClassName={'sensor-name'} />
            <Label name={t('Sensor Mac Address', 'CommonColumn')} value={sensorId} />
            <Label name={t('Sensor Name', 'CommonColumn')} value={sensorName} />
            {!!lat && !!lng && (
                <Label
                    name={t('Location', 'CommonColumn')}
                    value={
                        <Button onClick={handleSeeLocationClick} className={'btn-brand'}>
                            {t('See Location', 'RealTimeSensorMonitoring')}
                        </Button>
                    }
                />
            )}
        </div>
    );
};

export default SensorInfo;
