import React from 'react';
import { FilterList } from '../../../MainPages/Components/FilterSearchGroup/Components/Part';
import FilterGroup from '../../../MainPages/Components/FilterSearchGroup/Components/FilterGroup';
import FilterSearchGroup from '../../../MainPages/Components/FilterSearchGroup';
import { Button, DatePicker, SearchableSelect, Select } from '@components';
import { useTranslation } from '@hooks';
import cx from 'classnames';
import DatePickerGroupAM from './DatePickerGroupAM';

const FilterSearchableSelect = ({ options }) => {
    return <SearchableSelect {...options} />;
};

const FilterDatePickerGroup = ({ options }) => {
    const { startDate, handleStartChange, endDate, handleEndChange } = options;

    return (
        <DatePickerGroupAM
            startDate={startDate}
            handleStartChange={handleStartChange}
            endDate={endDate}
            handleEndChange={handleEndChange}
        />
    );
};

const FilterDatePicker = ({ date, renderDayContents, onChange, onMonthChange, calendarContainer }) => {
    return (
        <DatePicker
            value={date}
            renderDayContents={renderDayContents}
            onChange={onChange}
            onMonthChange={onMonthChange}
            calendarContainer={calendarContainer}
            withoutTime
            valueType="s"
            disabledKeyboardNavigation
        />
    );
};

const FilterButton = ({ onClick, children }) => {
    return (
        <Button className="ml-1 btn-secondary" onClick={onClick}>
            {children}
        </Button>
    );
};

const FilterSelect = ({ options }) => {
    const { value, options: selectOptions, onChange } = options;
    return <Select value={value} options={selectOptions} onChange={onChange} />;
};

const FilterLabel = ({ text, className = '' }) => {
    return <span className={cx('filter-label', className)}>{text}</span>;
};

const FilterMain = ({ children }) => {
    const t = useTranslation();

    return (
        <FilterSearchGroup className={cx('absolute-filter')}>
            <FilterGroup label={t('Filter', 'Filter')}>
                <FilterList>{children}</FilterList>
            </FilterGroup>
        </FilterSearchGroup>
    );
};

const Filter = Object.assign(FilterMain, {
    SearchableSelect: FilterSearchableSelect,
    DatePickerGroup: FilterDatePickerGroup,
    DatePicker: FilterDatePicker,
    Button: FilterButton,
    Select: FilterSelect,
    Label: FilterLabel,
});

export default Filter;
