import React, { useContext, useEffect } from 'react';
import Card from './WidgetCard';
import { useTranslation } from '@hooks';
import { ScreenEditDispatchContext } from '../../MainPages/Settings/ScreenManagement/ScreenEdit';
import { setTempWidgetLayout } from '../../MainPages/Settings/ScreenManagement/ScreenEdit/reducer';
import Button from '@components/Button';
import styled from 'styled-components';
import cx from 'classnames';

export const WIDGET_TYPE_TEMP = 'temp';

const TemporaryWidget = ({ children, widgetInfo, ...restProps }) => {
    const { type, layout } = widgetInfo;
    const dispatch = useContext(ScreenEditDispatchContext);
    const t = useTranslation('Widget');
    const handleAddWidget = e => {
        dispatch(setTempWidgetLayout(layout));
    };

    useEffect(() => {
        if (type === WIDGET_TYPE_TEMP) {
            const layoutContainer = document.querySelector('.app-page-content');
            if (layoutContainer) {
                layoutContainer.scrollTo({ top: layoutContainer.scrollHeight, behavior: 'smooth' });
            }
        }
    }, [type]);

    return type === WIDGET_TYPE_TEMP ? (
        <Card widgetInfo={widgetInfo} {...restProps} className={cx(restProps?.className, 'temp-widget')}>
            <AddInnerContainer className={'flex-center h-100'}>
                <Button className="btn-brand btn-icon" iconName="add" onClick={handleAddWidget}>
                    {t('Add Widget')}
                </Button>
            </AddInnerContainer>
            {children}
        </Card>
    ) : (
        <Card widgetInfo={widgetInfo} {...restProps}>
            <NotSupportInnerContainer className={'flex-center'}>
                {t('This is not supported widget.')}
            </NotSupportInnerContainer>
            {children}
        </Card>
    );
};

const AddInnerContainer = styled.div`
    border: 1px dashed #ffb930;
    color: #777;
`;

const NotSupportInnerContainer = styled.div`
    height: 95%;
    color: #777;
    font-size: 1.25rem;
`;

export default TemporaryWidget;
