import React, { useContext, useState } from 'react';
import { useSettings } from '../util/useSettings';
import { WidgetName, HiddenHeader, SubscribedData, HeightOptimization } from './WidgetSettingOptions';
import Modal from '@components/Modal';
import useTranslation from '../../../util/hooks/useTranslation';
import { editWidgetSettings } from '../../MainPages/Settings/ScreenManagement/ScreenEdit/reducer';
import { ScreenEditDispatchContext } from '../../MainPages/Settings/ScreenManagement/ScreenEdit';
import WidgetSettingValidationModal from './WidgetSettingValidationModal';

const WidgetSettingModal = ({
    modal = false,
    toggleModal = function () {
        modal = !modal;
    },
    widgetInfo,
    socketWidget,
    headerTitle,
    okCallback,
    cancelCallback,
    intervalTimeOptions,
    children,
    ...restProps
}) => {
    const dispatch = useContext(ScreenEditDispatchContext);
    const t = useTranslation('Widget');
    const { config } = widgetInfo;
    const settings = useSettings(config);
    const { settings: customSettings, defaultSettings } = config;
    const defaultHeaderTitle = t(widgetInfo.name) + ' ' + t('Setting');
    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState(customSettings || {});
    const [validation, setValidation] = useState(true);

    const [validationModalOpen, setValidationModalOpen] = useState(false);
    const toggleValidationModal = () => {
        setValidationModalOpen(!validationModalOpen);
    };

    const handleWidgetNameChange = name => {
        setValidation(!!(name && name.trim()));
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, name: name };
        });
    };

    const handleHiddenHeaderChange = checkedHiddenHeader => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, hiddenHeader: checkedHiddenHeader };
        });
    };

    const handleHeightOptimizationChange = checked => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, heightOptimization: checked };
        });
    };

    const handleSubDataChange = ({ widgetId, checked }) => {
        setUpdatedWidgetInfo(prevState => {
            return {
                ...prevState,
                subData: { ...prevState.subData, [widgetId]: checked },
            };
        });
    };

    const handleSubmitClick = data => {
        const checkedSettings = customSettings ? { ...customSettings, ...data } : data;

        const updatedWidgetSettings = {
            id: widgetInfo.id,
            settings: checkedSettings,
        };

        dispatch(editWidgetSettings(updatedWidgetSettings));
    };

    const handleCancelClick = () => {
        if (cancelCallback) {
            cancelCallback();
        }
        setUpdatedWidgetInfo(customSettings || {});
    };

    const handleSave = widgetInfo => {
        const checkName = updatedWidgetInfo.name || settings.name;
        // 이름을 직접 수정 했는데 빈 문자열 아닌 경우
        const confirmName =
            (updatedWidgetInfo.name !== '' || (updatedWidgetInfo.name && updatedWidgetInfo.name.trim() !== '')) &&
            checkName &&
            checkName.trim();
        if (!confirmName) {
            toggleValidationModal();
            return;
        }
        if (okCallback && typeof okCallback === 'function') {
            okCallback(widgetInfo);
            return;
        }
        handleSubmitClick(widgetInfo);
    };

    return (
        <>
            <Modal
                initModal={modal}
                toggleModal={toggleModal}
                headerTitle={headerTitle ?? defaultHeaderTitle}
                okValidation={validation}
                okCallback={handleSave}
                callbackParam={updatedWidgetInfo}
                cancelCallback={handleCancelClick}
                {...restProps}
            >
                <div className={'modal-body'}>
                    <div className={'flx-col gap-4 pnt-label-5'}>
                        <WidgetName
                            value={
                                updatedWidgetInfo.name !== undefined
                                    ? updatedWidgetInfo.name
                                    : customSettings && customSettings.name
                                    ? customSettings.name
                                    : t(`${defaultSettings.name}`)
                            }
                            onChange={handleWidgetNameChange}
                        />

                        {/*{!socketWidget && (*/}
                        {/*    <IntervalTime*/}
                        {/*        value={*/}
                        {/*            updatedWidgetInfo.intervalTime*/}
                        {/*                ? updatedWidgetInfo.intervalTime*/}
                        {/*                : settings.intervalTime*/}
                        {/*        }*/}
                        {/*        onChange={handleIntervalTimeChange}*/}
                        {/*        options={intervalTimeOptions ? intervalTimeOptions : null}*/}
                        {/*    />*/}
                        {/*)}*/}

                        <HiddenHeader
                            checked={
                                typeof updatedWidgetInfo.hiddenHeader !== 'undefined'
                                    ? updatedWidgetInfo.hiddenHeader
                                    : customSettings && typeof customSettings.hiddenHeader !== 'undefined'
                                    ? customSettings.hiddenHeader
                                    : false
                            }
                            onChange={handleHiddenHeaderChange}
                        />
                        {settings.heightOptimizable && (
                            <HeightOptimization
                                checked={settings.heightOptimization}
                                onChange={handleHeightOptimizationChange}
                            />
                        )}
                        {children}
                    </div>
                    <SubscribedData
                        widgetId={widgetInfo.id}
                        selected={updatedWidgetInfo.subData}
                        onChange={handleSubDataChange}
                    />
                </div>
            </Modal>
            <WidgetSettingValidationModal initModal={validationModalOpen} toggleModal={toggleValidationModal} />
        </>
    );
};

export default WidgetSettingModal;
