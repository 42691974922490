import React from 'react';
import { Button } from '@components';
import styled from 'styled-components';

interface Props {
    deviceName: string;
    onRemoveClick: () => void;
}

const DeviceInfo = ({ deviceName = '', onRemoveClick }: Props) => {
    return (
        <DeviceInfoWrapper className={'bg-secondary br-1'}>
            <div className={'device text-ellipsis color-white'} title={deviceName} id={deviceName}>
                <span className={'device-name pnt-txt s-8'}>{deviceName}</span>
            </div>
            <Button className="btn-icon-only btn-trans" iconName={'close'} onClick={onRemoveClick} />
        </DeviceInfoWrapper>
    );
};

export default DeviceInfo;

const DeviceInfoWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 100px;
    width: 200px;
    .device {
        vertical-align: baseline;
        text-align: left;
        width: fit-content;
        .device-name {
            color: white !important;
            margin-left: 0.5rem;
        }
    }
`;
