import React, { useMemo, useRef, useState } from 'react';
import { WidgetProps } from '../../staticInfo';
import { useAppSelector, useTranslation } from '@hooks';
import useMultiOptionsByRole from '../util/useMultiOptionsByRole';
import useCategoryList from '../util/useCategoryList';
import WidgetCard from '../../Components/WidgetCard';
import { isDeepEqual } from '@util/common/util';
import { PROPERTY_ID } from '../../constants';
import { OptionType } from '@components/type';
import Filter from '../Components/Filter';
import useAsync from '@hooks/useAsync';
import { getIdleAssetCount } from '@api/am/management';
import IdleEquipmentList from './Components/IdleEquipmentList';
import { FloorInfo } from '@api/common/floor';

const DEFAULT_EQUIPMENT_LIST = {
    pageSize: 15,
    totalCount: 0,
    totalPage: 1,
    rows: [],
};

const ASSET_CODE = 'ASSET';

const IdleEquipmentRequest = ({ children, widgetInfo, ...restProps }: WidgetProps) => {
    const widgetRef = useRef(null);
    const t = useTranslation('IdleEquipment');
    const [selectedFloor, setSelectedFloor] = useState<string[]>([]);

    const { allWardOptions: wardOptions, selectedWardList } = useMultiOptionsByRole();
    const { categoryOptions, selectedCategoryList, handleCategoryChange } = useCategoryList();
    const { floorList } = useAppSelector(state => state.FloorInfo);

    const handleFloorChange = (selected: FloorInfo[]) => {
        const isEqual = isDeepEqual(
            selectedFloor,
            selected.map((v: FloorInfo) => v.floorId),
        );
        if (isEqual) return;
        setSelectedFloor(selected.map((v: FloorInfo) => v.floorId));
    };

    const filterInfo = useMemo(() => {
        return {
            categoryCodes:
                selectedCategoryList.length !== 0
                    ? selectedCategoryList.toString()
                    : categoryOptions.map((v: OptionType) => v.value).toString(),
            floorIds:
                selectedFloor.length !== 0
                    ? selectedFloor.map(floor => floor).toString()
                    : floorList.map(floor => floor.floorId).toString(),
            targetPropertiesSearch: {
                categoryCode: ASSET_CODE,
                propertySearchs: [
                    {
                        propertyId: PROPERTY_ID.ASSET.WARD,
                        values: wardOptions
                            .map(({ value }: { value: string }) => value)
                            .filter((ward: string) => !selectedWardList.includes(ward))
                            .toString(),
                    },
                ],
            },
        };
    }, [selectedWardList, selectedCategoryList, selectedFloor, wardOptions, floorList]);

    const { state: IdleAsset, promise: getIdleAssets } = useAsync({
        promise: getIdleAssetCount,
        fixedParam: filterInfo,
        immediate: true,
        deps: [selectedCategoryList, selectedFloor, selectedWardList, wardOptions],
    });

    const handlePageChange = (pageIndex: number) => {
        getIdleAssets({
            page: pageIndex,
        });
    };

    const idleAssetList = IdleAsset?.response ?? DEFAULT_EQUIPMENT_LIST;

    return (
        <WidgetCard
            widgetInfo={widgetInfo}
            ref={widgetRef}
            searchFilter={
                <Filter>
                    <Filter.SearchableSelect
                        options={{
                            title: t('category'),
                            data: categoryOptions,
                            selected: selectedCategoryList,
                            onChange: handleCategoryChange,
                        }}
                    />
                    <Filter.SearchableSelect
                        options={{
                            title: t('floor'),
                            data: floorList,
                            selected: selectedFloor,
                            onChange: handleFloorChange,
                            valueKey: 'floorId',
                            labelKey: 'floorName',
                        }}
                    />
                </Filter>
            }
            {...restProps}
        >
            <IdleEquipmentList data={idleAssetList} handlePage={handlePageChange} />
            {children}
        </WidgetCard>
    );
};

export default IdleEquipmentRequest;
