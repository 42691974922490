import React, { useEffect, useRef } from 'react';
import biometricInformationMonitoringSlice, {
    BiometricInformationDashboardDispatchContext,
    BiometricInformationDashboardStateContext,
    setSelectedPatient,
    useBiometricInformationDashboardDispatchContext,
    useBiometricInformationDashboardStateContext,
} from './slice';
import ContextProvider from '@components/ContextProvider';
import { WidgetProps } from '../../staticInfo';
import WidgetCard from '../../Components/WidgetCard';
import Dashboard from './Components/Dashboard';
import Search from './Components/Dashboard/Search';
import PatientDetails from '../Components/PatientDetails';
import Button from '@components/Button';
import { useTranslation } from '@hooks';

const BiometricInformationDashboard = ({ widgetInfo, children, ...restProps }: WidgetProps) => {
    return (
        <ContextProvider
            StateContext={BiometricInformationDashboardStateContext}
            DispatchContext={BiometricInformationDashboardDispatchContext}
            slice={biometricInformationMonitoringSlice}
        >
            <ContextConsumer widgetInfo={widgetInfo} {...restProps}>
                {children}
            </ContextConsumer>
        </ContextProvider>
    );
};

const ContextConsumer = ({ widgetInfo, children, ...restProps }: WidgetProps) => {
    const t = useTranslation('BiometricInformationMonitoring');
    const dispatch = useBiometricInformationDashboardDispatchContext();
    const { selectedPatient } = useBiometricInformationDashboardStateContext();
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (containerRef.current?.parentElement) {
            containerRef.current.parentElement.scrollTop = 0;
        }
    }, [selectedPatient]);

    return (
        <WidgetCard
            widgetInfo={widgetInfo}
            {...restProps}
            headerAction={
                selectedPatient && (
                    <Button className="btn-lightgray" onClick={() => dispatch(setSelectedPatient(null))}>
                        {t('Back', 'Button')}
                    </Button>
                )
            }
            searchFilter={!selectedPatient && <Search />}
        >
            <div className={'w-100 h-100'} ref={containerRef}>
                {selectedPatient ? <PatientDetails targetNum={selectedPatient.targetNum} /> : <Dashboard />}
            </div>
            {children}
        </WidgetCard>
    );
};

export default BiometricInformationDashboard;
