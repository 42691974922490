import React, { createContext, useContext } from 'react';

import IotItemStatusContainer from './IotItemStatusContainer';
import ContextProvider from '@components/ContextProvider';
import IotItemStatusSlice from './iotItemStatusSlice';

export const IotItemStatusStateContext = createContext(null);
export const IotItemStatusDispatchContext = createContext(null);

export const useIotItemStatusContext = () => useContext(IotItemStatusStateContext);

export const useIotItemStatusDispatch = () => useContext(IotItemStatusDispatchContext);

const IotItemStatus = ({ children, widgetInfo, ...restProps }) => {
    return (
        <ContextProvider
            StateContext={IotItemStatusStateContext}
            DispatchContext={IotItemStatusDispatchContext}
            slice={IotItemStatusSlice}
        >
            <IotItemStatusContainer widgetInfo={widgetInfo} children={children} {...restProps} />
        </ContextProvider>
    );
};

export default IotItemStatus;
