import React from 'react';
import styled from 'styled-components';
import Nameplate from '@components/Nameplate';
import {
    CLASSNAME_CRITICAL,
    CLASSNAME_MOVEMENT,
    CLASSNAME_NONE_ACTIVE,
    CLASSNAME_NORMAL,
    CLASSNAME_WARNING,
    getStatusIcon,
    getStatusStyle,
} from '../../util';
import useTranslation from '@hooks/useTranslation';

const Legends = () => {
    const t = useTranslation('BiometricInformationMonitoring');
    return (
        <LegendContainer>
            <Legend className={CLASSNAME_NORMAL} name={t('Normal')} />
            <Legend className={CLASSNAME_WARNING} name={t('Warning')} />
            <Legend className={CLASSNAME_CRITICAL} name={t('Critical')} />
            <Legend className={CLASSNAME_MOVEMENT} name={t('Movement')} />
            <Legend className={CLASSNAME_NONE_ACTIVE} name={t('Signal Anomaly')} />
        </LegendContainer>
    );
};

const LegendContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-content: center;
    gap: 5px;
`;

const Legend = styled(Nameplate)`
    border: 0.75px solid var(--depth-base);
    #root .app-container &.pnt-nameplate {
        ${getStatusStyle('.cont-wrap')};
    }
    #root .app-container &.pnt-nameplate .cont-wrap {
        color: #fff;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 3.75px;

        .status-ico-wrapper {
            display: inline-block;
            width: 13.5px;
            height: 13.5px;
            background-color: var(--base-txt);
            mask-repeat: no-repeat;
            mask-size: cover;
            mask-position: center;
            ${getStatusIcon()}
        }
    }
`;

export default Legends;
