export const defaultCongestionInfo = {
    totalAvgStayEntryTime: 0,
    totalAvgStayUniqueTime: 0,
    totalPermittedEntryCount: 0,
    totalPermittedUniqueCount: 0,
    totalRestrictedEntryCount: 0,
    totalRestrictedUniqueCount: 0,
};

export const defaultSelectedCongestionInfo = {
    avgStayEntryTime: 0,
    avgStayUniqueTime: 0,
    entryCount: 0,
    uniqueCount: 0,
};
