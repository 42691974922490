import { BatteryCell } from './Cells';
import { Column } from '@components/Table/types';

export const battery = (columnProps: Partial<Column> = {}): Column => {
    return {
        Header: 'Battery',
        headerClassName: 'justify-content-center',
        className: 'justify-content-center',
        accessor: 'battery',
        width: 60,
        Cell: BatteryCell,
        ...columnProps,
    };
};
