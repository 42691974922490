import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@hooks';
import SummaryTable from './SummaryTable';
import { SearchWrap, SelectGroup } from '../../../../../MainPages/Components/FilterSearchGroup/Components/Part';
import FilterSearchGroup from '../../../../../MainPages/Components/FilterSearchGroup';
import moment from 'moment';
import useAsync from '@hooks/useAsync';
import { getVitalSignsDetailGraphOfPatronApi } from '@api/monitoring';
import DatePicker from '@components/DatePicker';
import useTranslation from '@hooks/useTranslation';
import Button from '@components/Button';
import styled from 'styled-components';
import { LineChart, LoadingBlock } from '@components';
import { mergeObjects } from '@util/common/util';
import NoDataBlock from '../../../../Components/NoDataBlock';
import SearchGroup from '../../../../../MainPages/Components/FilterSearchGroup/Components/SearchGroup';
import cx from 'classnames';
import TIMES from '@util/times';
import { formatSensorList } from '../../../VitalSignsRealTimePartronPoC/constants';

// 2021-12-26 주간그래프 정보 미진행 -> 추후 클라이언트 요청에 따라 변경 예정
const TAB_ITEMS = [
    {
        key: 'Daily Graph',
        value: 1,
    },
    // {
    //     key: 'Weekly Graph',
    //     value: 7,
    // },
];

const CHART_HEIGHT = 200;

const WHITE_COLOR = '#fff';
const GRID_GRAY_COLOR = '#ccc';
const TOOLTIP_BG_COLOR_DARK = '#4c4c52';
const TOOLTIP_BG_COLOR_NAVY = '#e8e6ef';
const TOOLTIP_TEXT_COLOR = '#565672';
const TEXT_BOLD = 'bold';
const DARK_THEME = 'pnt-dark';

const DEFAULT_CHART_OPTIONS = {
    animation: false,
    layout: {
        padding: {
            top: 0,
            left: 0,
            right: 8,
            bottom: 24,
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            mode: 'index',
            intersect: false,
            animation: { duration: 0 },
            displayColors: false,
            padding: 12,
            titleFont: {
                weight: TEXT_BOLD,
            },
            bodyFont: {
                weight: TEXT_BOLD,
            },
        },
    },
    scales: {
        x: {
            type: 'time',
            time: {
                unit: 'minute',
                tooltipFormat: 'HH:mm:ss',
                displayFormats: { minute: 'HH:mm:ss' },
            },
            display: true,
            grid: {
                borderDash: [4, 4],
                display: true,
                tickLength: 12,
                offset: false,
                color: GRID_GRAY_COLOR,
            },
            ticks: {
                minRotation: 45,
                source: 'labels',
            },
        },
        y: {
            grid: {
                borderWidth: 1,
                borderDash: [4, 4],
                color: GRID_GRAY_COLOR,
            },
            ticks: {
                maxTicksLimit: 5,
            },
        },
    },
    datasets: {
        line: {
            pointRadius: 0,
            pointHoverRadius: 0,
            borderWidth: 2,
            offset: true,
        },
    },
};

// custom tooltip 으로 기존 recharts 툴팁과 유사하게 설정
const customTooltip = (colorScheme, t) => ({
    tooltip: {
        backgroundColor: colorScheme === DARK_THEME ? TOOLTIP_BG_COLOR_DARK : TOOLTIP_BG_COLOR_NAVY,
        titleColor: colorScheme === DARK_THEME ? WHITE_COLOR : TOOLTIP_TEXT_COLOR,
        bodyColor: colorScheme === DARK_THEME ? WHITE_COLOR : TOOLTIP_TEXT_COLOR,
        callbacks: {
            title: tooltipItem => `${t('Measured Value')} ${tooltipItem[0].formattedValue}`,
            label: tooltipItem => `${t('Received Time')} ${tooltipItem.label}`,
        },
    },
});

const ContentView = ({ data }) => {
    const t = useTranslation('VitalSignsRealTimePartron');
    const { colorScheme } = useAppSelector(state => state.ThemeOptions);

    const { targetId } = data;

    const [date, setDate] = useState(moment().unix());
    const getToday = () => moment().format('YYYY-MM-DD');
    const getDate = () => moment.unix(date).format('YYYY-MM-DD');
    const handleChangeDate = date => {
        if (!date) {
            setDate(moment().unix());
            return;
        }
        setDate(date);
    };
    const [sensorList, setSensorList] = useState([]);
    const [summaryInfo, setSummaryInfo] = useState({});

    const [currentTab, setCurrentTab] = useState(0);
    const handleTabItem = idx => {
        setCurrentTab(idx);
    };

    const { promise: getVitalSignsDetailGraph, state: vitalSignsGraph } = useAsync({
        promise: getVitalSignsDetailGraphOfPatronApi,
        resolve: res => {
            const { recentVitalSensor, vitalSensorList } = res;
            setSensorList(formatSensorList(vitalSensorList, t));
            setSummaryInfo(recentVitalSensor);
        },
        reject: err => {
            setSensorList([]);
            console.error(err);
        },
    });

    useEffect(() => {
        const selectedDate = moment.unix(date);
        getVitalSignsDetailGraph({
            targetId,
            startDate: selectedDate.startOf('date').unix(),
            endDate: selectedDate.endOf('date').unix(),
        });
    }, [date]);

    return (
        <div className="w-100">
            <SummaryTable vitalSensor={summaryInfo} />

            <ul className="nav nav-tabs border-light">
                {TAB_ITEMS.map(({ key, value }, idx) => (
                    <li key={`${key}_${idx}`} className="nav-item" onClick={() => handleTabItem(idx)}>
                        <span className={cx('nav-link', currentTab === idx && 'bg-lightgray')}>{t(key)}</span>
                    </li>
                ))}
            </ul>

            <FilterSearchGroup className="px-0">
                <SearchGroup>
                    <SearchWrap>
                        <SelectGroup>
                            <div className={'datePicker-container'}>
                                <DatePicker
                                    value={date}
                                    handleChange={handleChangeDate}
                                    maxDate={moment().valueOf()}
                                    withoutTime
                                />
                            </div>
                        </SelectGroup>
                        <Button
                            className="btn-icon-only btn-lightgray ml-2"
                            iconName="keyboard_arrow_left"
                            onClick={() => handleChangeDate(date - TIMES.DAY_1_PER_SEC)}
                        />
                        <Button
                            className="btn-icon-only btn-lightgray ml-1"
                            iconName="keyboard_arrow_right"
                            onClick={() => handleChangeDate(date + TIMES.DAY_1_PER_SEC)}
                            disabled={getDate() === getToday()}
                        />
                    </SearchWrap>
                </SearchGroup>
            </FilterSearchGroup>

            {sensorList.map(({ name, max, min, data, unit }, index) => (
                <div key={`${name}-${index}`} className="mb-4">
                    <div className="d-flex w-100 mb-2">
                        <span className="pnt-txt s-8 fw-bold">
                            {t(name.charAt(0) + name.substring(1).toLowerCase())}
                        </span>
                        {unit && <span className="pnt-txt ml-1">({unit})</span>}
                    </div>
                    <LoadingBlock blocking={vitalSignsGraph.isLoading}>
                        <div style={{ height: `${CHART_HEIGHT}px` }}>
                            {data && !!data.datasets[0].data.length ? (
                                <LineChart
                                    data={data}
                                    options={mergeObjects(DEFAULT_CHART_OPTIONS, {
                                        scales: {
                                            y: {
                                                min: Math.floor(min * 0.9),
                                                max: Math.floor(max * 1.1),
                                            },
                                        },
                                        plugins: customTooltip(colorScheme, t),
                                    })}
                                />
                            ) : (
                                <NoDataWrapper>
                                    <NoDataBlock />
                                </NoDataWrapper>
                            )}
                        </div>
                    </LoadingBlock>
                </div>
            ))}
        </div>
    );
};

const NoDataWrapper = styled.div`
    height: 200px;
    width: 100%;
    border: 1px solid #eceaf2;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default ContentView;
