import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    targetNums: [],
};

const sensorLogSlice = createSlice({
    name: 'sensorLog',
    initialState,
    reducers: {
        setTargetNums: (state, action) => {
            state.targetNums = action.payload;
        },
        reset: (state, action) => {
            const initState = { ...initialState, ...action.payload };
            for (let key in initState) {
                state[key] = initState[key];
            }
        },
    },
});

export const { setTargetNums, reset } = sensorLogSlice.actions;
export default sensorLogSlice;
