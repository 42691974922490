import { createContext, Dispatch, useContext } from 'react';
import { ActionType } from '@components/ContextProvider';
import { createSlice } from '@reduxjs/toolkit';
import { NullableNumber } from '@util/type/util';

type MonitoringFilter = {
    wardList: string[];
    roomList: string[];
};

export type TargetInfo = {
    targetNum: NullableNumber;
    sensorNum: NullableNumber;
};

const initialState: {
    targetInfo: TargetInfo;
    monitoringFilter: MonitoringFilter;
} = {
    targetInfo: {
        targetNum: null,
        sensorNum: null,
    },
    monitoringFilter: {
        wardList: [],
        roomList: [],
    },
};

export const DeviceMappingManagementStateContext = createContext(initialState);
export const DeviceMappingManagementDispatchContext = createContext<Dispatch<ActionType> | null>(null);

export const useDeviceMappingManagementStateContext = () => useContext(DeviceMappingManagementStateContext);
export const useDeviceMappingManagementDispatchContext = () => useContext(DeviceMappingManagementDispatchContext)!;

const deviceMappingManagementSlice = createSlice({
    name: 'sh/deviceMappingManagement',
    initialState,
    reducers: {
        setTargetInfo: (state, action) => {
            state.targetInfo = { ...state.targetInfo, ...action.payload };
        },
        setMonitoringFilter: (state, action) => {
            const newFilter = action.payload;
            state.monitoringFilter = { ...state.monitoringFilter, ...newFilter };
        },
    },
});

export const { setTargetInfo, setMonitoringFilter } = deviceMappingManagementSlice.actions;

export default deviceMappingManagementSlice;
