import { ReactNode } from 'react';
import { TagKey } from './tags';

const WIDGET_CATEGORY_DASHBOARD = 'DASHBOARD' as const;
const WIDGET_CATEGORY_MONITORING = 'MONITORING' as const;
const WIDGET_CATEGORY_MANUAL_SEARCH = 'MANUAL_SEARCH' as const;
const WIDGET_CATEGORY_TEMP = 'TEMP' as const;

export const WIDGET_CATEGORY_TO_NAME = {
    [WIDGET_CATEGORY_DASHBOARD]: 'Dashboard',
    [WIDGET_CATEGORY_MONITORING]: 'Monitoring',
    [WIDGET_CATEGORY_MANUAL_SEARCH]: 'Manual search',
    [WIDGET_CATEGORY_TEMP]: 'Temp',
};

const WIDGET_SERVICE_CODE_SH = 'SH' as const;
const WIDGET_SERVICE_CODE_AM = 'AM' as const;
const WIDGET_SERVICE_CODE_SO = 'SO' as const;
const WIDGET_SERVICE_CODE_COMMON = 'COMMON' as const;

// 위젯 카테고리 목록
export const WIDGET_CATEGORY = {
    DASHBOARD: WIDGET_CATEGORY_DASHBOARD,
    MONITORING: WIDGET_CATEGORY_MONITORING,
    MANUAL_SEARCH: WIDGET_CATEGORY_MANUAL_SEARCH,
    TEMP: WIDGET_CATEGORY_TEMP,
} as const;

export const SERVICE_CODE = {
    SH: WIDGET_SERVICE_CODE_SH,
    AM: WIDGET_SERVICE_CODE_AM,
    SO: WIDGET_SERVICE_CODE_SO,
    COMMON: WIDGET_SERVICE_CODE_COMMON,
} as const;

type SharableData = {
    dataKey: string;
    dataLabel: string;
};

/**
 * ### 각 위젯들의 정보 Type
 *
 * @author minchan-kim
 */
export default interface Widget {
    type: string;
    category: WidgetCategory;
    name: string;
    desc: string;
    thumbnailClassName: string | null;
    Component: ReactNode;
    SettingComponent: ReactNode;
    config: Config;
    layout: Layout;
    publishableData?: SharableData[];
    serviceCode?: keyof typeof SERVICE_CODE;
    tags?: TagKey[];
}

export type WidgetCategory = typeof WIDGET_CATEGORY[keyof typeof WIDGET_CATEGORY];

export interface Settings {
    name?: string;
    intervalTime?: number;
    categoryCode?: string;
    dataType?: string;
    chartType?: string;
    floor?: string;
    type?: string;
    targetOption?: string;
    tagOption?: number;
    DataInteger?: boolean;
    heightOptimizable?: boolean;
    [key: string]: any;
}

export interface Config {
    defaultSettings?: Settings;
    settings?: Settings;
}

export interface Layout {
    minW: number;
    w: number;
    maxW?: number;
    minH: number;
    h: number;
    maxH?: number;
}

interface WidgetInfo extends Pick<Widget, 'category' | 'config' | 'layout' | 'name' | 'type'> {
    id: string;
}

export interface WidgetProps {
    children: ReactNode;
    widgetInfo: WidgetInfo;
}
