import React, { useContext } from 'react';
import moment from 'moment';

import { Button } from '@components';
import { useTranslation } from '@hooks';

import {
    setEndDate,
    setSelectedCategory,
    setSelectedFloor,
    setSelectedFloorInfo,
    setStartDate,
} from '../hourlyCongestionStatusSlice';
import { useHourlyCongestionStatusDispatch } from '../index';

const SearchConditions = ({ setShowSearchArea, floor }) => {
    const t = useTranslation('Button');
    const dispatch = useHourlyCongestionStatusDispatch();

    const handleSearchAreaClick = () => {
        setShowSearchArea(prev => !prev);
    };

    const handleRefreshClick = () => {
        dispatch(setSelectedCategory(''));
        dispatch(setSelectedFloor(floor.floorId));
        dispatch(setStartDate(moment().startOf('day').valueOf()));
        dispatch(setEndDate(moment().endOf('day').valueOf()));
        dispatch(setSelectedFloorInfo(floor));
    };

    return (
        <>
            <Button className={'btn-darkgray'} onClick={handleSearchAreaClick}>
                {t('Search', 'Button')}
            </Button>
            <div className="pnt-border border-h" />
            <Button className="btn-lightgray btn-icon-only" iconName="refresh" onClick={handleRefreshClick} />
        </>
    );
};

export default SearchConditions;
