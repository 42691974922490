import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getOwnershipListApi } from '@api/common/asset';
import { useAsync } from '@hooks';
import useAdminCheck from './useAdminCheck';

const useMultiOptionsByRole = () => {
    const { userInfo } = useSelector(state => state.UserInfo);
    const { groupNums, groupInfoList } = userInfo;
    const [selectedWardList, setSelectedWardList] = useState(groupNums);
    const [selectedRentalWardList, setSelectedRentalWardList] = useState();
    const isAdmin = useAdminCheck();

    const { state: wardState } = useAsync({
        promise: getOwnershipListApi,
        fixedParam: { isAll: 'Y' },
        immediate: true,
        reject: err => console.error(err),
    });

    const filteredLoginGroupList = wardState.response?.rows.filter(ele => isAdmin || groupNums.includes(ele.groupNum));

    const options = filteredLoginGroupList
        ? filteredLoginGroupList.map(({ groupNum, groupName }) => ({
              value: groupNum,
              label: groupName,
          }))
        : [];

    const allWardOptions = useMemo(
        () =>
            wardState?.response
                ? wardState.response?.rows.map(({ groupNum, groupName }) => ({
                      value: groupNum,
                      label: groupName,
                  }))
                : [],
        [wardState],
    );

    if (wardState.response && !selectedRentalWardList) {
        setSelectedRentalWardList(wardState.response.rows.map(({ groupNum }) => groupNum));
    }

    const handleWardChange = selected => {
        setSelectedWardList(selected.map(v => v.value));
    };

    const handleWardReset = () => {
        setSelectedWardList(groupNums);
    };

    const handleRentalWardChange = selected => {
        setSelectedRentalWardList(selected.map(v => v.value));
    };

    const handleRentalWardReset = () => {
        setSelectedWardList(options.map(({ value }) => value));
    };

    return {
        selectedWardList,
        options,
        allWardOptions,
        handleWardChange,
        userWardList: groupInfoList,
        handleWardReset,
        selectedRentalWardList,
        handleRentalWardChange,
        handleRentalWardReset,
    };
};

export default useMultiOptionsByRole;
