import moment from 'moment';
import { useState } from 'react';

// 해당 날짜에 처음일
const formatStartUnixTime = (unixTime: number) => {
    return moment.unix(unixTime).startOf('day').unix();
};

// 해당 날짜에 말일
const formatEndUnixTime = (unixTime: number) => {
    return moment.unix(unixTime).endOf('day').unix();
};

interface Params {
    subStart?: number;
    subEnd?: number;
}

const useRangeDate = ({ subStart = 7, subEnd = 0 }: Params = {}) => {
    const [startDate, setStartDate] = useState(moment().subtract(subStart, 'days').unix());
    const [endDate, setEndDate] = useState(moment().subtract(subEnd, 'days').unix());

    const handleStartChange = (date: Date) => {
        const unixTime = moment(date).unix();
        setStartDate(unixTime);

        if (unixTime > endDate) {
            setEndDate(unixTime);
            return;
        }
    };

    const handleEndChange = (date: Date) => {
        const unixTime = moment(date).unix();
        setEndDate(unixTime);
    };

    const handleDateReset = () => {
        setStartDate(moment().subtract(subStart, 'days').unix());
        setEndDate(moment().subtract(subEnd, 'days').unix());
    };

    return {
        startDate: formatStartUnixTime(startDate),
        handleStartChange,
        endDate: formatEndUnixTime(endDate),
        handleEndChange,
        handleDateReset,
    };
};

export default useRangeDate;
