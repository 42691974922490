import React, { useContext, useState } from 'react';
import { Checkbox, Label, Select } from '@components';
import { useTranslation } from '@util/hooks';
import { ScreenEditDispatchContext } from '../../../MainPages/Settings/ScreenManagement/ScreenEdit';
import WidgetSettingModal from '../../Components/WidgetSettingModal';
import { editWidgetSettings } from '../../../MainPages/Settings/ScreenManagement/ScreenEdit/reducer';
import { useSettings } from '../../util/useSettings';
import { ASSET_MANAGEMENT } from '../../constants';

const RentalManagementSetting = ({ widgetInfo, ...restProps }) => {
    const t = useTranslation(ASSET_MANAGEMENT);

    const options = [
        { label: t('lend'), value: 'lend' }, // 대여해주다
        { label: t('borrow'), value: 'borrow' }, // 대여받다
    ];

    const { config } = widgetInfo;
    const { settings: customSettings } = config;
    const settings = useSettings(config);
    const dispatch = useContext(ScreenEditDispatchContext);
    const initialWidgetInfo = settings?.dashboardURL ? { dashboardURL: settings.dashboardURL } : { dashboardURL: {} };
    const [updatedWidgetInfo, setUpdatedWidgetInfo] = useState(initialWidgetInfo);

    const handleSubmitClick = data => {
        const checkedSettings = customSettings
            ? { ...customSettings, ...data, ...updatedWidgetInfo }
            : { ...data, ...updatedWidgetInfo };

        const updatedWidgetSettings = {
            id: widgetInfo.id,
            settings: checkedSettings,
        };

        dispatch(editWidgetSettings(updatedWidgetSettings));
    };

    const handleChange = selectedCategory => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, categoryCode: selectedCategory.value };
        });
    };

    const handleCancelClick = () => {
        setUpdatedWidgetInfo({});
    };

    const handleIdleAssetChange = checked => {
        setUpdatedWidgetInfo(prevState => {
            return { ...prevState, idleAssetType: checked };
        });
    };

    const value = options.find(e =>
        updatedWidgetInfo.categoryCode ? e.value === updatedWidgetInfo.categoryCode : e.value === settings.categoryCode,
    );

    const idleAssetValue = updatedWidgetInfo?.idleAssetType ?? settings?.idleAssetType;

    return (
        <WidgetSettingModal
            headerTitle={t('Rental Management Setting')}
            widgetInfo={widgetInfo}
            hiddenHeader
            okCallback={handleSubmitClick}
            cancelCallback={handleCancelClick}
            {...restProps}
        >
            <Label
                name={t('Rental type')}
                value={
                    <Select className="w-100" value={value} options={options} onChange={handleChange} isModalSelect />
                }
            />

            <Label
                name={t('Idle Asset Type')}
                value={
                    <Checkbox
                        id={'rentalRequest'}
                        handleChecked={e => {
                            const { checked } = e.target;
                            handleIdleAssetChange(checked);
                        }}
                        checked={idleAssetValue}
                    />
                }
            />
        </WidgetSettingModal>
    );
};

export default RentalManagementSetting;
