import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const initialState = {
    log: [],
    targetNum: [],
    detectedTargets: [],
    startTime: moment().unix(),
    endTime: moment().add(10, 'seconds').unix(),
    playTime: moment().unix(),
    play: false,
    selectedFloor: null,
    processLog: [],
    heatmapPointsLoc: [],
    resultHeatmapPointsLoc: [],
    repeatSectionStart: false,
    repeatSectionEnd: false,
    repeatSectionStartTime: 0,
    repeatSectionEndTime: 0,
};

const heatMapSlice = createSlice({
    name: 'heatMap',
    initialState,
    reducers: {
        setTargetNum: (state, action) => {
            const log = { ...state.log };
            const resultLog = action.payload.reduce((acc, curr) => {
                for (let regDate in log) {
                    if (log[regDate][curr])
                        if (acc[regDate]) {
                            acc[regDate] = [...acc[regDate], [log[regDate][curr]['lat'], log[regDate][curr]['lng'], 1]];
                        } else {
                            acc[regDate] = [[log[regDate][curr]['lat'], log[regDate][curr]['lng'], 1]];
                        }
                }
                return acc;
            }, {});
            state.heatmapPointsLoc = resultLog;
            state.targetNum = action.payload;
        },

        setLog: (state, action) => {
            state.log = action.payload.reduce((acc, curr) => {
                const { regDate, targetNum } = curr;
                acc[regDate] = { ...acc[regDate], [targetNum]: curr };
                return acc;
            }, {});
        },

        setDetectedTargets: (state, action) => {
            state.detectedTargets = action.payload;
            const pointLoc = action.payload.map(v => {
                return [v.lat, v.lng, v.targetNum];
            });
            state.processLog = pointLoc;
        },

        setPlayTime: (state, action) => {
            const repeatSectionStartState = state.repeatSectionStart;
            const repeatSectionEndState = state.repeatSectionEnd;
            const startTime = state.repeatSectionStartTime;
            const endTime = state.repeatSectionEndTime;
            const playTime = state.playTime;

            const heatmapPointsLoc = state.heatmapPointsLoc;
            const processLoc = state.resultHeatmapPointsLoc;

            if (repeatSectionStartState && repeatSectionEndState) {
                if (playTime >= endTime) {
                    state.playTime = startTime;
                } else {
                    state.playTime = state.playTime + 1;
                }
            } else {
                if (action.payload) {
                    let resultHeatmapPointsLoc = [];
                    if (Object.keys(heatmapPointsLoc).length) {
                        if (heatmapPointsLoc[playTime]) {
                            resultHeatmapPointsLoc.push(heatmapPointsLoc[playTime]);
                        }
                        if (resultHeatmapPointsLoc.length) {
                            state.resultHeatmapPointsLoc = processLoc.concat(resultHeatmapPointsLoc);
                        }
                    }
                    state.playTime = action.payload;
                } else {
                    state.playTime = state.playTime + 1;
                }
            }
        },

        setStartTime: (state, action) => {
            state.startTime = action.payload;
            state.playTime = action.payload;
        },

        setEndTime: (state, action) => {
            state.endTime = action.payload;
        },

        setPlay: (state, action) => {
            if (action.payload) {
                state.play = action.payload;
            } else {
                state.play = action.payload;
            }
        },

        setSelectedFloor: (state, action) => {
            const { swLat, swLng, neLat, neLng } = action.payload;

            state.selectedFloor = {
                ...action.payload,
                bounds: [
                    [swLat, swLng],
                    [neLat, neLng],
                ],
            };
        },

        setHeatmapPointsLocUpdate: (state, action) => {
            const playTime = action.payload;

            const heatmapPointsLoc = state.heatmapPointsLoc;
            const processLoc = state.resultHeatmapPointsLoc;
            let resultHeatmapPointsLoc = [];
            if (Object.keys(heatmapPointsLoc).length) {
                if (heatmapPointsLoc[playTime]) {
                    resultHeatmapPointsLoc.push(heatmapPointsLoc[playTime]);
                }
                if (resultHeatmapPointsLoc.length) {
                    state.resultHeatmapPointsLoc = processLoc.concat(resultHeatmapPointsLoc);
                }
            } else {
                state.resultHeatmapPointsLoc = [];
            }
        },
        setSliderUpdate: (state, action) => {
            let playTime = action.payload;
            let prePlayTime = state.playTime;
            let startTime = state.startTime;
            // 슬라이더를 움직이면 이전의 히트맵 데이터를 지워야함.
            // 지우지 않으면 이전 히트맵 데이터에 또 히트맵을 그리기 때문에 덧칠이 계속 됨
            state.resultHeatmapPointsLoc = [];

            const heatmapPointsLoc = state.heatmapPointsLoc;
            const processLoc = state.resultHeatmapPointsLoc;
            let resultHeatmapPointsLoc = [];

            // 슬라이더 이벤트 발생 시 그전 시간과 현재 시간을 알아야 한다.
            // 조건 현재 시간과 playTime 같을 경우 x
            if (Object.keys(heatmapPointsLoc).length) {
                // 슬라이더바를 플레이 타이
                playTime = playTime > prePlayTime ? playTime : prePlayTime;
                prePlayTime = prePlayTime > playTime ? playTime : startTime;
                if (playTime !== prePlayTime) {
                    for (let i = prePlayTime; playTime > i; i++) {
                        if (heatmapPointsLoc[i]) {
                            resultHeatmapPointsLoc.push(heatmapPointsLoc[i]);
                        }
                    }

                    if (resultHeatmapPointsLoc.length) {
                        if (action.payload < state.playTime) {
                            state.resultHeatmapPointsLoc = resultHeatmapPointsLoc;
                        } else {
                            state.resultHeatmapPointsLoc = processLoc.concat(resultHeatmapPointsLoc);
                        }
                    }
                }
            } else {
                state.resultHeatmapPointsLoc = [];
            }
            state.playTime = action.payload;
        },
        setRepeatSectionStart: (state, action) => {
            const { status, playTime } = action.payload;
            state.repeatSectionStart = status;
            state.repeatSectionStartTime = playTime;
        },
        setRepeatSectionEnd: (state, action) => {
            const { status, playTime } = action.payload;
            state.repeatSectionEnd = status;
            state.repeatSectionEndTime = playTime;
        },
        setClear: (state, action) => {
            state.log = [];
            state.targetNum = [];
            state.detectedTargets = [];
            state.play = false;
            state.selectedFloor = null;
            state.processLog = [];
            state.heatmapPointsLoc = [];
            state.resultHeatmapPointsLoc = [];
            state.repeatSectionStart = false;
            state.repeatSectionEnd = false;
            state.repeatSectionStartTime = 0;
            state.repeatSectionEndTime = 0;
            state.startTime = moment().unix();
            state.endTime = moment().add(10, 'seconds').unix();
            state.playTime = moment().unix();
        },
        setResultHeatmapClear: state => {
            state.resultHeatmapPointsLoc = [];
            state.targetNum = [];
        },
    },
});

export const {
    setTargetNum,
    setLog,
    setDetectedTargets,
    setPlayTime,
    setStartTime,
    setEndTime,
    setPlay,
    setSelectedFloor,
    setHeatmapPointsLocUpdate,
    setSliderUpdate,
    setRepeatSectionStart,
    setRepeatSectionEnd,
    setClear,
    setResultHeatmapClear,
} = heatMapSlice.actions;
export default heatMapSlice;
