import React, { ChangeEvent, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Modal from '@components/Modal';
import useTranslation from '@hooks/useTranslation';
import styled from 'styled-components';
import { TextInput } from '@components';
import { ListGroup, ListGroupItem } from 'reactstrap';
import Button from '@components/Button';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ContextProvider from '@components/ContextProvider';
import widgetSearchSlice, {
    setPrevList,
    useWidgetSearchDispatchContext,
    useWidgetSearchStateContext,
    WidgetSearchDispatchContext,
    WidgetSearchPopupState,
    WidgetSearchStateContext,
} from './slice';
import Widget from '../../staticInfo';
import { ScreenEditDispatchContext } from '../../../MainPages/Settings/ScreenManagement/ScreenEdit';
import { editWidget } from '../../../MainPages/Settings/ScreenManagement/ScreenEdit/reducer';
import widgetList from '../../index';
import Tag from './Components/Tag';
import { TagKey, TagType, TYPE_TO_LABEL, TYPE_TO_TAGS } from '../../tags';
import { useDeepCompareEffect } from '@hooks';

interface WidgetSearchPopupProps {
    initOpen: boolean;
    toggleModal(): void;
    layout: GridItemOffset;
}

interface WidgetPrevListProps {
    widgetList: Widget[];
    handleAddClick(widget: Widget): void;
}

interface GridItemOffset {
    i: string;
    h: number;
    w: number;
    minH: number;
    minW: number;
    x: number;
    y: number;
}

const WidgetSearchPopup = ({ initOpen = false, toggleModal, layout }: WidgetSearchPopupProps) => {
    const { temp, ...validWidgetList } = widgetList;
    return (
        <ContextProvider
            StateContext={WidgetSearchStateContext}
            DispatchContext={WidgetSearchDispatchContext}
            slice={widgetSearchSlice}
            initializer={() => {
                const originList = Object.values(validWidgetList);
                return {
                    originList,
                    prevList: originList,
                } as WidgetSearchPopupState;
            }}
        >
            <ContextConsumer initOpen={initOpen} toggleModal={toggleModal} layout={layout} />
        </ContextProvider>
    );
};

const initialTags = Object.entries(TYPE_TO_TAGS).reduce(
    (acc: { tag: TagKey; selected: boolean; type: TagType }[], [type, tagsInType]) => {
        Object.keys(tagsInType).forEach(key => {
            acc.push({ tag: key as TagKey, type: type as TagType, selected: false });
        });
        return acc;
    },
    [],
);

const tagTypes = Object.keys(TYPE_TO_TAGS) as TagType[];

const ContextConsumer = ({ initOpen = false, toggleModal, layout }: WidgetSearchPopupProps) => {
    const t = useTranslation('Widget');
    const screenEditDispatch = useContext(ScreenEditDispatchContext);
    const dispatch = useWidgetSearchDispatchContext();
    const { prevList } = useWidgetSearchStateContext();

    const [searchKeyword, setSearchKeyword] = useState('');
    const handleKeywordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchKeyword(e.currentTarget.value);
    };

    const ps = useRef<HTMLElement>();
    const scrollTop = () => {
        const curr = ps.current;
        if (curr) {
            curr.scrollTop = 0;
        }
    };

    const handleAddClick = (widgetInfo: Widget) => {
        screenEditDispatch(
            editWidget({
                name: widgetInfo.name,
                category: widgetInfo.category,
                id: layout.i,
                type: widgetInfo.type,
                config: widgetInfo.config,
                layout: { ...layout, ...widgetInfo.layout },
            }),
        );
        toggleModal();
    };

    const handleRefreshClick = () => {
        setSearchKeyword('');
        setTags(initialTags);
    };

    const [tags, setTags] = useState<{ tag: TagKey; selected: boolean; type: TagType }[]>(initialTags);

    const handleTagClick = (tag: TagKey, selected: boolean) => {
        setTags(prevState => {
            return prevState.map(tagInfo => {
                if (tagInfo.tag === tag) {
                    return { ...tagInfo, selected };
                }
                return tagInfo;
            });
        });
    };

    const selectedTags = useMemo(() => {
        return tags.filter(tag => tag.selected);
    }, [tags]);

    useDeepCompareEffect(() => {
        scrollTop();
        dispatch(setPrevList({ keyword: searchKeyword, selectedTags, t }));
    }, [searchKeyword, selectedTags, t]);

    return (
        <Modal
            className={'widget-add-popup'}
            initModal={initOpen}
            toggleModal={toggleModal}
            style={{ maxWidth: '45rem' }}
            removeCancel
            modalHeader={
                <div className={'modal-header w-100 bg-depth-3'}>
                    <div className={'flx-row justify-content-between w-100 align-center'}>
                        <span className={'s-8 fw-bold'}>{t('Add Widget', 'WidgetList')}</span>
                        <span className={'icon-close cursor-pointer'} onClick={toggleModal} />
                    </div>
                </div>
            }
        >
            <div className="modal-body d-flex p-0" style={{ height: '400px' }}>
                <SearchContainer className="w-40 h-100 p-3 gap-2">
                    <TextInput
                        type={'text'}
                        name={'widgetName'}
                        placeholder={t("Please enter a widget's name")}
                        value={searchKeyword}
                        handleChange={handleKeywordChange}
                        inputGroupClassName={'w-100'}
                    />
                    <PerfectScrollbar>
                        <div className="w-100 tags-filter-wrapper">
                            {tagTypes.map(type => {
                                return (
                                    <div key={type} className={'w-100'}>
                                        <div className={'w-100'}>
                                            <div className={'pnt-txt txt-dot'}>{t(TYPE_TO_LABEL[type])}</div>
                                            <div className={'pnt-divider horizon-line m-0 mb-1'}></div>
                                        </div>
                                        <div className={'d-flex flex-wrap flex-row gap-1'}>
                                            {tags.map(
                                                tagInfo =>
                                                    tagInfo.type === type && (
                                                        <Tag
                                                            key={tagInfo.tag}
                                                            tag={tagInfo.tag}
                                                            selected={tagInfo.selected}
                                                            recommended={prevList.some(({ tags }) =>
                                                                (tags ?? []).includes(tagInfo.tag),
                                                            )}
                                                            onClick={handleTagClick}
                                                        />
                                                    ),
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </PerfectScrollbar>
                    <SearchResultContainer>
                        <span className={'text-ellipsis'}>
                            {t('A total of {{totalCount}} results were found.', 'WidgetList', {
                                totalCount: prevList.length,
                            })}
                        </span>
                        <Button
                            title={t('Refresh', 'Button')}
                            className="btn-icon-only btn-trans"
                            iconName="refresh"
                            onClick={handleRefreshClick}
                        />
                    </SearchResultContainer>
                </SearchContainer>

                <div className="w-60 h-100">
                    <PerfectScrollbar containerRef={e => (ps.current = e)}>
                        <ListGroup flush>
                            <WidgetPrevList widgetList={prevList} handleAddClick={handleAddClick} />
                        </ListGroup>
                    </PerfectScrollbar>
                </div>
            </div>
        </Modal>
    );
};

const SearchContainer = styled.div`
    display: grid;
    grid-template-rows: min-content auto min-content;
`;

const SearchResultContainer = styled.div`
    display: grid;
    grid-template-columns: auto min-content;
    align-items: center;
`;

const WidgetPrevList = ({ widgetList, handleAddClick }: WidgetPrevListProps) => {
    const t = useTranslation('WidgetList');

    // widget 없는 경우
    if (!widgetList.length) {
        return (
            <ListGroupItem key={'noItem'} className="d-flex flex-center">
                <p className="mb-0">{t('There are no search widget')}</p>
            </ListGroupItem>
        );
    }

    return (
        <>
            {widgetList.map((widget, index) => (
                <div key={`${widget.name}_${index}`} className="widget-item-wrapper bg-depth-4">
                    <div className={'flx-row justify-content-center'}>
                        <div>
                            <span className={widget.thumbnailClassName ?? undefined} />
                        </div>
                        <div className="w-100 p-3">
                            <span className="widget-name">{t(widget.name, 'Widget')}</span>
                            <p className="widget-desc">{t(widget.desc, 'Widget')}</p>
                            <div className={'tags-wrapper'}>
                                {(widget.tags ?? []).map((v, i) => (
                                    <Tag key={v + i} tag={v} />
                                ))}
                            </div>
                        </div>
                        <Button
                            className="btn-brand btn-icon btn-icon-only"
                            iconName="add"
                            onClick={e => {
                                handleAddClick(widget);
                            }}
                        />
                    </div>
                </div>
            ))}
        </>
    );
};

export default WidgetSearchPopup;
