import React, { useState } from 'react';
import { Button, ConfirmModal } from '@components';
import { useConfirm, useTranslation } from '@hooks';
import {
    DisplayedMonitoringPatient,
    setSelectedDeviceInfo,
    updatePatientInfo,
    useBiometricInformationMonitoringDispatchContext,
} from '../../slice';
import Bookmark from '../../../../Components/Bookmark';
import { NullableUnixTimestamp, YN } from '@util/type/util';
import cx from 'classnames';
import moment from 'moment';
import { checkLostSignal } from '@util/mappInfo';

export const isMapped = ({ deviceNum }: DisplayedMonitoringPatient) => {
    return !!deviceNum;
};

export const SensorNameCell = ({
    value,
    row: { original },
}: {
    value: string;
    row: {
        original: DisplayedMonitoringPatient;
    };
}) => {
    const t = useTranslation('BiometricInformationMonitoring');
    const dispatch = useBiometricInformationMonitoringDispatchContext();

    return (
        <>
            {isMapped(original) ? (
                <Button
                    className={'btn-trans text-decoration-underline text-ellipsis d-block'}
                    title={value}
                    onClick={e => {
                        e.stopPropagation();
                        dispatch(setSelectedDeviceInfo(original));
                    }}
                >
                    {value}
                </Button>
            ) : (
                <Button
                    className={'btn-line'}
                    onClick={e => {
                        e.stopPropagation();
                        dispatch(setSelectedDeviceInfo(original));
                    }}
                >
                    {t('Device mapping')}
                </Button>
            )}
        </>
    );
};

export const BookmarkCell = ({
    value,
    row: { original },
}: {
    value: YN;
    row: {
        original: DisplayedMonitoringPatient;
    };
}) => {
    const t = useTranslation('BiometricInformationMonitoring');
    const dispatch = useBiometricInformationMonitoringDispatchContext();
    const bookmarkFailureModalProps = useConfirm({
        confirmText: t('Something has changed.', 'ConfirmModal'),
        removeCancel: true,
    });
    const handleBookmarkChange = () => {
        dispatch(updatePatientInfo({ ...original, isBookmark: value === 'Y' ? 'N' : 'Y' }));
    };
    return (
        <>
            <Bookmark
                isBookmark={value}
                targetNum={original.targetNum}
                successCallback={handleBookmarkChange}
                failureCallback={bookmarkFailureModalProps.toggleModal}
            />
            <ConfirmModal {...bookmarkFailureModalProps} />
        </>
    );
};

export const RegisteredDateCell = ({
    value,
    row: { original },
}: {
    value: NullableUnixTimestamp;
    row: { original: DisplayedMonitoringPatient };
}) => {
    return value ? (
        <div
            className={cx(
                'w-100 h-100 flex-center pnt-txt s-8',
                original.lostSignalState && checkLostSignal(original.lostSignalState) && 'bg-gray bg-light',
            )}
        >
            {moment.unix(value).format('YYYY-MM-DD HH:mm:ss')}
        </div>
    ) : (
        ''
    );
};
