import React, { useState } from 'react';
import { IdleAsset, SelectedIdleValue } from '../type';
import { useColumns, useTranslation } from '@hooks';
import { PageInfo } from '@util/type/util';
import { Button, Table } from '@components';
import IdleRentalRequest from './IdleRentalRequest';

interface Props {
    data: PageInfo<IdleAsset>;
    handlePage: (pageIndex: number) => void;
}

interface TableCellProps {
    row: {
        original: IdleAsset;
        index: number;
    };
}

const IdleEquipmentList = ({ data, handlePage }: Props) => {
    const t = useTranslation('IdleEquipment');
    const [selectedOption, setSelectedOption] = useState<SelectedIdleValue>({
        floor: null,
        categoryCode: null,
        wards: [],
        fcNum: null,
    });

    const handleSelectedValue = ({ categoryCode, floor, wards, fcNum }: SelectedIdleValue) => {
        setSelectedOption({
            categoryCode,
            floor,
            wards,
            fcNum,
        });
    };

    const handleClear = () => {
        setSelectedOption({
            floor: null,
            categoryCode: null,
            wards: [],
            fcNum: null,
        });
    };

    const columns = useColumns(
        [
            {
                Header: 'No.',
                accessor: 'idx',
                Cell: ({ row }: TableCellProps) => {
                    const { index } = row;
                    return <span>{index + 1}</span>;
                },
                width: 80,
            },
            {
                Header: 'category',
                accessor: 'categoryName',
                className: 'text-ellipsis',
            },
            {
                Header: 'count',
                accessor: 'assetCounts',
                className: 'text-ellipsis',
            },
            {
                Header: 'floor',
                accessor: 'floorName',
                className: 'text-ellipsis',
            },
            {
                Header: 'location',
                accessor: 'fcName',
                className: 'text-ellipsis',
            },
            {
                Header: 'Handling rentals',
                className: 'p-0',
                Cell: ({ row: { original } }: TableCellProps) => {
                    const { categoryCode, floor, wards, fcNum } = original;
                    return (
                        <div className={'w-100 flex-center'}>
                            <Button
                                className={'pnt-btn btn-secondary w-50'}
                                onClick={() => handleSelectedValue({ categoryCode, floor, wards, fcNum })}
                            >
                                {t('Rental Request')}
                            </Button>
                        </div>
                    );
                },
                width: 120,
            },
        ],
        t,
        [],
    );
    const handleTrClick = (row: IdleAsset) => {
        const { categoryCode, floor, wards, fcNum } = row;
        setSelectedOption({
            categoryCode,
            floor,
            wards,
            fcNum,
        });
        handleSelectedValue({ categoryCode, floor, wards, fcNum });
    };

    return (
        <div className={'h-100 flx-col'}>
            <span className={'pnt-txt txt-bold s-5'}>{t('Idle Assets Request')}</span>
            {selectedOption.categoryCode ? (
                <IdleRentalRequest handleClear={handleClear} options={selectedOption} />
            ) : (
                <Table columns={columns} data={data} onPageChange={handlePage} onTrClick={handleTrClick} />
            )}
        </div>
    );
};

export default IdleEquipmentList;
