import React, { ReactNode } from 'react';
import cx from 'classnames';

type NameplateProps = {
    name?: ReactNode;
    icon?: ReactNode;
    className?: string;
};

const Nameplate = ({ name = '', icon = '', className }: NameplateProps) => {
    return (
        <div className={cx('pnt-nameplate', className)}>
            {icon && <span className="material-icons-round">{icon}</span>}
            <div className="cont-wrap">{name}</div>
        </div>
    );
};

export default Nameplate;
