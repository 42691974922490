import React, { useRef } from 'react';
import { getOutpatientUsageDept, postOutpatientUsageDeptExcel } from '@api/so/outpatient';
import { useAsync, useTranslation } from '@hooks';
import InsightBox, { BoxBodyText, HighlightedText } from '../Components/InsightBox';
import WidgetCard from '../../Components/WidgetCard';
import useDateTypeOptions from '../util/useDateTypeOptions';
import Chart from './Chart';
import { useAsyncExcel } from '../../util/useAsyncExcel';
import useRangeDateSO from '../util/useRangeDateSO';
import SearchGroupSO from '../Components/SearchGroupSO';
import { isValidData } from '../util/checkData';
import NoDataBlock from '../../Components/NoDataBlock';
import { Trans } from 'react-i18next';
import moment from 'moment';
import useDepartmentOptions from '../util/useDepartmentOptions';

const getColumnMetas = (excelDownType, t) => {
    const columns = [
        { key: 'regDay', name: t(`Date(${excelDownType})`) },
        { key: 'departmentName', name: t('Department name') },
        { key: 'departmentCode', name: t('Department code') },
        { key: 'todayPatientCount', name: `${t('Same-day Registration')} (${t('Cases')})` },
        { key: 'appointmentPatientCount', name: `${t('Appointment Registration')} (${t('Cases')})` },
        { key: 'patientCount', name: `${t('Total Registration')} (${t('Cases')})` },
        { key: 'todayPatientKioskCount', name: `${t('Using a kiosk after same-day check-in')} (${t('Cases')})` },
        {
            key: 'appointmentPatientKioskCount',
            name: `${t('Using a kiosk after appointment registration')} (${t('Cases')})`,
        },
    ];

    if (excelDownType === 'day') {
        columns.push({ key: 'kioskRate', name: `${t('Kiosk Usage Rate')} (%)` });
    }

    return columns;
};

const getFileName = (startDate, endDate) =>
    `so_kioskData_${moment.unix(startDate).format('YYYYMMDD')}_${moment.unix(endDate).format('YYYYMMDD')}`;

// 외래 사용량 분석(진료과별)
const OutpatientUsageAnalysisByDepartment = ({ children, widgetInfo, ...restProps }) => {
    const widgetRef = useRef();
    const t = useTranslation('OutpatientReport');

    const dateTypeValues = useDateTypeOptions();
    const dateValues = useRangeDateSO(dateTypeValues.dateType.value);
    const departmentValues = useDepartmentOptions();

    const { state, promise: getData } = useAsync({
        promise: getOutpatientUsageDept,
        immediate: true,
        param: {
            departmentCode: departmentValues.department.toString(),
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
        },
        reject: err => console.error(err),
    });
    const data = state.response ? state.response.rows : [];
    const { insightDepartments = [] } = state.response ? state.response.additionalDatas : {};
    const [dept1, dept2] = insightDepartments;

    const triggerDayDownload = useAsyncExcel({
        promise: postOutpatientUsageDeptExcel,
        param: {
            departmentCode: departmentValues.department.toString(),
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
            excelDownType: 'day',
            columnMetas: getColumnMetas('day', t),
            fileName: getFileName(dateValues.startDate, dateValues.endDate),
            zipFileName: 'so_usage_by_department_day',
        },
        reject: err => console.error(err),
    });
    const triggerMonthDownload = useAsyncExcel({
        promise: postOutpatientUsageDeptExcel,
        param: {
            departmentCode: departmentValues.department.toString(),
            startDate: dateValues.startDate,
            endDate: dateValues.endDate,
            excelDownType: 'month',
            columnMetas: getColumnMetas('month', t),
            fileName: getFileName(dateValues.startDate, dateValues.endDate),
            zipFileName: 'so_usage_by_department_month',
        },
        reject: err => console.error(err),
    });

    const handleDownloadClick = () => {
        triggerMonthDownload().then(() => {
            triggerDayDownload();
        });
    };

    return (
        <WidgetCard widgetInfo={widgetInfo} ref={widgetRef} {...restProps}>
            <SearchGroupSO
                showDepartment
                departmentValues={departmentValues}
                dateValues={dateValues}
                getData={getData}
            />
            {isValidData(data) ? (
                <>
                    <InsightBox>
                        <BoxBodyText>
                            <Trans t={t} values={{ dept1, dept2 }} components={[<HighlightedText />]}>
                                {`Smart outpatient utilization, such as arrival notification using kiosks during the period you viewed, was most utilized by department, in that order, <0>{{dept1}}</0> and then <0>{{dept2}}</0>.`}
                            </Trans>
                        </BoxBodyText>
                    </InsightBox>
                    <Chart data={data} onDownloadClick={handleDownloadClick} />
                </>
            ) : (
                <NoDataBlock text={t('No data')} />
            )}
            {children}
        </WidgetCard>
    );
};

export default OutpatientUsageAnalysisByDepartment;
