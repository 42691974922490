import { WidgetProps } from '../../staticInfo';
import ContextProvider from '@components/ContextProvider';
import deviceMappingManagementSlice, {
    DeviceMappingManagementDispatchContext,
    DeviceMappingManagementStateContext,
} from './slice';
import WidgetCard from '../../Components/WidgetCard';
import React from 'react';
import PatientsDeviceList from './Components/DeviceList/PatientsDeviceList';
import DeviceManagementFilterGroup from './Components/Filter/DeviceMappingManagementFilterGroup';

const DeviceMappingManagementContainer = ({ widgetInfo, children, ...restProps }: WidgetProps) => {
    return (
        <WidgetCard widgetInfo={widgetInfo} {...restProps} searchFilter={<DeviceManagementFilterGroup />}>
            <PatientsDeviceList />
            {children}
        </WidgetCard>
    );
};

const DeviceMappingManagement = ({ widgetInfo, children, ...restProps }: WidgetProps) => {
    return (
        <ContextProvider
            StateContext={DeviceMappingManagementStateContext}
            DispatchContext={DeviceMappingManagementDispatchContext}
            slice={deviceMappingManagementSlice}
        >
            <DeviceMappingManagementContainer widgetInfo={widgetInfo} {...restProps}>
                {children}
            </DeviceMappingManagementContainer>
        </ContextProvider>
    );
};

export default DeviceMappingManagement;
