import { Button, LineChart } from '@components';
import InnerItemCard from '@components/Card/InnerItemCard';
import { useAppSelector, useTranslation } from '@hooks';
import React from 'react';
import styled from 'styled-components';
import { OUTPATIENT_REPORT, OUTPATIENT_USAGE_CHART_TITLE, TIME_LABELS } from '../../constants';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { hoverBackgroundPlugin } from '../util/chartPlugin';
import { createTooltipBase, dataLabelsColorByTheme } from '../util';

// type = 0 or 1 (개별 or 합산)
const Chart = ({ data, onDownloadClick }) => {
    const t = useTranslation(OUTPATIENT_REPORT);
    const { colorScheme } = useAppSelector(state => state.ThemeOptions);

    const lineData = {
        labels: TIME_LABELS,
        datasets: data.map(({ departmentName, kioskCount, kioskRate }) => ({
            label: departmentName,
            data: kioskCount,
            kioskRate: kioskRate,
            datalabels: {
                // 데이터의 길이가 1개일때만 datalabes 표시
                display: data.length === 1,
                anchor: 'end',
                align: 'top',
                color: dataLabelsColorByTheme(colorScheme),
                formatter: function (value) {
                    // 개별일땐 키오스크 사용률까지
                    return `${value}${t('Cases')}`; // 표시할 값
                },
            },
        })),
    };

    return (
        <InnerItemCard
            className={'m-2 h-90'}
            bodyClassName={'h-100'}
            header={{ title: t(OUTPATIENT_USAGE_CHART_TITLE) }}
        >
            <div className={'h-90 flx-row position-relative'}>
                <div className={'w-100 h-100 '}>
                    <LineChart
                        plugins={[ChartDataLabels, hoverBackgroundPlugin]}
                        data={lineData}
                        options={{
                            plugins: {
                                tooltip: {
                                    intersect: false,
                                    mode: 'index',
                                    enabled: false,
                                    external: function (context) {
                                        createTooltipBase(context, (dataPoints, tooltipEl) => {
                                            dataPoints.forEach(dataPoint => {
                                                const { dataIndex } = dataPoint;
                                                const {
                                                    label: datasetLabel = '',
                                                    backgroundColor: color,
                                                    kioskRate,
                                                } = dataPoint.dataset;
                                                const value = dataPoint.raw;

                                                tooltipEl.insertAdjacentHTML(
                                                    'beforeend',
                                                    `
                                                       <div class="d-flex align-items-center gap-1">
                                                          <div style="height: 15px; width: 15px; background-color: ${color};"></div>
                                                          <div>${datasetLabel}: ${value} ${t('Cases')}</div>
                                                          <div style="color:red">(${kioskRate[dataIndex]}%)</div>
                                                       </div>`,
                                                );
                                            });
                                        });
                                    },
                                },
                            },
                            scales: {
                                y: {
                                    beginAtZero: true,
                                    suggestedMax: data.length === 1 ? Math.max(...data[0].kioskCount) + 10 : 10,
                                },
                            },
                        }}
                    />
                </div>
                <DownloadButton className={'btn-secondary ml-3'} onClick={onDownloadClick}>
                    {t('Download')}
                </DownloadButton>
            </div>
        </InnerItemCard>
    );
};

const DownloadButton = styled(Button)`
    position: absolute;
    top: 0;
    right: 0;
`;

export default Chart;
