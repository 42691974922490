import React, { useCallback, useEffect, useState } from 'react';
import Draggable from '@components/Draggable';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import { useTranslation } from '@hooks';
import { useRealTimeLocationStatusContext } from '../index';
import { useSelector } from 'react-redux';
import { Table, Th, Tr, Td } from '@components/Table/SimpleTable';
import { defaultOptions } from '@components/Map/config';
import cx from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';

const checkInputValue = (inputValue, value, inputType) => {
    // value값의 배열 여부
    let isArr;
    // value가 배열값일 때 사용할 변수
    let filteredValues;
    try {
        const arr = JSON.parse(value);
        if (Array.isArray(arr)) {
            isArr = true;
            filteredValues = inputValue.filter(item => value.includes(item.value));
        }
    } catch (e) {
        isArr = false;
    }

    if (!!inputValue.length) {
        return isArr
            ? filteredValues.map(item => item.name).join(',')
            : inputValue.find(item => item.value === value)?.name;
    } else {
        if (inputType === 'date') {
            return moment.unix(value).format('YYYY-MM-DD');
        }
        return value;
    }
};

export const TableBody = ({ className, children }) => {
    return (
        <div role={'rowgroup'} className={cx('tbody', className)}>
            <div>
                <PerfectScrollbar options={{ wheelPropagation: false }}>
                    <div>{children}</div>
                </PerfectScrollbar>
            </div>
        </div>
    );
};

const SelectedItemDetail = ({ modal, toggleModal, widgetRef, mapRef }) => {
    const t = useTranslation('RealTimeLocationStatus');
    const { selectItem } = useRealTimeLocationStatusContext();
    const { categoryPropertiesList, categoryList } = useSelector(state => state.CategoryInfo);

    const { properties, categoryCode, targetName, targetId } = selectItem;

    const filteredCategories = categoryList.filter(
        item => item.categoryCode === categoryCode || item.parentCode === categoryCode,
    );

    const [position, setPosition] = useState({
        x: 0,
        y: 0,
    });
    const [popupSize, setPopupSize] = useState({
        x: 0,
        y: 0,
    });

    const popupRef = useCallback(node => {
        if (node !== null) {
            const { width, height } = node.getBoundingClientRect();

            setPopupSize({
                width,
                height,
            });
        }
    }, []);

    useEffect(() => {
        const widgetPosition = getWidgetPosition();

        const x = (widgetPosition.width - popupSize.width) / 2 - 20;
        const y = widgetPosition.height / 2 - widgetPosition.height / 4 - 100;
        setPosition({
            x: Math.ceil(x),
            y: Math.ceil(y),
        });
    }, [popupSize]);

    const getPosition = (el, position = { top: 0, left: 0 }) => {
        if (el) {
            position.top += el.offsetTop;
            position.left += el.offsetLeft;
            getPosition(el.offsetParent, position);
        }
        return position;
    };

    const getWidgetPosition = () => {
        const widgetPosition = { x: 0, y: 0 };

        if (widgetRef.current) {
            const widget = widgetRef.current;

            const { offsetWidth: width, offsetHeight: height } = widget;

            widgetPosition.width = width;
            widgetPosition.height = height;
        }
        return widgetPosition;
    };

    useEffect(() => {
        defaultOptions.scrollWheelZoom = false;

        return () => {
            defaultOptions.scrollWheelZoom = true;
        };
    }, []);

    return (
        <Draggable
            key={JSON.stringify(position)}
            bounds="parent"
            defaultPosition={position}
            backgroundMapElement={mapRef.current?.leafletElement}
        >
            <Toast isOpen={modal} className={'detail-popup-open bg-depth-3'} innerRef={popupRef}>
                <ToastHeader className={'bg-depth-4'} toggle={toggleModal}>
                    {t('IoT Item ID')} : {targetId}
                </ToastHeader>
                <ToastBody>
                    <Table className={'w-100'}>
                        <TableBody className={'h-100'}>
                            <Tr className={'d-flex realTime-label'}>
                                <Th className="w-50 thead">{t('IoT Item Name')}</Th>
                                <Td className="w-50">{targetName}</Td>
                            </Tr>
                            {filteredCategories.map(item =>
                                categoryPropertiesList
                                    .filter(
                                        ({ isListProperty, categoryCode }) =>
                                            isListProperty === 'Y' &&
                                            (categoryCode === item?.parentCode || categoryCode === item.categoryCode),
                                    )
                                    .map(({ displayName, propertyId, inputValues, targetNum, inputType }) => {
                                        return (
                                            <Tr key={targetNum + propertyId} className="d-flex realTime-label">
                                                <Th className="w-50">{displayName}</Th>
                                                <Td className="w-50">
                                                    <div className={'w-100 text-ellipsis'}>
                                                        {checkInputValue(
                                                            inputValues,
                                                            properties && properties[propertyId],
                                                            inputType,
                                                        )}
                                                    </div>
                                                </Td>
                                            </Tr>
                                        );
                                    }),
                            )}
                        </TableBody>
                    </Table>
                </ToastBody>
            </Toast>
        </Draggable>
    );
};
export default SelectedItemDetail;
