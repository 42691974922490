import React, { Dispatch, SetStateAction, useMemo, useRef } from 'react';
import { DEFAULT_CHART_OPTIONS, LINE_CHART_COLOR, WHITE_COLOR } from './constants';
import { mergeObjects } from '@util/common/util';
import getVitalSingsTooltip from '../../../util/chart/plugins/vitalSingsTooltip';
import { LineChart, LoadingBlock } from '@components';
import { useAppSelector, useTranslation } from '@hooks';
import { ChartData, ChartLoading } from './VitalSignsView';
import { Chart } from 'react-chartjs-2';
import PartialBackgroundColor from '@components/Charts/Plugins/PartialBackgroundColor';
import NoDataBlock from '../../../Components/NoDataBlock';
import styled from 'styled-components';
import { Scale, ScatterDataPoint } from 'chart.js';
import { Nullable, NullableNumber } from '@util/type/util';

type VitalSignsChartProps = {
    chartData: ChartData;
    isLoading?: boolean;
    setLoading?: Dispatch<SetStateAction<ChartLoading>>;
    criticalMaxValue: NullableNumber;
    normalRange: Nullable<[min: number, max: number]>;
};

const VitalSignsChart = ({ chartData, isLoading, setLoading, criticalMaxValue, normalRange }: VitalSignsChartProps) => {
    const t = useTranslation('BiometricInformationMonitoring');
    const colorScheme = useAppSelector(state => state.ThemeOptions.colorScheme);
    const isDataChanged = useRef<boolean>(false);
    const dangerColor = useMemo(() => getGlobalDangerColor(), [colorScheme]);

    return (
        <ChartWrapper>
            <h5 className={'pnt-txt txt-bold s-6 mb-1'}>{`${chartData.name} ${
                chartData?.unit ? '(' + chartData?.unit + ')' : ''
            }`}</h5>
            <div className={'line-chart-area'}>
                <LoadingBlock blocking={isLoading}>
                    {!chartData.data.length ? (
                        <NoDataBlock className={'no-data'} />
                    ) : (
                        <LineChart
                            data={{
                                datasets: [
                                    {
                                        data: chartData.data,
                                        label: chartData.name,
                                        forcedPointBackgroundColor: WHITE_COLOR,
                                        forcedBorderColor: LINE_CHART_COLOR,
                                        forcedPointBorderColor: LINE_CHART_COLOR,
                                        forcedBackgroundColor: LINE_CHART_COLOR,
                                        pointHoverRadius: 0,
                                    },
                                    {
                                        data:
                                            typeof criticalMaxValue !== 'number'
                                                ? []
                                                : chartData.data.filter(
                                                      d => (d as ScatterDataPoint).y === criticalMaxValue,
                                                  ),
                                        forcedPointBackgroundColor: dangerColor,
                                        forcedBorderColor: dangerColor,
                                        forcedPointBorderColor: dangerColor,
                                        forcedBackgroundColor: dangerColor,
                                        pointRadius: 3, // 포인트 크기
                                        showLine: false, // 라인을 숨기고 포인트만 표시
                                    },
                                ],
                                labels: chartData.labels,
                            }}
                            options={mergeObjects(DEFAULT_CHART_OPTIONS, {
                                plugins: {
                                    ...getVitalSingsTooltip(colorScheme, t),
                                    legend: {
                                        labels: {
                                            usePointStyle: true,
                                            boxWidth: 15,
                                            boxHeight: 15,
                                            generateLabels: () => {
                                                return [
                                                    {
                                                        text: chartData.name,
                                                        datasetIndex: 0,
                                                        pointStyle: 'line',
                                                        strokeStyle: LINE_CHART_COLOR,
                                                        fillStyle: LINE_CHART_COLOR,
                                                    },
                                                    {
                                                        text: t('Critical Maximum') + ' : ' + (criticalMaxValue ?? '-'),
                                                        datasetIndex: 1,
                                                        pointStyle: generateCustomPoint(),
                                                        strokeStyle: dangerColor,
                                                        fillStyle: dangerColor,
                                                    },
                                                ];
                                            },
                                        },
                                    },
                                    loading: {
                                        install: (chart: Chart) => {
                                            if (chart.data.datasets[0]?.label) {
                                                if (typeof setLoading === 'function') {
                                                    isDataChanged.current = true;
                                                }
                                            }
                                        },
                                        afterRender: (chart: Chart) => {
                                            if (isDataChanged.current && typeof setLoading === 'function') {
                                                setLoading((prev: ChartLoading) => {
                                                    return { ...prev, [chartData.sensorType]: false };
                                                });
                                            }
                                            isDataChanged.current = false;
                                        },
                                    },
                                    partialBackgroundColor: normalRange
                                        ? [
                                              {
                                                  axis: 'y',
                                                  color: '#caf0ff',
                                                  arrange: normalRange,
                                              },
                                          ]
                                        : null,
                                    zoom: {
                                        limits: {
                                            x: { min: 'original', max: 'original', minRange: 30 * 1000 },
                                        },
                                        zoom: {
                                            wheel: {
                                                enabled: true,
                                                modifierKey: 'shift',
                                            },
                                            drag: {
                                                enabled: true,
                                            },
                                            pinch: {
                                                enabled: true,
                                            },
                                            mode: 'x',
                                        },
                                    },
                                },
                                scales: {
                                    x: {
                                        afterBuildTicks: (axis: Scale) => {
                                            const DEFAULT_TICK_LENGTH = 10;
                                            if (axis.ticks.length < DEFAULT_TICK_LENGTH) {
                                                const min = Math.round(axis.min);
                                                const max = Math.round(axis.max);
                                                const range = max - min;
                                                const gap = Math.round(range / (DEFAULT_TICK_LENGTH - 1));
                                                const newTicksValue = [];
                                                for (let i = 0; i < DEFAULT_TICK_LENGTH; i++) {
                                                    newTicksValue.push(min + gap * i);
                                                }
                                                axis.ticks = newTicksValue.map(v => ({ value: v }));
                                            }
                                        },
                                    },
                                    y:
                                        chartData.min === 0 && chartData.max === 0
                                            ? { min: 0, max: 1 }
                                            : chartData.min === chartData.max
                                            ? {
                                                  min: chartData.min - chartData.min * 0.2,
                                                  max: chartData.max + chartData.max * 0.2,
                                              }
                                            : {
                                                  min: chartData.min - (chartData.max - chartData.min) * 0.2,
                                                  max: chartData.max + (chartData.max - chartData.min) * 0.2,
                                              },
                                },
                            })}
                            plugins={[PartialBackgroundColor]}
                        />
                    )}
                </LoadingBlock>
            </div>
        </ChartWrapper>
    );
};

const ChartWrapper = styled.div`
    & .line-chart-area {
        height: 300px;
        .no-data {
            border: 1px solid #eceaf2;
        }
    }
`;

const generateCustomPoint = () => {
    const canvas = document.createElement('canvas');
    canvas.width = 10;
    canvas.height = 10;

    const ctx = canvas.getContext('2d');
    if (ctx) {
        ctx.arc(5, 5, 5, 0, 2 * Math.PI);
        ctx.fillStyle = getGlobalDangerColor();
        ctx.fill();
    }

    return canvas;
};

const getGlobalDangerColor = () => {
    const rootStyles = getComputedStyle(document.documentElement);
    const color = rootStyles.getPropertyValue('--danger').trim();
    return color ?? '#ff3358';
};

export default React.memo(VitalSignsChart);
