import React, { createContext, useContext } from 'react';
import SensorLogContainer from './SensorLogContainer';
import ContextProvider from '@components/ContextProvider';
import sensorLogSlice from './sensorLogSlice';

/**
 * 센서 로그 위젯
 *
 * useAsync 커스텀 후 리팩토링 예정
 *
 * @author jinbeom-jung
 * */

export const SensorLogStateContext = createContext(null);
export const SensorLogDispatchContext = createContext(null);

export const useSensorLogContext = () => useContext(SensorLogStateContext);
export const useSensorLogDispatch = () => useContext(SensorLogDispatchContext);

const SensorLog = ({ children, widgetInfo, ...restProps }) => {
    return (
        <ContextProvider
            StateContext={SensorLogStateContext}
            DispatchContext={SensorLogDispatchContext}
            slice={sensorLogSlice}
        >
            <SensorLogContainer widgetInfo={widgetInfo} children={children} {...restProps} />
        </ContextProvider>
    );
};

export default SensorLog;
