import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const initialState = {
    selectedCategory: '',
    selectedFloor: '',
    selectedFloorInfo: {},
    startDate: moment().startOf('day').valueOf(),
    endDate: moment().endOf('day').valueOf(),
};

const hourlyCongestionStatusSlice = createSlice({
    name: 'hourlyCongestionStatus',
    initialState,
    reducers: {
        setSelectedCategory: (state, action) => {
            state.selectedCategory = action.payload;
        },
        setSelectedFloor: (state, action) => {
            state.selectedFloor = action.payload;
        },
        setStartDate: (state, action) => {
            state.startDate = action.payload;
        },
        setEndDate: (state, action) => {
            state.endDate = action.payload;
        },
        setSelectedFloorInfo: (state, action) => {
            state.selectedFloorInfo = action.payload;
        },
    },
});

export const {
    setSelectedCategory,
    setSelectedFloor,
    setStartDate,
    setEndDate,
    setSelectedFloorInfo,
} = hourlyCongestionStatusSlice.actions;

export default hourlyCongestionStatusSlice;
