import React, { useState, useRef, useMemo } from 'react';
import { useSettings } from '../../util/useSettings';
import Table from '@components/Table';
import { useTranslation, useAppSelector } from '@hooks';
import { SCREEN_MODE_EDIT } from '@reducer/ScreenInfo';
import useSocketEvent from '@util/socket/hooks/useSocketEvent';
import { EVENT_TYPE_LOCATION } from '@reducer/SocketInfo';
import { RECOVERY_STATE_INFO } from '@util/mappInfo';
import { convertState, setTagAlarmList } from '@reducer/TagInfo/processSocketData';
import WidgetCard from '../../Components/WidgetCard';
import moment from 'moment';

const initialData = [
    {
        evtTime: 1645000665,
        targetName: 'example',
        tagStateLabel: 'Long Stay',
        categoryName: 'asset',
    },
];

// 태그 상태값은 항상 있고 on/off 상태를 다 받아야 클리어가 가능하므로 모든 데이터를 다 받음.
const filterConfig = {};

const TagStateAlarmList = ({ children, widgetInfo, ...restProps }) => {
    const t = useTranslation('TagStateAlarmList');
    const { mode } = useAppSelector(state => state.ScreenInfo);
    const columns = useMemo(
        () => [
            {
                Header: t('Time'),
                className: 'text-ellipsis',
                accessor: 'evtTime',
                headerClassName: 'text-ellipsis',
                Cell: ({ value }) => moment(value * 1000).format('HH:mm:ss'),
            },
            {
                Header: t('Target name'),
                className: 'text-ellipsis',
                accessor: 'targetName',
                headerClassName: 'text-ellipsis',
            },
            {
                Header: t('Category name'),
                className: 'text-ellipsis',
                accessor: 'categoryName',
                headerClassName: 'text-ellipsis',
            },
            {
                Header: t('Tag state'),
                className: 'text-ellipsis',
                accessor: 'tagStateLabel',
                headerClassName: 'text-ellipsis',
            },
        ],
        [t],
    );

    const { config } = widgetInfo;
    const settings = useSettings(config);

    const tagAlarmListRef = useRef([]);
    const [filteredData, setFilteredData] = useState([]);

    const widgetRef = useRef();

    const widgetData = useMemo(() => {
        return mode === SCREEN_MODE_EDIT ? { rows: initialData } : { rows: filteredData };
    }, [mode, filteredData]);

    useSocketEvent({
        name: EVENT_TYPE_LOCATION,
        filterConfig,
        handler: data => {
            const {
                target,
                targetCategory,
                targetState: { lowBattery: battery, ...restTargetState },
                unixTime,
                latlng: { lat, lng },
                currentFloor: { floorId, floorName },
            } = data;
            const targetState = { battery, ...restTargetState };
            const targetInfo = {
                evtTime: unixTime,
                target: { ...target, ...targetCategory },
                location: { floorId, floorName, lat, lng, latLng: [lat, lng] },
                state: convertState(targetState),
                recovery: convertState(targetState, RECOVERY_STATE_INFO),
            };

            setTagAlarmList(tagAlarmListRef.current, targetInfo);

            const { tagState } = settings;
            let newAlarmList = [...tagAlarmListRef.current];
            if (tagState?.length) {
                newAlarmList = tagAlarmListRef.current.filter(v => tagState.includes(v.tagState));
            }
            setFilteredData(newAlarmList);
        },
    });

    return (
        <WidgetCard widgetInfo={widgetInfo} ref={widgetRef} {...restProps}>
            <div className={'h-100'}>
                <Table paging={false} columns={columns} data={widgetData} className={'table-for-viewer'} />
            </div>
            {children}
        </WidgetCard>
    );
};

export default TagStateAlarmList;
