import { ObjectUnionType } from '@util/type/util';

// 자동완성용
export const TAG_KEYS = {
    // 위젯 기능에 따라 - 제품
    IOT_COMMON: 'IOT_COMMON',
    SH: 'SH',
    SO: 'SO',
    AM: 'AM',

    // IoT 아이템에 따라 - 데이터
    LOCATION: 'LOCATION',
    TEMPERATURE: 'TEMPERATURE',
    BODY_TEMPERATURE: 'BODY_TEMPERATURE',
    HEART_RATE: 'HEART_RATE',
    // OXYGEN_SATURATION: 'OXYGEN_SATURATION',
    IV_FLUIDS: 'IV_FLUIDS',
    RESPIRATION_RATE: 'RESPIRATION_RATE',

    // 장비 종류에 따라
    GALAXY_WATCH_5: 'GALAXY_WATCH_5',
    GALAXY_WATCH_6: 'GALAXY_WATCH_6',
    HICARDI_PLUS: 'HICARDI_PLUS',
    PARTRON_SMART_BAND: 'PARTRON_SMART_BAND',
    CHOIS_REFRIGERATOR_SENSOR: 'CHOIS_REFRIGERATOR_SENSOR',

    // 위젯 기능에 따라 - 용도
    MONITORING: 'MONITORING',
    DASHBOARD: 'DASHBOARD',
    MANUAL_SEARCH: 'MANUAL_SEARCH',
    SETTINGS: 'SETTINGS',

    // 화면 유형에 따라
    GRAPH: 'GRAPH',
    HEAT_MAP: 'HEAT_MAP',
    MAP: 'MAP',
    LIST: 'LIST',
    CARD: 'CARD',

    // IoT 아이템에 따라 - 대분류
    TAG: 'TAG',
    SENSOR: 'SENSOR',
    SCANNER: 'SCANNER',
};

export const TYPE_TO_TAGS = {
    // 위젯 기능에 따라 - 제품
    SERVICE: {
        IOT_COMMON: 'IoT Common',
        SH: 'Smart Hospital',
        SO: 'Smart OutPatient',
        AM: 'Asset Management',
    },
    // IoT 아이템에 따라 - 데이터
    DATA: {
        LOCATION: 'Location',
        TEMPERATURE: 'Temperature',
        BODY_TEMPERATURE: 'Body Temperature',
        HEART_RATE: 'Heart Rate',
        // OXYGEN_SATURATION: 'Oxygen Saturation',
        IV_FLUIDS: 'IV Fluids',
        RESPIRATION_RATE: 'Respiration Rate',
    },
    // 장비 종류에 따라
    SENSOR: {
        GALAXY_WATCH_5: 'Galaxy Watch 5',
        GALAXY_WATCH_6: 'Galaxy Watch 6',
        HICARDI_PLUS: 'Hicardi Plus',
        PARTRON_SMART_BAND: 'Partron Smart Band',
        CHOIS_REFRIGERATOR_SENSOR: 'Chois Refrigerator sensors',
    },
    // 위젯 기능에 따라 - 용도
    USAGE: {
        MONITORING: 'Monitoring',
        DASHBOARD: 'Dashboard',
        MANUAL_SEARCH: 'Manual Search',
        SETTINGS: 'Settings',
    },
    // 화면 유형에 따라
    DISPLAY: {
        GRAPH: 'Graph',
        HEAT_MAP: 'Heat Map',
        MAP: 'Map',
        LIST: 'List',
        CARD: 'Card',
    },
    // IoT 아이템에 따라 - 대분류
    CLASSIFICATION: {
        TAG: 'Tag',
        SENSOR: 'Sensor',
        SCANNER: 'Scanner',
    },
} as const;

export const TYPE_TO_LABEL: Record<TagType, string> = {
    SERVICE: 'Service',
    DATA: 'Measurement Data',
    SENSOR: 'Sensor',
    USAGE: 'Usage',
    DISPLAY: 'Display Method',
    CLASSIFICATION: 'IoT Item Classification',
};

export type TagType = keyof typeof TYPE_TO_TAGS;

const TAGS = Object.values(TYPE_TO_TAGS).reduce((acc: Record<string, string>, curr) => {
    Object.entries(curr).forEach(([key, value]) => {
        acc[key] = value;
    });
    return acc;
}, {});

export type Tag = ObjectUnionType<typeof TAGS>;
export type TagKey = keyof typeof TAGS;

export default TAGS;
