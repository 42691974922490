import React, { createContext, useContext } from 'react';
import ContextProvider from '@components/ContextProvider';
import RealTimeLocationStatusContainer from './RealTimeLocationStatusContainer';
import realTimeLocationSlice from './realTimeLocationSlice';

export const RealTimeLocationStatusStateContext = createContext(null);
export const RealTimeLocationStatusDispatchContext = createContext(null);

export const useRealTimeLocationStatusContext = () => useContext(RealTimeLocationStatusStateContext);
export const useRealTimeLocationStatusDispatch = () => useContext(RealTimeLocationStatusDispatchContext);

const RealTimeLocationStatus = ({ children, widgetInfo, ...restProps }) => {
    return (
        <ContextProvider
            StateContext={RealTimeLocationStatusStateContext}
            DispatchContext={RealTimeLocationStatusDispatchContext}
            slice={realTimeLocationSlice}
        >
            <RealTimeLocationStatusContainer widgetInfo={widgetInfo} children={children} {...restProps} />
        </ContextProvider>
    );
};

export default RealTimeLocationStatus;
