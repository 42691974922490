import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    floorInfo: {},
    selectedFloor: '',
    selectedBuilding: '',
    realTimeLog: { count: 0, data: [] },
    geofenceInfo: [],
    selectedItem: {},
    categoryList: [],
    categoryGroup: [],
    categoryForImg: {},
    bookmark: false,
    bookmarkList: [],
    selectedCategory: [],
    popup: {
        floorDetailPopup: false,
        filteringPopup: false,
    },
};

const massRealTimeLocationSlice = createSlice({
    name: 'massRealTimeLocation',
    initialState,
    reducers: {
        setFloorInfo: (state, action) => {
            state.floorInfo = { ...action.payload };
        },
        setSelectedFloor: (state, action) => {
            state.selectedFloor = action.payload;
        },
        setSelectedBuilding: (state, action) => {
            state.selectedBuilding = action.payload;
            state.floorInfo = {};
            state.selectedFloor = '';
        },
        setGeofenceInfo: (state, action) => {
            const { geofenceInfo } = action.payload;
            state.geofenceInfo = geofenceInfo.map(geofence => {
                return {
                    ...geofence,
                    bounds: [geofence.latLngList.map(({ lat, lng }) => [lat, lng])],
                };
            });
        },

        setCategoryList: (state, action) => {
            state.categoryList = [...action.payload];
        },
        setCategoryGroup: (state, action) => {
            state.categoryGroup = [...action.payload].filter(v => v.depth === 0).map(v => v.categoryCodePath);
        },
        setCategoryImg: (state, action) => {
            state.categoryForImg = { ...action.payload };
        },
        setRealTimeLog: (state, action) => {
            state.realTimeLog = action.payload;
        },
        setSelectedItem: (state, action) => {
            state.selectedItem = { ...action.payload };
        },
        setSelectedCategory: (state, action) => {
            state.selectedCategory = action.payload;
        },
        setBookmark: (state, action) => {
            state.bookmark = action.payload;
        },
        setBookmarkList: (state, action) => {
            state.bookmarkList = action.payload;
        },
        setPopup: (state, action) => {
            state.popup = { ...state.popup, ...action.payload };
        },
        clearData: (state, action) => {
            state.selectedItem = {};
            state.selectedFloor = '';
            state.floorInfo = {};
            state.geofenceInfo = [];
            state.realTimeLog = { count: 0, data: [] };
        },
    },
});

export const {
    setFloorInfo,
    setSelectedFloor,
    setGeofenceInfo,
    setCategoryList,
    setCategoryGroup,
    setCategoryImg,
    setSelectedItem,
    setRealTimeLog,
    setBookmark,
    setBookmarkList,
    setSelectedCategory,
    setPopup,
    clearData,
    setSelectedBuilding,
} = massRealTimeLocationSlice.actions;
export default massRealTimeLocationSlice;
