import React, { useState } from 'react';
import { ConfirmModal } from '@components';

// @Deprecated v0.19.0-pjt-01 - use useConfirm() instead
const useConfirmModal = ({ initModal, ...modalProps }) => {
    const [open, setOpen] = useState(initModal);
    const toggleModal = () => {
        setOpen(!open);
    };
    return {
        toggleModal: toggleModal,
        Modal: () => <ConfirmModal initModal={open} toggleModal={toggleModal} {...modalProps} />,
    };
};

export default useConfirmModal;
