import React, { createContext, useContext } from 'react';
import ContextProvider from '@components/ContextProvider';
import IotItemMappingLogContainer from './IotItemMappingLogContainer';
import iotItemMappingLogSlice from './IotItemMappingLogSlice';

/**
 * Iot 아이템 맵핑 위젯
 *
 * useAsync 커스텀 후 리팩토링 예정
 *
 * @author jinbeom-jung
 * */

export const IotItemMappingLogStateContext = createContext(null);
export const IotItemMappingLogDispatchContext = createContext(null);

export const useIotItemMappingLogContext = () => useContext(IotItemMappingLogStateContext);

export const useIotItemMappingLogDispatch = () => useContext(IotItemMappingLogDispatchContext);

const IotItemMappingLog = ({ children, widgetInfo, ...restProps }) => {
    return (
        <ContextProvider
            StateContext={IotItemMappingLogStateContext}
            DispatchContext={IotItemMappingLogDispatchContext}
            slice={iotItemMappingLogSlice}
        >
            <IotItemMappingLogContainer widgetInfo={widgetInfo} children={children} {...restProps} />
        </ContextProvider>
    );
};

export default IotItemMappingLog;
