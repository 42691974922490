const defaultConfig = {
    className: 'layout',
    breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
    cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
    rowHeight: 10,
    isBounded: true,
    containerPadding: [0, 0],
    draggableCancel: '.temp-widget button',
    // verticalCompact: false,
    // compactType: 'horizontal',
    // isDraggable: false,
    // isResizable: false,
};

export const monitoringConfig = {
    isDraggable: false,
    isResizable: false,
};

export default defaultConfig;
