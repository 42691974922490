const SENSOR = {
    ACCELER_X_AXIS: 's_accelerationXaxis',
    ACCELER_Y_AXIS: 's_accelerationYaxis',
    ACCELER_Z_AXIS: 's_accelerationZaxis',
    ANBIENT_LIGHT: 's_ambientLight',
    ANGULAR_X_AXIS: 's_angularXaxis',
    ANGULAR_Y_AXIS: 's_angularYaxis',
    ANGULAR_Z_AXIS: 's_angularZaxis',
    BAROM_PRESURE: 's_barometricPressure',
    BATTERY: 's_battery',
    BODY_TEMP: 's_temperature',
    BUTTON1_COUNT: 's_button1Count',
    BUTTON2_COUNT: 's_button2Count',
    CALORIES: 's_calories',
    CAPACITY: 's_capacity',
    CO2: 's_CO2',
    CO: 's_CO',
    COMMON: 's_sensor',
    CRACK: 's_crack',
    DISCOMFORT_IDX: 's_discomfortIndex',
    DISTANCE: 's_distance',
    DOSAGE: 's_dosage',
    DROP_COUNT: 's_dropCount',
    DROP_RATE: 's_dropRate',
    DUST: 's_dust',
    ECO2: 's_eCO2',
    ETVOC: 's_eTVOC',
    H2S: 's_H2S',
    H_BLOODPRESSURE: 's_highBloodPressure',
    L_BLOODPRESSURE: 's_lowBloodPressure',
    HEARTBEAT: 's_heartbeat',
    HEAT_STROKE: 's_heatStroke',
    HEIGHT: 's_height',
    HUMIDITY: 's_humidity',
    LEL: 's_LEL',
    MOVEMENT: 's_movement',
    NAPPY_BIG: 's_nappyBig',
    NAPPY_GAS: 's_nappyGas',
    NAPPY_SMALL: 's_nappySmall',
    O2: 's_O2',
    PACKET_COUNTER: 's_packetCounter',
    PIR: 's_pir',
    PULSE_RATE: 's_pulseRate',
    R_HUMIDITY: 's_relativeHumidity',
    SEISMIC_INTENS: 's_seismicIntensity',
    SLEEP: 's_sleep',
    SO2: 's_SO2',
    SOUND_NOISE: 's_soundNoise',
    STEP: 's_step',
    TEMPERATURE: 's_temperature',
    TOTAL_DOSAGE: 's_totalDosage',
    WEIGHT: 's_weight',

    // new
    BLOOD_GLUCOSE: 's_bloodGlucose',
    AF_BURDEN: 's_afBurden',
    AF_EPISODE_CNT: 's_afEpisodeCnt',
    ANALYZABLE_WEAR_TIME_SEC: 's_analyzableWearTimeSec',
    BLOODPRESSURE: 's_bloodPressure',
    MAX_AF_DUR_SEC: 's_maxAfDurSec',
    MIN_AF_DUR_SEC: 's_minAfDurSec',
    MEAN_AF_DUR_SEC: 's_meanAfDurSec',
    MEDIAN_AF_DUR_SEC: 's_medianAfDurSec',
    TOTAL_AF_DUR_SEC: 's_totalAfDurSec',
    TOTAL_WEAR_TIME_SEC: 's_totalWearTimeSec',
    SPO2: 's_spO2',
    RESPIRATION_RATE: 's_respirationRate',
    ETCO2: 's_etCO2',
    IH_BLOODPRESSURE: 's_iHighBloodPressure',
    IH_BLOODPRESSURE2: 's_iHighBloodPressure2',
    IL_BLOODPRESSURE: 's_iLowBloodPressure',
    IL_BLOODPRESSURE2: 's_iLowBloodPressure2',
    I_BLOODPRESSURE: 's_iBloodPressure',
    I_BLOODPRESSURE2: 's_iBloodPressure2',
    INCO2: 's_inCO2',
    IPI: 's_ipi',
    TEMPERATURE2: 's_temperature2',
    BI: 's_bi',
    CONC: 's_conc',
    DOSE: 's_dose',
    INFUSED_VOL: 's_infusedVol',
    PCA_BOLUS: 's_pcaBolus',
    RATE: 's_rate',
    VTBI: 's_vtbi',
    SPEED: 's_speed',
    STRIDE: 's_stride',
};

const STATUS = {
    CRITICAL: { iconName: 'close', color: 'danger' },
    NORMAL: { iconName: 'done', color: 'success' },
    null: { iconName: 'remove', color: 'gray' },
    undefined: { iconName: 'remove', color: 'gray' },
    OUTOFRANGE: { iconName: 'question_mark', color: 'secondary' },
    WARNING: { iconName: 'priority_high', color: 'warning' },
};

export { SENSOR, STATUS };

export const ALL_TYPE = { label: 'Fixed Type & Mobile Type', value: 'ALL_TYPE' };
export const FIXED_TYPE = { label: 'Fixed Type', value: 'F' };
export const MOBILE_TYPE = { label: 'Mobile Type', value: 'M' };

export const PARAMETER_ALL = { isAll: 'Y' };
export const PARAMETER_DEFAULT_PAGE = { pageSize: 20, page: 1 };
