import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    setMonitoringPatients,
    setSelectedDeviceInfo,
    updateFenceData,
    updateMappedSensor,
    updateSensingData,
    useBiometricInformationMonitoringDispatchContext,
    useBiometricInformationMonitoringStateContext,
} from '../../slice';
import { useAppSelector, useAsync } from '@hooks';
import { getMonitoringPatentsApi } from '@api/sh/biometricInformationMonitoring';
import PatientsTable from '../PatientsTable';
import styled from 'styled-components';
import { arraysAreEqual } from '@util/common/util';
import useSocketEvent from '@util/socket/hooks/useSocketEvent';
import { EVENT_TYPE_LOCATION, EVENT_TYPE_NUMERIC_SENSING, EVENT_TYPE_TARGET_INFO } from '@reducer/SocketInfo';
import {
    RealtimeIoTItemLocationData,
    RealtimeIoTItemNumericSensingData,
    RealtimeTargetInfo,
} from '@util/socket/socketData';
import { MODEL_CODE } from '@util/staticData/sensorMeta';
import DeviceInfoModal from '../MappingModal/DeviceInfoModal';

const PatientsMonitoring = () => {
    const dispatch = useBiometricInformationMonitoringDispatchContext();
    const {
        monitoringFilter,
        monitoringGroupList,
        selectedDeviceInfo,
    } = useBiometricInformationMonitoringStateContext();
    const wardMapInfo = useAppSelector(state => state.DepartmentsInfo.wardMapInfo);

    useAsync({
        promise: getMonitoringPatentsApi,
        fixedParam: { ...monitoringFilter, modelCodeList: [MODEL_CODE.MEZOO_SMARTPATCH] },
        deps: [monitoringFilter, wardMapInfo],
        immediate: true,
        keepState: true,
        resolve: (response, request) => {
            if (response.rows) {
                dispatch(setMonitoringPatients({ wardMapInfo, patients: response.rows }));
            }
        },
    });

    useEffect(() => {
        const newTargetNums = monitoringGroupList.reduce((accTargetNums: number[], patientGroup) => {
            return [...accTargetNums, ...patientGroup.patients.map(patient => patient.targetNum)];
        }, []);

        if (!arraysAreEqual(targetNumsRef.current, newTargetNums)) {
            targetNumsRef.current = newTargetNums;
            setTargetNums(newTargetNums);
        }
    }, [monitoringGroupList]);

    const [targetNums, setTargetNums] = useState<number[]>([]);
    const targetNumsRef = useRef<number[]>();
    const socketFilter = useMemo(() => {
        return {
            target: {
                targetNum: {
                    $in: targetNums,
                },
            },
        };
    }, [targetNums]);

    useSocketEvent({
        name: EVENT_TYPE_NUMERIC_SENSING,
        filterConfig: socketFilter,
        handler: data => {
            const {
                target,
                sensor,
                sensingState,
                sensingValues,
                unixTime,
                targetState,
            } = data as RealtimeIoTItemNumericSensingData;
            if (sensor.modelCode === MODEL_CODE.MEZOO_SMARTPATCH) {
                dispatch(
                    updateSensingData({
                        ward: target.properties.ward,
                        room: target.properties.room,
                        targetNum: target.targetNum,
                        deviceNum: sensor.sensorNum,
                        sensorName: sensor.sensorName,
                        recentSensorStates: sensingState,
                        recentSensorValues: sensingValues,
                        lastDate: unixTime,
                        lostSignalState: targetState.lostSignal,
                    }),
                );
            }
        },
    });

    useSocketEvent({
        name: EVENT_TYPE_LOCATION,
        filterConfig: socketFilter,
        handler: data => {
            const {
                target: { targetNum, properties },
                geofences,
            } = data as RealtimeIoTItemLocationData;
            dispatch(
                updateFenceData({
                    ward: properties?.ward,
                    room: properties?.room,
                    targetNum: targetNum,
                    fcList: geofences.map(({ fcNum, fcName }) => ({ fcNum, fcName })),
                }),
            );
        },
    });

    useSocketEvent({
        name: EVENT_TYPE_TARGET_INFO,
        filterConfig: socketFilter,
        handler: data => {
            const {
                target: { targetNum },
                mappedSensorList,
                metaData: { ward, room },
            } = data as RealtimeTargetInfo;
            const mappedSensor = mappedSensorList.find(({ modelCode }) => modelCode === MODEL_CODE.MEZOO_SMARTPATCH);
            dispatch(
                updateMappedSensor({
                    ward: ward,
                    room: room,
                    targetNum: targetNum,
                    ...mappedSensor,
                }),
            );
        },
    });

    return (
        <PatientsTableContainer>
            {monitoringGroupList.map(monitoringGroup => {
                return <PatientsTable key={monitoringGroup.ward + '_' + monitoringGroup.room} {...monitoringGroup} />;
            })}
            {selectedDeviceInfo && (
                <DeviceInfoModal
                    isOpen
                    toggleModal={() => {
                        dispatch(setSelectedDeviceInfo(null));
                    }}
                    targetNum={selectedDeviceInfo.targetNum}
                />
            )}
        </PatientsTableContainer>
    );
};

const PatientsTableContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
`;

export default PatientsMonitoring;
