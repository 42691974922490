import React, { createContext, useContext } from 'react';
import ContextProvider from '@components/ContextProvider';
import IotItemInfoLogSlice from './iotItemInfoLogSlice';
import IotItemInfoLogContainer from './IotItemInfoLogContainer';

/**
 * 아이템 정보 로그 위젯
 *
 * useAsync 커스텀 후 리팩토링 예정
 *
 * @author jinbeom-jung
 * */

export const IotItemInfoLogStateContext = createContext(null);
export const IotItemInfoLogDispatchContext = createContext(null);

export const useIotItemInfoLogContext = () => useContext(IotItemInfoLogStateContext);

export const useIotItemInfoLogDispatch = () => useContext(IotItemInfoLogDispatchContext);

const IotItemInfoLog = ({ widgetInfo, children, ...restProps }) => {
    return (
        <ContextProvider
            StateContext={IotItemInfoLogStateContext}
            DispatchContext={IotItemInfoLogDispatchContext}
            slice={IotItemInfoLogSlice}
        >
            <IotItemInfoLogContainer widgetInfo={widgetInfo} children={children} {...restProps} />
        </ContextProvider>
    );
};

export default IotItemInfoLog;
