import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { LoadingBlock } from '@components';
import useAsync from '@hooks/useAsync';
import { useExamPatientsMonitoringStateContext } from '../../slice';
import Label from '@components/Label';
import { getBatteryDisplayValue } from '../../../../Components/TableCommon/RemainsBattery/util';
import useTranslation from '@hooks/useTranslation';
import { getTestTargetPatientInfoApi } from '@api/sh/examPatientMonitoring';
import { getCurrentElapsedTime, secToMin } from '../../util';
import { useAppSelector } from '@hooks';
import { isMonitoring } from '@reducer/ScreenInfo';
import { getPatientApi } from '../../sampleData';

const DetailsInfo = () => {
    const t = useTranslation('ExamPatientsMonitoring');
    const mode = useAppSelector(state => state.ScreenInfo.mode);
    const { selectedSubject } = useExamPatientsMonitoringStateContext();

    const {
        state: { isLoading, response },
    } = useAsync({
        promise: isMonitoring(mode) ? getTestTargetPatientInfoApi : getPatientApi,
        fixedParam: { subjectNum: selectedSubject?.subjectNum },
        immediate: true,
        deps: [selectedSubject],
    });

    return (
        <LoadingBlock blocking={isLoading}>
            <PerfectScrollbar className={'p-3 border border-depth-7 d-flex flex-column gap-4'}>
                <Label name={t('Study Number')} value={response?.subjectNum ?? '-'} />
                <Label name={t('Stabilization Room')} value={response?.fcName ?? '-'} />
                <Label
                    name={t('Progress Time')}
                    value={
                        !isNaN(response?.elapsedTime) ? `${secToMin(getCurrentElapsedTime(response))} ${t('min')}` : '-'
                    }
                />
                <Label name={t('Device Name')} value={response?.sensorName ?? '-'} />
                <Label name={t('Battery')} value={getBatteryDisplayValue(response?.battery)} />
            </PerfectScrollbar>
        </LoadingBlock>
    );
};

export default DetailsInfo;
