import React from 'react';
import DetailsInfo from './DetailsInfo';
import VitalSignsView from './VitalSignsView';
import styled from 'styled-components';
import ContextProvider from '@components/ContextProvider';
import patientDetailsSlice, { PatientDetailsDispatchContext, PatientDetailsStateContext } from './slice';

type PatientDetailsProps = {
    targetNum?: number;
};

// TODO : 센서 모델에 따른 그래프, 센서 그래프 분리 고민
const PatientDetails = ({ targetNum }: PatientDetailsProps) => {
    return (
        <ContextProvider
            StateContext={PatientDetailsStateContext}
            DispatchContext={PatientDetailsDispatchContext}
            slice={patientDetailsSlice}
        >
            <DetailViewWrapper>
                <DetailsInfo targetNum={targetNum} />
                <VitalSignsView targetNum={targetNum} />
            </DetailViewWrapper>
        </ContextProvider>
    );
};

const DetailViewWrapper = styled.div`
    height: 100%;
    min-height: 100%;
    display: grid;
    grid-template-columns: 1fr 3fr;
    column-gap: 1rem;
`;

export default PatientDetails;
