import React, { useState } from 'react';
import { Button, ConfirmModal } from '@components';
import { useAsync, useTranslation } from '@hooks';
import cx from 'classnames';
import { Nullable } from '@util/type/util';
import { Patient, SensorStates, SensorType, SensorValues } from '../../types';
import {
    setSelectedMappingSubjectNum,
    setToggleMappingModal,
    useExamPatientsMonitoringDispatchContext,
} from '../../slice';
import { patchPauseSubjectApi, patchStartSubjectApi } from '@api/sh/examPatientMonitoring';
import { getCurrentElapsedTime, isMapped, secToMin } from '../../util';

export const SensorValueCell = ({
    value,
    states,
    sensorType,
}: {
    value: SensorValues;
    states?: Nullable<SensorStates>;
    sensorType: SensorType;
}) => {
    const state = states ? String(states[sensorType]) : 'null';
    return <div className={cx('w-100 h-100 flex-center pnt-txt s-8', STATUS_COLOR[state]?.BG)}>{value}</div>;
};

export const HeartbeatValueCell = ({
    value,
    row: { original },
}: {
    value: SensorValues;
    row: { original: Patient };
}) => {
    return <SensorValueCell value={value} states={original.recentSensorStates} sensorType={'HEARTBEAT'} />;
};

export const SpO2ValueCell = ({ value, row: { original } }: { value: SensorValues; row: { original: Patient } }) => {
    return <SensorValueCell value={value} states={original.recentSensorStates} sensorType={'SPO2'} />;
};

export const TemperatureValueCell = ({
    value,
    row: { original },
}: {
    value: SensorValues;
    row: { original: Patient };
}) => {
    return <SensorValueCell value={value} states={original.recentSensorStates} sensorType={'TEMPERATURE'} />;
};

const STATUS_COLOR: { [key: string]: { BG: string; COLOR: string } } = {
    WARNING: { BG: 'bg-warning', COLOR: '' },
    CRITICAL: { BG: 'bg-warning', COLOR: '' },
    OUTOFRANGE: { BG: 'bg-secondary bg-l-4', COLOR: '' },
    undefined: { BG: 'bg-gray bg-light', COLOR: '' },
    null: { BG: 'bg-gray bg-light', COLOR: '' },
};

export const ElapsedTimeCell = ({ value }: { value: number }) => {
    const t = useTranslation('ExamPatientsMonitoring');
    return `${secToMin(value)} ${t('min')}`;
};

export const SensorNameCell = ({
    value,
    row: { original },
}: {
    value: string;
    row: {
        original: Patient;
    };
}) => {
    const t = useTranslation('ExamPatientsMonitoring');
    const dispatch = useExamPatientsMonitoringDispatchContext();

    const handleToggleMappingModal = () => {
        dispatch(setToggleMappingModal({}));
        dispatch(
            setSelectedMappingSubjectNum({
                subjectNum: original.subjectNum,
                started: getCurrentElapsedTime(original) !== 0,
            }),
        );
    };

    return isMapped(original) ? (
        <Button
            className={'btn-trans text-decoration-underline'}
            onClick={e => {
                e.stopPropagation();
                handleToggleMappingModal();
            }}
        >
            {value}
        </Button>
    ) : (
        <Button
            className={'btn-line'}
            onClick={e => {
                e.stopPropagation();
                handleToggleMappingModal();
            }}
        >
            {t('Device mapping')}
        </Button>
    );
};

export const ProgressTypeCell = ({
    value,
    row: {
        original: { subjectNum, ...restPatient },
    },
}: {
    value: string;
    row: { original: Patient };
}) => {
    const t = useTranslation('ExamPatientsMonitoring');
    const { promise: patchStartSubject } = useAsync({
        promise: patchStartSubjectApi,
    });
    const { promise: patchPauseSubject } = useAsync({
        promise: patchPauseSubjectApi,
    });

    const [mappingValidationModal, setMappingValidationModal] = useState(false);

    return (
        <>
            {value === 'Y' ? (
                <Button
                    className={'btn-gray'}
                    onClick={e => {
                        e.stopPropagation();
                        patchPauseSubject({ subjectNum });
                    }}
                >
                    {t('Stop')}
                </Button>
            ) : (
                <Button
                    className={'btn-secondary'}
                    onClick={e => {
                        e.stopPropagation();
                        if (isMapped(restPatient)) {
                            patchStartSubject({ subjectNum });
                        } else {
                            setMappingValidationModal(true);
                        }
                    }}
                >
                    {t('Start')}
                </Button>
            )}
            <ConfirmModal
                initModal={mappingValidationModal}
                confirmText={t('The mapped device does not exist.')}
                toggleModal={() => {
                    setMappingValidationModal(prevState => !prevState);
                }}
            />
        </>
    );
};
