import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '@hooks';
import { Checkbox, Label, QuestionTooltip, TextInput } from '../../Common';
import Select from '@components/Select';
import { TAG_STATE_LABEL } from '@util/mappInfo';
import SearchableSelect from '@components/Select/SearchableSelect';
import SingleTreeSelect from '@components/Select/SingleTreeSelect';
import useAppSelector from '@hooks/useAppSelector';

export const WidgetName = ({ value, onChange }) => {
    const t = useTranslation('Widget');

    return (
        <Label
            name={t('Widget Name')}
            value={
                <TextInput
                    inputGroupClassName={'w-100'}
                    type={'text'}
                    name={'widgetName'}
                    id={'widgetName'}
                    placeholder={''}
                    value={value}
                    handleChange={e => {
                        const { value } = e.target;
                        onChange(value);
                    }}
                />
            }
        />
    );
};

export const IntervalTime = ({ value, onChange, options }) => {
    const t = useTranslation('Widget');

    return (
        <Label
            name={t('Interval Time')}
            value={
                <Select
                    isModalSelect
                    options={
                        options
                            ? options
                            : [
                                  { value: 10000, label: `10${t('seconds')}` },
                                  { value: 15000, label: `15${t('seconds')}` },
                                  { value: 20000, label: `20${t('seconds')}` },
                                  { value: 30000, label: `30${t('seconds')}` },
                              ]
                    }
                    value={value}
                    onChange={selected => onChange(selected)}
                />
            }
        />
    );
};

export const HiddenHeader = ({ checked, onChange }) => {
    const t = useTranslation('Widget');

    return (
        <Label
            name={t('Hide Header')}
            value={
                <Checkbox
                    id={'hiddenHeader'}
                    handleChecked={e => {
                        const { checked } = e.target;
                        onChange(checked);
                    }}
                    checked={checked}
                />
            }
        />
    );
};

export const ChartType = ({ value, onChange, options }) => {
    const t = useTranslation('CategoryChart');
    const graphOptions = options || [
        { label: t('Pie'), value: 'pie' },
        { label: t('Doughnut'), value: 'doughnut' },
    ];

    return (
        <Label
            name={t('Type of Graph')}
            value={
                <Select
                    value={graphOptions.find(option => option.value === value)}
                    options={graphOptions}
                    onChange={selected => onChange(selected)}
                    isModalSelect
                />
            }
        />
    );
};

export const CategoryCode = ({ value, onChange }) => {
    const t = useTranslation('CategoryChart');
    const { topCategoryList } = useSelector(state => state.CategoryInfo);

    return (
        <Label
            name={t('Type of Category')}
            value={
                <Select
                    isModalSelect
                    options={topCategoryList}
                    value={topCategoryList.find(category => category.value === value)}
                    onChange={selected => onChange(selected.value)}
                />
            }
        />
    );
};

export const TagState = ({ value, onChange, options }) => {
    const t = useTranslation('TagStateAlarmList');

    return (
        <Label
            name={t('Tag State')}
            value={
                <SearchableSelect
                    isModalSelect
                    title={t('Tag State')}
                    className={'w-100'}
                    data={
                        options ? options : Object.entries(TAG_STATE_LABEL).map(([value, label]) => ({ value, label }))
                    }
                    selected={value}
                    onChange={selected => onChange(selected)}
                />
            }
        />
    );
};

export const SingleFloor = ({ value, onChange }) => {
    const t = useTranslation('GeofenceStatus');
    const { floorList } = useSelector(state => state.FloorInfo);

    return (
        <Label
            name={t('Floor')}
            value={
                <SingleTreeSelect
                    isModalSelect
                    data={floorList}
                    title={'Category'}
                    valueKey={'floorId'}
                    labelKey={'floorName'}
                    parentKey={'upperFloorId'}
                    selected={value}
                    onChange={selected => onChange(selected)}
                />
            }
        />
    );
};

export const DataInteger = ({ checked, onChange }) => {
    const t = useTranslation('Widget');

    return (
        <Label
            name={t('Change The Y-Axios To an Integer')}
            value={
                <Checkbox
                    id={'dataInteger'}
                    handleChecked={e => {
                        const { checked } = e.target;
                        onChange(checked);
                    }}
                    checked={checked}
                />
            }
        />
    );
};

export const HiddenMapTile = ({ updateWidgetInfo, customSettings, onChange }) => {
    const t = useTranslation('Widget');

    const checked =
        typeof updateWidgetInfo.hiddenMapTile !== 'undefined'
            ? updateWidgetInfo.hiddenMapTile
            : customSettings && typeof customSettings.hiddenMapTile !== 'undefined'
            ? customSettings.hiddenMapTile
            : false;

    return (
        <Label
            name={t('Hide Map')}
            value={
                <Checkbox
                    id={'hiddenMapTile'}
                    handleChecked={e => {
                        const { checked } = e.target;
                        onChange(checked);
                    }}
                    checked={checked}
                />
            }
        />
    );
};

export const SubscribedData = ({ widgetId, selected, onChange }) => {
    const t = useTranslation('Widget');
    const validatedPubDataList = useAppSelector(state => {
        const { [widgetId]: thisData, ...restData } = state.ScreenInfo.validatedPubDataList;
        return restData;
    });

    const displayWidgetName = (originName, setName) => {
        if (setName && setName !== t(originName)) {
            return setName;
        } else {
            return t(originName);
        }
    };

    return (
        !!Object.values(validatedPubDataList).length && (
            <>
                <div className={'pnt-divider horizon-line'} />
                <div className={'flx-col gap-4 pnt-label-5'}>
                    <Label
                        name={t('Subscribe to other widgets', 'WidgetSubscribe')}
                        value={
                            <div>
                                {Object.values(validatedPubDataList).map(({ id, name, setName, pubDataList }) => {
                                    return (
                                        <React.Fragment key={id}>
                                            <div className={'pnt-txt txt-dot m-1'}>
                                                {displayWidgetName(name, setName)}
                                            </div>
                                            <CheckBoxGroup
                                                widgetId={id}
                                                dataList={pubDataList}
                                                checked={selected?.[id]}
                                                onChange={onChange}
                                            />
                                            {/*<SearchableSelect*/}
                                            {/*    title={t('Data')}*/}
                                            {/*    className={'w-100'}*/}
                                            {/*    data={pubDataList}*/}
                                            {/*    selected={selected?.[id]}*/}
                                            {/*    onChange={selected => {*/}
                                            {/*        onChange({ widgetId: id, selected });*/}
                                            {/*    }}*/}
                                            {/*    valueKey={'dataKey'}*/}
                                            {/*    labelKey={'dataLabel'}*/}
                                            {/*/>*/}
                                        </React.Fragment>
                                    );
                                })}
                            </div>
                        }
                    />
                </div>
            </>
        )
    );
};

const CheckBoxGroup = ({ widgetId, dataList, checked = [], onChange }) => {
    const t = useTranslation('WidgetSubscribe');
    const handleChange = e => {
        const { checked: targetChecked, name } = e.target;
        let changeChecked = [...checked];
        if (targetChecked) {
            changeChecked.push(name);
        } else {
            changeChecked = changeChecked.filter(dataKey => dataKey !== name);
        }
        onChange({ widgetId, checked: changeChecked });
    };
    return dataList.map(({ dataKey, dataLabel }) => {
        return (
            <Checkbox
                key={dataKey}
                inputName={dataKey}
                name={t(dataLabel)}
                checked={checked.includes(dataKey)}
                onChange={handleChange}
            />
        );
    });
};

export const HeightOptimization = ({ checked, onChange }) => {
    const t = useTranslation('Widget');

    return (
        <Label
            name={
                <>
                    {t('Height Optimization')}
                    <QuestionTooltip
                        contents={t(
                            'If only one widget exists on the screen, align the height of the widget with the screen.',
                        )}
                    />
                </>
            }
            value={
                <Checkbox
                    handleChecked={e => {
                        const { checked } = e.target;
                        onChange(checked);
                    }}
                    checked={checked}
                />
            }
        />
    );
};
