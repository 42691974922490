import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';
import Draggable from '@components/Draggable';
import { useTranslation } from '@hooks';
import { useAssetListFloorDetailColumns } from '@util/grid/column';
import Table from '@components/Table';
import { useRealTimeLocationStatusContext } from '../index';

const PAGE_SIZE = 5;
const FloorDetailPopup = ({ modal, toggleModal, widgetRef, mapRef }) => {
    const t = useTranslation('RealTimeLocationStatus');
    const { geofenceInfo, realTimeLog, categoryList } = useRealTimeLocationStatusContext();
    const [itemList, setItemList] = useState({ pageSize: PAGE_SIZE, totalPage: 0, rows: [] });

    const [position, setPosition] = useState({
        x: 0,
        y: 0,
    });
    const [popupSize, setPopupSize] = useState({
        x: 0,
        y: 0,
    });

    const popupRef = useCallback(node => {
        if (node !== null) {
            const { width, height } = node.getBoundingClientRect();

            setPopupSize({
                width,
                height,
            });
        }
    }, []);

    useEffect(() => {
        const widgetPosition = getWidgetPosition();

        const x = (widgetPosition.width - popupSize.width) / 2 - 20;
        const y = widgetPosition.height / 2 - widgetPosition.height / 4 - 100;
        setPosition({
            x: Math.ceil(x),
            y: Math.ceil(y),
        });
    }, [popupSize]);

    useEffect(() => {
        const targetList =
            realTimeLog &&
            realTimeLog.data.map(target => {
                const { target: targetInfo, floorInOutState } = target;
                const category = categoryList.find(v => targetInfo.categoryCode === v.categoryCode);
                return {
                    targetId: targetInfo.targetId,
                    targetName: targetInfo.targetName,
                    categoryName: category && category.categoryName,
                    entryTime: moment.unix(floorInOutState.inTime).format('YYYY-MM-DD HH:mm:ss'),
                };
            });
        setItemList({ ...itemList, rows: targetList });
    }, [realTimeLog]);

    const getPosition = (el, position = { top: 0, left: 0 }) => {
        if (el) {
            position.top += el.offsetTop;
            position.left += el.offsetLeft;
            getPosition(el.offsetParent, position);
        }
        return position;
    };

    const getWidgetPosition = () => {
        const widgetPosition = { x: 0, y: 0 };

        if (widgetRef.current) {
            const widget = widgetRef.current;

            const { offsetWidth: width, offsetHeight: height } = widget;

            widgetPosition.width = width;
            widgetPosition.height = height;
        }
        return widgetPosition;
    };

    return (
        <Draggable
            key={JSON.stringify(position)}
            bounds="parent"
            defaultPosition={position}
            backgroundMapElement={mapRef.current?.leafletElement}
        >
            <Toast isOpen={modal} className={'popup-open floorDetail bg-depth-4 h-100'} innerRef={popupRef}>
                <ToastHeader className={'bg-depth-4'} toggle={toggleModal}>
                    {t('Information of the Selected Floor')}
                </ToastHeader>
                <ToastBody className={'bg-depth-4'}>
                    <div className={'pnt-txt txt-dot txt-bold s-9 mb-1'}>{`${t('Number of Geofence')} : ${
                        geofenceInfo && geofenceInfo.length ? geofenceInfo.length : 0
                    }`}</div>
                    <div className={'pnt-txt txt-dot txt-bold s-9'}>{`${t(
                        'Total Number of Detected Items on the Selected Floor',
                    )} : ${realTimeLog.count ? realTimeLog.count : 0}`}</div>
                    <Table
                        columns={useAssetListFloorDetailColumns()}
                        data={{
                            rows: itemList.rows,
                            pageSize: PAGE_SIZE,
                            totalPage: Math.ceil(itemList.rows.length / PAGE_SIZE),
                            totalCount: itemList.rows.length,
                        }}
                        manualPagination={false}
                        autoResetPage={false}
                    />
                </ToastBody>
            </Toast>
        </Draggable>
    );
};
export default FloorDetailPopup;
