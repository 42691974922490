import React from 'react';
import { BarChart, Button } from '@components';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import InnerItemCard from '@components/Card/InnerItemCard';
import { useTranslation } from '@hooks';
import { OUTPATIENT_USAGE_CHART_TITLE } from '../../constants';
import styled from 'styled-components';
import { hoverBackgroundPlugin } from '../util/chartPlugin';
import { createTooltipBase } from '../util';

const COLOR_RED = 'red';

const Chart = ({ data, onDownloadClick }) => {
    const t = useTranslation('OutpatientReport');

    const barData = {
        labels: data.map(row => row.departmentName),
        datasets: [
            {
                label: t('Same-day Registration'),
                data: data.map(row => row.todayPatientCount),
                stack: 'patient',
                order: 1, // z-index 낮음
            },
            {
                label: t('Appointment Registration'),
                data: data.map(row => row.appointmentPatientCount),
                stack: 'patient',
                order: 1, // z-index 낮음
            },
            {
                label: t('Kiosk Usage'),
                data: data.map(row => row.kioskCount),
                stack: 'kiosk',
                order: 1, // z-index 낮음
            },
            {
                label: t('Kiosk Usage Rate'),
                data: data.map(row => row.kioskRate),
                type: 'line',
                fill: false,
                yAxisID: 'y1', // 라인차트는 별도의 y축 사용
                order: 0, // z-index 높음
                forcedBorderColor: COLOR_RED,
                forcedBackgroundColor: COLOR_RED,
                forcedPointBorderColor: COLOR_RED,
                forcedPointBackgroundColor: COLOR_RED,
                datalabels: {
                    display: true,
                    // 이 데이터셋에만 데이터 레이블 표시
                    color: COLOR_RED,
                    anchor: 'end',
                    align: 'top',
                    font: {
                        size: 14,
                    },
                    textStrokeColor: 'white', // 테두리 색상
                    textStrokeWidth: 1, // 테두리 두께
                    formatter: function (value) {
                        return value.toFixed(1) + '%'; // 표시할 값
                    },
                },
            },
        ],
    };

    return (
        <InnerItemCard
            className={'m-2 h-90'}
            bodyClassName={'h-100'}
            header={{ title: t(OUTPATIENT_USAGE_CHART_TITLE) }}
        >
            {
                <div className={'h-90 flx-row position-relative'}>
                    <div className={'w-100 h-100 '}>
                        <BarChart
                            data={barData}
                            type={'stackedBar'}
                            plugins={[hoverBackgroundPlugin, ChartDataLabels]}
                            options={{
                                plugins: {
                                    datalabels: {
                                        display: false, // 기본적으로 모든 데이터셋에서 레이블을 숨김
                                    },
                                    legend: {
                                        position: 'bottom',
                                    },
                                    tooltip: {
                                        intersect: false,
                                        mode: 'index',
                                        enabled: false, // 툴팁 활성화
                                        external: function (context) {
                                            createTooltipBase(context, (dataPoints, tooltipEl) => {
                                                dataPoints.forEach(dataPoint => {
                                                    const {
                                                        label: datasetLabel = '',
                                                        backgroundColor: color,
                                                        type,
                                                    } = dataPoint.dataset;
                                                    const value = dataPoint.raw;

                                                    if (type === 'line') {
                                                        const { label: department } = dataPoint;
                                                        tooltipEl.innerHTML = `
                                                    <div>
                                                        <div>${department}</div>
                                                        <div class="d-flex align-items-center gap-1">
                                                          <div style="height: 15px; width: 15px; background-color: ${color}; border-radius: 15px"></div>
                                                          <div style="color: red">${datasetLabel}: ${value}%</div>
                                                        </div>
                                                    </div>`;
                                                    } else {
                                                        tooltipEl.insertAdjacentHTML(
                                                            'beforeend',
                                                            `
                                                       <div class="d-flex align-items-center gap-1">
                                                          <div style="height: 15px; width: 15px; background-color: ${color};"></div>
                                                          <div>${datasetLabel}: ${value} ${t('Cases')}</div>
                                                       </div>`,
                                                        );
                                                    }
                                                });
                                            });
                                        },
                                    },
                                },
                                scales: {
                                    y1: {
                                        type: 'linear', // 축의 종류 (linear는 기본값)
                                        position: 'right', // y1 축을 오른쪽으로 위치시킴
                                        beginAtZero: true, // y1도 0에서 시작
                                        min: 0, // y1 최소값
                                        max: 100, // y1 최대값
                                        grid: {
                                            drawOnChartArea: false, // 오른쪽 y1 축에 대한 그리드 라인 비활성화
                                        },
                                    },
                                    y: {
                                        beginAtZero: true,
                                        suggestedMax: 10,
                                    },
                                },
                            }}
                        />
                    </div>
                    <DownloadButton className={'btn-secondary'} onClick={onDownloadClick}>
                        {t('Download')}
                    </DownloadButton>
                </div>
            }
        </InnerItemCard>
    );
};

const DownloadButton = styled(Button)`
    position: absolute;
    top: 0;
    right: 0;
`;

export default Chart;
