import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
    keyword: '',
    searchOption: '',
    fcNums: [],
    geofenceList: [],
    floorIds: { floorId: '', floorName: '' },
    floorIdPath: null,
};

const geofenceLogSlice = createSlice({
    name: 'geofenceLog',
    initialState,
    reducers: {
        setKeyword: (state, action) => {
            state.keyword = action.payload;
        },
        setSearchOption: (state, action) => {
            state.searchOption = action.payload;
        },
        setGeofence: (state, action) => {
            state.fcNums = action.payload;
        },
        setGeofenceList: (state, action) => {
            state.geofenceList = action.payload;
        },
        setFloorId: (state, action) => {
            state.floorIds = action.payload;
        },
        setFloorIdPath: (state, action) => {
            state.floorIdPath = action.payload;
        },
        reset: (state, action) => {
            const initState = { ...initialState, ...action.payload };
            for (let key in initState) {
                state[key] = initState[key];
            }
        },
    },
});

export const {
    setKeyword,
    setSearchOption,
    setGeofence,
    setGeofenceList,
    setFloorId,
    setFloorIdPath,
    reset,
} = geofenceLogSlice.actions;

export default geofenceLogSlice;
