import React, { createContext, useContext } from 'react';
import ContextProvider from '@components/ContextProvider';
import GeofenceLogContainer from './GeofenceLogContainer';
import geofenceLogSlice from './geofenceLogSlice';

/**
 * 지오펜스 로그 위젯
 *
 * 추후 useAsync 커스텀 후 리팩토링 예정
 *
 * @author jinbeom-jung
 * */

export const GeofenceLogStateContext = createContext(null);
export const GeofenceLogDispatchContext = createContext(null);

export const useGeofenceLogContext = () => useContext(GeofenceLogStateContext);
export const useGeofenceLogDispatch = () => useContext(GeofenceLogDispatchContext);

const GeofenceLog = ({ children, widgetInfo, ...restProps }) => {
    return (
        <ContextProvider
            StateContext={GeofenceLogStateContext}
            DispatchContext={GeofenceLogDispatchContext}
            slice={geofenceLogSlice}
        >
            <GeofenceLogContainer widgetInfo={widgetInfo} children={children} {...restProps} />
        </ContextProvider>
    );
};

export default GeofenceLog;
