import React from 'react';
import styled from 'styled-components';
import { useBiometricInformationDashboardStateContext } from '../../../slice';
import PatientCard from './PatientCard';

const MainView = () => {
    const { monitoringList } = useBiometricInformationDashboardStateContext();

    return (
        <CardContainer>
            {monitoringList.map(patient => (
                <PatientCard key={patient.targetNum} patient={patient} />
            ))}
        </CardContainer>
    );
};

const CardContainer = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(182px, auto));
    grid-auto-rows: 88px;
    gap: 10px;
`;

export default MainView;
