import React, { createContext, useContext } from 'react';
import ContextProvider from '@components/ContextProvider';
import LocationLogContainer from './LocationLogContainer';
import LocationLogSlice from './locationLogSlice';

/**
 * 위치 로그 위젯
 *
 * useAsync 커스텀 후 리팩토링 예정
 *
 * @author jinbeom-jung
 * */

export const LocationLogStateContext = createContext(null);
export const LocationLogDispatchContext = createContext(null);

export const useLocationLogContext = () => useContext(LocationLogStateContext);
export const useLocationLogDispatch = () => useContext(LocationLogDispatchContext);

const LocationLog = ({ children, widgetInfo, className, ...restProps }) => {
    return (
        <ContextProvider
            StateContext={LocationLogStateContext}
            DispatchContext={LocationLogDispatchContext}
            slice={LocationLogSlice}
        >
            <LocationLogContainer widgetInfo={widgetInfo} className={className} children={children} {...restProps} />
        </ContextProvider>
    );
};

export default LocationLog;
