import { DatePicker } from '@components';
import moment from 'moment';
import React from 'react';

const CustomInput = ({ value, ref, className, onClick }) => {
    return <input value={value} className={className} ref={ref} onClick={onClick} readOnly />;
};

const DatePickerGroupAM = ({ startDate, handleStartChange, endDate, handleEndChange, minStartDate }) => {
    const startDateMs = startDate * 1000;
    const startDateMoment = moment(startDateMs);

    return (
        <>
            <DatePicker
                value={startDate}
                onChange={handleStartChange}
                withoutTime
                valueType="s"
                minDate={minStartDate}
                disabledKeyboardNavigation
                customInput={<CustomInput value={startDate} />}
            />
            <span>~</span>
            <DatePicker
                value={endDate}
                onChange={handleEndChange}
                withoutTime
                valueType="s"
                minDate={startDateMs}
                maxDate={startDateMoment.add(365, 'days').valueOf()}
                disabledKeyboardNavigation
                customInput={<CustomInput value={endDate} />}
            />
        </>
    );
};

export default DatePickerGroupAM;
