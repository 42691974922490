import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const SENSOR_NUM_KEY = 'sensorNum';
// 접속시간 기준 담당시간의 시간 범위 수정
export const convertToWorkTimeCheckList = workTimeList => {
    return workTimeList.map(item => {
        const { startDate, endDate } = item;
        let start = moment(startDate * 1000);
        let end = moment(endDate * 1000);
        let checked = item.checked ?? false;
        // 현재 시간 범위에 있을 경우 종료시간 = 현재시간
        if (moment().isBetween(start, end)) {
            checked = true;
            end = moment();
        }
        // 시작 시간이 현재 시간 보다 큰 경우 시작,종료 D-1
        if (moment().unix() < start.unix()) {
            start = start.subtract(1, 'day');
            end = end.subtract(1, 'day');
        }
        return {
            ...item,
            startDate: start.unix(),
            endDate: end.unix(),
            checked,
        };
    });
};

export const initialState = {
    vitalSignsList: {},
    workTimeList: {
        origin: [],
        current: [],
    },
    isDetail: false,
    targetNum: null,
};

const vitalSignsMonitoringSlice = createSlice({
    name: 'vitalSignsMonitoring',
    initialState,
    reducers: {
        storeVitalSignList: (state, action) => {
            const data = action.payload;
            if (!data) {
                return;
            }

            state.vitalSignsList = (function loop(entryValues) {
                return Object.fromEntries(
                    Object.keys(entryValues).map(key => {
                        if (!Array.isArray(entryValues[key])) {
                            return [key, loop(entryValues[key])];
                        } else {
                            let person = {
                                mappedSensors: [],
                            };
                            entryValues[key].forEach(personData => {
                                person = {
                                    ...person,
                                    properties: personData.properties,
                                    sensorNum: personData.sensorNum,
                                    [personData.sensingType]: {
                                        value: personData.currentValue,
                                        status: personData.status,
                                        measurementUnit: personData.measurementUnit,
                                        regDate: personData.regDate,
                                    },
                                    status: personData.status,
                                    targetId: personData.targetId,
                                    targetName: personData.targetName,
                                    targetNum: personData.targetNum,
                                    isBookmark: personData.isBookmark,
                                    modDate: personData.modDate,
                                    deviceId: personData.deviceId,
                                    mappedSensors: [...person.mappedSensors, personData.deviceId],
                                };
                            });
                            return [key, person];
                        }
                    }),
                );
            })(data);
        },
        updateVitalSignList: (state, action) => {
            const vitalSignList = action.payload;
            if (!vitalSignList) {
                return;
            }
            try {
                const { comNum, sensor, target, targetCategory, sensingState, sensingValues, unixTime } = vitalSignList;
                const { sensorNum, sensorId } = sensor;

                const { targetNum: socketTargetNum } = target;
                const {
                    H_BLOODPRESSURE: highBloodPressureState,
                    L_BLOODPRESSURE: lowBloodPressureState,
                    TEMPERATURE: temperatureState,
                    PULSE_RATE: pulseRateState,
                } = sensingState;
                const {
                    H_BLOODPRESSURE: highBloodPressureValue,
                    L_BLOODPRESSURE: lowBloodPressureValue,
                    TEMPERATURE: temperatureValue,
                    PULSE_RATE: pulseRateValue,
                } = sensingValues;

                const socketHighBP =
                    highBloodPressureValue && highBloodPressureState
                        ? {
                              value: highBloodPressureValue,
                              status: highBloodPressureState,
                              regDate: unixTime,
                          }
                        : {};
                const socketLowBP =
                    lowBloodPressureValue && lowBloodPressureValue
                        ? {
                              value: lowBloodPressureValue,
                              status: lowBloodPressureState,
                              regDate: unixTime,
                          }
                        : {};
                const socketBT =
                    temperatureValue && temperatureState
                        ? {
                              value: temperatureValue,
                              status: temperatureState,
                              regDate: unixTime,
                          }
                        : {};
                const socketPR =
                    pulseRateValue && pulseRateState
                        ? {
                              value: pulseRateValue,
                              status: pulseRateState,
                              regDate: unixTime,
                          }
                        : {};

                state.vitalSignsList = (function loop(entryValues) {
                    return Object.fromEntries(
                        Object.keys(entryValues).map(key => {
                            // 특정 key 값이 존재 할 때까지 재귀
                            if (!Object.keys(entryValues[key]).includes(SENSOR_NUM_KEY)) {
                                return [key, loop(entryValues[key])];
                            }
                            // 현재 entry value
                            const currentValue = entryValues[key];
                            // 대상 key 또는 센서 key 가 맞지 않는 경우
                            if (
                                currentValue.targetNum !== socketTargetNum ||
                                !currentValue.mappedSensors.includes(sensorId)
                            ) {
                                return [key, currentValue];
                            }

                            // update sensor value
                            const updateValue = {
                                ...entryValues[key],
                                H_BLOODPRESSURE: {
                                    ...currentValue.H_BLOODPRESSURE,
                                    ...socketHighBP,
                                },
                                L_BLOODPRESSURE: {
                                    ...currentValue.L_BLOODPRESSURE,
                                    ...socketLowBP,
                                },
                                PULSE_RATE: {
                                    ...currentValue.PULSE_RATE,
                                    ...socketPR,
                                },
                                TEMPERATURE: {
                                    ...currentValue.TEMPERATURE,
                                    ...socketBT,
                                },
                                regDate: unixTime,
                            };

                            return [key, updateValue];
                        }),
                    );
                })(state.vitalSignsList);
            } catch (e) {
                console.error(e);
            }
        },
        initialWorkTimeList: (state, action) => {
            const workTimeList = action.payload.workTimeList;
            if (!workTimeList) {
                return;
            }

            // 서버에서 받은 최초 담당시간 현재시간 기준으로 생성
            const initWorkTimeList = workTimeList.map(item => {
                const { startDate, endDate } = item;
                // 서버로부터 받은 시작시간
                const responseStartDate = moment.unix(startDate);
                // 서버로부터 받은 종료시간
                const responseEndDate = moment.unix(endDate);
                // 현재 시작 시간
                let currentStartDate = moment()
                    .hours(responseStartDate.hours())
                    .minutes(responseStartDate.minutes())
                    .startOf('minutes');
                // 현재 종료 시간
                let currentEndDate = moment()
                    .hours(responseEndDate.hours())
                    .minutes(responseEndDate.minutes())
                    .endOf('minutes');
                // 시작 시간이 종료 시간보다 클 경우 D-1
                if (currentStartDate.hours() > currentEndDate.hours()) {
                    if (moment().isBetween(currentStartDate, moment().endOf('day'))) {
                        // 23:59:59 이전의 경우 end time 현재 시간으로 변경
                        currentEndDate = moment();
                    } else {
                        // 00시 이후 D-1
                        currentStartDate = currentStartDate.subtract(1, 'day');
                    }
                }
                return {
                    ...item,
                    startDate: currentStartDate.startOf('minutes').unix(),
                    endDate: currentEndDate.endOf('minutes').unix(),
                    valueOf: currentEndDate.unix() - currentStartDate.unix(),
                };
            });

            state.workTimeList = {
                origin: initWorkTimeList,
                current: convertToWorkTimeCheckList(initWorkTimeList), // 접속 시간에 따른 work time 시간 범위 수정
            };
        },
        resetCurrentWorkTime: state => {
            state.workTimeList = {
                ...state.workTimeList,
                current: convertToWorkTimeCheckList(state.workTimeList.origin),
            };
        },
        updateCurrentWorkTime: (state, action) => {
            const currentWorkTime = action.payload.currentWorkTime;
            if (!currentWorkTime) {
                return;
            }
            // 현재시간 업데이트
            const refreshCurrent = convertToWorkTimeCheckList(state.workTimeList.origin).map((item, idx) => ({
                ...item,
                checked: currentWorkTime[idx]?.checked,
            }));
            state.workTimeList = {
                ...state.workTimeList,
                current: refreshCurrent,
            };
        },
        setIsDetail: (state, action) => {
            state.isDetail = action.payload;
        },
        setTargetNum: (state, action) => {
            state.targetNum = action.payload;
        },
    },
});
export const {
    storeVitalSignList,
    updateVitalSignList,
    initialWorkTimeList,
    resetCurrentWorkTime,
    updateCurrentWorkTime,
    setIsDetail,
    setTargetNum,
} = vitalSignsMonitoringSlice.actions;
export default vitalSignsMonitoringSlice;
