export const hoverBackgroundPlugin = {
    id: 'hoverBackgroundPlugin',
    beforeDraw: chart => {
        const ctx = chart.ctx;
        const yScale = chart.scales['y'];
        const xScale = chart.scales['x'];

        // tooltip의 활성화된 요소 가져오기
        const activeElements = chart.tooltip.getActiveElements();

        // 활성화된 요소가 있을 경우 처리
        if (activeElements.length) {
            const activeElement = activeElements[0]; // 첫 번째 활성화된 데이터 포인트
            const index = activeElement.index; // 현재 tooltip이 활성화된 인덱스

            // 해당 데이터 포인트의 x 값 가져오기
            const xValue = xScale.getPixelForValue(chart.data.labels[index]);

            // 차트의 너비 계산
            const barWidth = xScale.width / chart.data.labels.length / 2;

            // 차트 왼쪽과 오른쪽 좌표 계산
            const barLeft = xValue - barWidth;
            const barRight = xValue + barWidth;

            ctx.save();
            ctx.fillStyle = 'rgba(255,239,176,0.49)';
            ctx.fillRect(
                barLeft,
                yScale.top,
                barRight - barLeft, // 차트의 너비
                yScale.bottom - yScale.top, // y축 전체에 대한 배경 색칠
            );
            ctx.restore();
        }
    },
};
