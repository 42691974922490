import Widget, { SERVICE_CODE, WIDGET_CATEGORY } from '../staticInfo';
import { TAG_KEYS as T } from '../tags';
import OutpatientCareDashboardSetting from './OutpatientCareDashboard/OutpatientCareDashboardSetting';
import OutpatientCareDashboard from './OutpatientCareDashboard';
import OutpatientCareStatus from './OutpatientCareStatus';
import OutpatientBloodDrawStatus from './OutpatientBloodDrawStatus';
import OutpatientBaselineTestingStatus from './OutpatientBaselineTestingStatus';
import OutpatientUnattendedReceivingStatus from './OutpatientUnattendedReceivingStatus';
import OutpatientProofIssuanceStatus from './OutpatientProofIssuanceStatus';
import OutpatientUnattendedReceptionStatus from './OutpatientUnattendedReceptionStatus';
import OutpatientEquipmentStatus from './OutpatientEquipmentStatus';
import OutpatientReportDashboard from './OutpatientReportDashboard';
import OutpatientUsageAnalysisByDepartment from './OutpatientUsageAnalysisByDepartment';
import OutpatientUsageAnalysisByTime from './OutpatientUsageAnalysisByTime';
import OutpatientUsageAnalysisByDay from './OutpatientUsageAnalysisByDay';
import OutpatientWaitAnalysisByDepartment from './OutpatientWaitAnalysisByDepartment';
import OutpatientWaitAnalysisByTime from './OutpatientWaitAnalysisByTime';
import OutpatientCongestionAnalysis from './OutpatientCongestionAnalysis';
import OutpatientCongestionAnalysisBySpace from './OutpatientCongestionAnalysisBySpace';
import OutstandingAmountNotification from './OutstandingAmountNotification';
import NotificationHistory from './NotificationHistory';
import NotificationHistorySetting from './NotificationHistory/NotificationHistorySetting';
import NotificationStatistics from './NotificationStatistics';
import { addServiceTag } from '../util/commons';

/**
 * 외래 진료 현황
 */
export const outpatientCareDashboard: Widget = {
    type: 'outpatientCareDashboard',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Outpatient Care Dashboard',
    desc: 'Ability to monitor outpatient appointment/waiting/completion status of outpatient visits',
    thumbnailClassName: 'w_cardmonitor',
    Component: OutpatientCareDashboard,
    SettingComponent: OutpatientCareDashboardSetting,
    config: { defaultSettings: { name: 'Outpatient Care Dashboard', intervalTime: 10000 } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 46,
        maxH: 54,
    },
};

/**
 * smart outpatient
 * 외래 진료현황 모니터링 위젯
 */
export const outpatientCareStatus: Widget = {
    type: 'outpatientCareStatus',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    tags: [T.MANUAL_SEARCH, T.TAG, T.CARD, T.LIST, T.IOT_COMMON],
    name: 'Outpatient Care Status',
    desc: 'This widget shows the status of the outpatient care status',
    thumbnailClassName: 'w_chart_search_table',
    Component: OutpatientCareStatus,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Outpatient Care Status', categoryCode: 'ASSET' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 46,
        maxH: 54,
    },
};

/**
 * smart outpatient
 * 외래 채혈검사 위젯
 */
export const outpatientBloodDrawStatus: Widget = {
    type: 'outpatientBloodDrawStatus',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    tags: [T.MANUAL_SEARCH, T.TAG, T.CARD, T.LIST],
    name: 'Outpatient Blood Draw Status',
    desc: 'This widget shows the status of the outpatient blood draw status',
    thumbnailClassName: 'w_chart_search_table',
    Component: OutpatientBloodDrawStatus,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Outpatient Blood Draw Status', categoryCode: 'ASSET' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 46,
        maxH: 54,
    },
};

/**
 * smart outpatient
 * 외래 기초검사 위젯
 */

export const outpatientBaselineTestingStatus: Widget = {
    type: 'outpatientBaselineTestingStatus',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    tags: [T.MANUAL_SEARCH, T.CARD, T.LIST],
    name: 'Outpatient Baseline Testing Status',
    desc: 'This widget shows the status of the outpatient baseline testing status',
    thumbnailClassName: 'w_chart_search_table',
    Component: OutpatientBaselineTestingStatus,
    SettingComponent: null,
    config: {
        defaultSettings: { name: 'Outpatient Baseline Testing Status', categoryCode: 'ASSET' },
    },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 46,
        maxH: 54,
    },
};

export const outpatientUnattendedReceivingStatus: Widget = {
    type: 'outpatientUnattendedReceivingStatus',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    tags: [T.MANUAL_SEARCH, T.CARD, T.LIST],
    name: 'Outpatient Unattended Receiving Status',
    desc: 'This widget shows the status of the outpatient unattended receiving status',
    thumbnailClassName: 'w_chart_search_table',
    Component: OutpatientUnattendedReceivingStatus,
    SettingComponent: null,
    config: {
        defaultSettings: { name: 'Outpatient Unattended Receiving Status', categoryCode: 'ASSET' },
    },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 46,
        maxH: 54,
    },
};

export const outpatientProofIssuanceStatus: Widget = {
    type: 'outpatientProofIssuanceStatus',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    tags: [T.MANUAL_SEARCH, T.CARD, T.LIST],
    name: 'Outpatient Proof Issuance Status',
    desc: 'This widget shows the status of the outpatient proof issuance status',
    thumbnailClassName: 'w_chart_search_table',
    Component: OutpatientProofIssuanceStatus,
    SettingComponent: null,
    config: {
        defaultSettings: { name: 'Outpatient Proof Issuance Status', categoryCode: 'ASSET' },
    },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 46,
        maxH: 54,
    },
};

export const outpatientUnattendedReceptionStatus: Widget = {
    type: 'outpatientUnattendedReceptionStatus',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    tags: [T.MANUAL_SEARCH, T.CARD, T.LIST],
    name: 'Outpatient Unattended Reception',
    desc: 'Indicates Outpatient Unattended Reception',
    thumbnailClassName: 'w_chart_search_table',
    Component: OutpatientUnattendedReceptionStatus,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Outpatient Unattended Reception' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 38,
        maxH: 54,
    },
};

export const outpatientEquipmentStatus: Widget = {
    type: 'outpatientEquipmentStatus',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    tags: [T.MANUAL_SEARCH, T.CARD, T.LIST],
    name: 'Outpatient Equipment Status',
    desc: 'Indicates Outpatient Equipment Status',
    thumbnailClassName: 'w_chart_search_table',
    Component: OutpatientEquipmentStatus,
    SettingComponent: null,
    config: { defaultSettings: { name: 'Outpatient Equipment Status' } },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 26,
        h: 38,
        maxH: 54,
    },
};

// 외래 리포트 대시보드
export const outpatientDashboard: Widget = {
    type: 'outpatientReportDashboard',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Outpatient Report Dashboard',
    desc: 'Dashboard features for a outpatient report',
    thumbnailClassName: 'w_chart_search_table',
    Component: OutpatientReportDashboard,
    SettingComponent: null,
    config: {
        defaultSettings: { name: 'Outpatient Report Dashboard' },
    },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 46,
        h: 54,
        maxH: 64,
    },
};

// 외래 사용량 분석(진료과별)
export const outpatientUsageAnalysisByDepartment: Widget = {
    type: 'outpatientUsageAnalysisByDepartment',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    tags: [T.MANUAL_SEARCH, T.GRAPH],
    name: 'Outpatient Usage Analysis By Department',
    desc: 'Dashboard for outpatient usage analysis by department.',
    thumbnailClassName: 'w_chart_search_table',
    Component: OutpatientUsageAnalysisByDepartment,
    SettingComponent: null,
    config: {
        defaultSettings: { name: 'Outpatient Usage Analysis By Department' },
    },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 42,
        h: 42,
        maxH: 54,
    },
};

// 외래 사용량 분석(시간대별)
export const outpatientUsageAnalysisByTime: Widget = {
    type: 'outpatientUsageAnalysisByTime',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    tags: [T.MANUAL_SEARCH, T.GRAPH],
    name: 'Outpatient Usage Analysis By Time',
    desc: 'Dashboard for outpatient usage analysis by time.',
    thumbnailClassName: 'w_chart_search_table',
    Component: OutpatientUsageAnalysisByTime,
    SettingComponent: null,
    config: {
        defaultSettings: { name: 'Outpatient Usage Analysis By Time' },
    },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 42,
        h: 42,
        maxH: 54,
    },
};

// 외래 사용량 분석(요일별)
export const outpatientUsageAnalysisByDay: Widget = {
    type: 'outpatientUsageAnalysisByDay',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    tags: [T.MANUAL_SEARCH, T.GRAPH],
    name: 'Outpatient Usage Analysis By Day',
    desc: 'Dashboard for outpatient usage analysis by day.',
    thumbnailClassName: 'w_chart_search_table',
    Component: OutpatientUsageAnalysisByDay,
    SettingComponent: null,
    config: {
        defaultSettings: { name: 'Outpatient Usage Analysis By Day' },
    },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 42,
        h: 42,
        maxH: 54,
    },
};

// 외래 대기시간 분석(진료과별)
export const outpatientWaitAnalysisByDepartment: Widget = {
    type: 'outpatientWaitAnalysisByDepartment',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    tags: [T.MANUAL_SEARCH, T.GRAPH],
    name: 'Outpatient Wait Analysis By Department',
    desc: 'Dashboard for outpatient wait analysis by department.',
    thumbnailClassName: 'w_chart_search_table',
    Component: OutpatientWaitAnalysisByDepartment,
    SettingComponent: null,
    config: {
        defaultSettings: { name: 'Outpatient Wait Analysis By Department' },
    },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 42,
        h: 42,
        maxH: 54,
    },
};

// 외래 대기시간 분석(시간대별 평균)
export const outpatientWaitAnalysisByTime: Widget = {
    type: 'outpatientWaitAnalysisByTime',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    tags: [T.MANUAL_SEARCH, T.GRAPH],
    name: 'Outpatient Wait Analysis By Time',
    desc: 'Dashboard for outpatient wait analysis by time.',
    thumbnailClassName: 'w_chart_search_table',
    Component: OutpatientWaitAnalysisByTime,
    SettingComponent: null,
    config: {
        defaultSettings: { name: 'Outpatient Wait Analysis By Time' },
    },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 42,
        h: 42,
        maxH: 54,
    },
};

// 외래 혼잡도 분석
export const outpatientCongestionAnalysis: Widget = {
    type: 'outpatientCongestionAnalysis',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Outpatient Congestion Analysis',
    desc: 'Dashboard for outpatient congestion analysis.',
    thumbnailClassName: 'w_chart_search_table',
    Component: OutpatientCongestionAnalysis,
    SettingComponent: null,
    config: {
        defaultSettings: { name: 'Outpatient Congestion Analysis' },
    },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 42,
        h: 42,
        maxH: 54,
    },
};

// 외래 혼잡도 분석(공간)
export const outpatientCongestionAnalysisBySpace: Widget = {
    type: 'outpatientCongestionAnalysisBySpace',
    category: WIDGET_CATEGORY.DASHBOARD,
    name: 'Outpatient Congestion Analysis By Space',
    desc: 'Dashboard for outpatient congestion analysis by space.',
    thumbnailClassName: 'w_chart_search_table',
    Component: OutpatientCongestionAnalysisBySpace,
    SettingComponent: null,
    config: {
        defaultSettings: { name: 'Outpatient Congestion Analysis By Space' },
    },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 42,
        h: 42,
        maxH: 54,
    },
};

// 미수금 알림톡 발송
export const outstandingAmountNotification = {
    type: 'outstandingAmountNotification',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    tags: [T.MANUAL_SEARCH, T.LIST],
    name: 'Outstanding Amount Notification Send',
    desc: 'Dashboard for outstanding amount lists.',
    thumbnailClassName: 'w_chart_search_table',
    Component: OutstandingAmountNotification,
    SettingComponent: null,
    config: {
        defaultSettings: { name: 'Outstanding Amount Notification Send' },
    },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 42,
        h: 42,
        maxH: 54,
    },
};

// 알림톡 발송 내역
export const notificationHistory = {
    type: 'notificationHistory',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    tags: [T.MANUAL_SEARCH, T.LIST],
    name: 'Notification History',
    desc: 'Dashboard for notification history.',
    thumbnailClassName: 'w_chart_search_table',
    Component: NotificationHistory,
    SettingComponent: NotificationHistorySetting,
    config: {
        defaultSettings: { name: 'Notification History' },
    },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 42,
        h: 42,
        maxH: 54,
    },
};

// 알림톡 발송 통계
export const notificationStatistics = {
    type: 'notificationStatistics',
    category: WIDGET_CATEGORY.MANUAL_SEARCH,
    tags: [T.MANUAL_SEARCH, T.GRAPH],
    name: 'Notification Statistics',
    desc: 'Dashboard for notification statistics.',
    thumbnailClassName: 'w_chart_search_table',
    Component: NotificationStatistics,
    SettingComponent: null,
    config: {
        defaultSettings: { name: 'Notification Statistics' },
    },
    layout: {
        minW: 8,
        w: 12,
        maxW: 12,
        minH: 42,
        h: 42,
        maxH: 54,
    },
};

/* [SO] 스마트 공공병원 */
const soWidgetList: Record<string, Widget> = {
    // outpatientCareDashboard,
    outpatientCareStatus,
    outpatientBloodDrawStatus,
    outpatientBaselineTestingStatus,
    outpatientUnattendedReceivingStatus,
    outpatientProofIssuanceStatus,
    outpatientUnattendedReceptionStatus,
    outpatientEquipmentStatus,

    // outpatientDashboard,
    outpatientUsageAnalysisByDepartment,
    outpatientUsageAnalysisByTime,
    outpatientUsageAnalysisByDay,
    outpatientWaitAnalysisByDepartment,
    outpatientWaitAnalysisByTime,
    // outpatientCongestionAnalysis,
    // outpatientCongestionAnalysisBySpace,

    outstandingAmountNotification,
    notificationHistory,
    notificationStatistics,
};

for (const widget in soWidgetList) {
    if (soWidgetList.hasOwnProperty(widget)) {
        soWidgetList[widget].serviceCode = SERVICE_CODE.SO;
        soWidgetList[widget].tags = addServiceTag(soWidgetList[widget].tags, T.SO);
    }
}

export default soWidgetList;
