import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import useFilter from '@hooks/useFilter';
import { fetchAssetCountStatus, getTargetListWithLocationApi } from '@api/common/asset';
import { useAsync } from '@hooks';
import TIMES from '@util/times';

import IotItemStatusCount from './IotItemStatusCount/IotItemStatusCount';
import IotItemStatusList from './IotItemStatusList/IotItemStatusList';
import { useIotItemStatusContext } from '../index';
import { DEFAULT_IoT_COUNT, DEFAULT_IoT_ITEM_LIST, DEFAULT_PARAMETERS } from '../constants';
import useMonitor from '../../../util/useMonitor';
import defaultData from './IotItemStatusList/defaultData.json';
import { useSettings } from '../../../util/useSettings';

const IotItemWrapper = ({ widgetInfo }) => {
    const { config } = widgetInfo;
    const { categoryCode: parentCategory } = useSettings(config);
    const { monitoring, globalIntervalTime } = useSelector(state => state.GlobalIntervalTime);
    const { topCategoryList, categoryList } = useSelector(state => state.CategoryInfo);
    const { selectedIotItemStatus: status, target } = useIotItemStatusContext();
    const fixedParams = useMemo(() => {
        return { ...DEFAULT_PARAMETERS, categoryCodes: parentCategory };
    }, [parentCategory]);
    const filterParam = useFilter({ preParam: fixedParams });
    const [dynamicParam, setDynamicParam] = useState(filterParam);

    const tarnsFilter = useMemo(() => {
        const propertySearchs = [];
        const filterMap = new Map();

        Object.entries(dynamicParam).forEach(([key, value], index) => {
            const propertyIdKey = `targetPropertiesSearch.propertySearchs[${index}].propertyId`;
            const valuesKey = `targetPropertiesSearch.propertySearchs[${index}].values`;

            if (dynamicParam.hasOwnProperty(propertyIdKey) && dynamicParam.hasOwnProperty(valuesKey)) {
                propertySearchs.push({
                    propertyId: dynamicParam[propertyIdKey],
                    values: [dynamicParam[valuesKey]], // 각 값을 배열로 감쌉니다.
                });
            }

            if (!key.includes('targetPropertiesSearch')) {
                filterMap.set(key, value);
            }
        });

        return {
            ...Object.fromEntries(filterMap),
            targetPropertiesSearch: {
                categoryCode: parentCategory,
                propertySearchs: propertySearchs,
            },
        };
    }, [dynamicParam]);
    // 페이지 이동 event
    const handlePageChange = pageIndex => {
        setDynamicParam(prevState => {
            return { ...prevState, page: pageIndex };
        });
    };

    const [iotItemCount, setIotItemCount] = useState(DEFAULT_IoT_COUNT);
    const { monitor: getAssetCount } = useMonitor({
        config,
        defaultData: DEFAULT_IoT_COUNT,
        dynamicParam: tarnsFilter,
        fetchData: fetchAssetCountStatus,
        makeData: ({ newData }) => {
            setIotItemCount(newData);
        },
    });

    // IoT Item 목록정보
    const [iotItemList, setIotItemList] = useState(DEFAULT_IoT_ITEM_LIST);
    // IoT Item 목록 조회 (모니터링)
    const { monitor: getIotItems } = useMonitor({
        config,
        defaultData,
        dynamicParam: tarnsFilter,
        fetchData: getTargetListWithLocationApi,
        makeData: ({ newData }) => {
            if (newData.rows) {
                setIotItemList({ ...DEFAULT_IoT_ITEM_LIST, ...newData });
            }
        },
    });

    // bookmark handling
    const bookmarkSuccessCallback = () => {
        getAssetCount();
        getIotItems();
    };

    // merge filter params and search params
    useEffect(() => {
        // ward 정보 -1 경우 (로그인 사용자 그룹)
        // fixme: filter parameter 중 병동 값 -1 을 막기 위한 임시 코드
        if (filterParam['targetPropertiesSearch.propertySearchs[0].values'] < 0) {
            return;
        }

        setDynamicParam(prevState => {
            let defaultParams = { ...filterParam, target, status };

            const dynamicCategoryCodeList = [parentCategory];

            if (filterParam.categoryCodes) {
                filterParam.categoryCodes.split(',').forEach(code => {
                    // 위젯 설정 카테고리를 우선적으로 적용하기 위하여 filter 카테고리의 최상위 카테고리는 제외한다.
                    if (~topCategoryList.findIndex(({ value }) => value === code)) {
                        return;
                    }
                    // 자식(하위) 카테고리가 parentCategory 에 속할 때만 카테고리 추가
                    if (categoryList.find(({ categoryCode }) => categoryCode === code)?.parentCode === parentCategory) {
                        dynamicCategoryCodeList.push(code);
                    }
                });
            }

            // 카테고리 필터가 없을 때, 카테고리 필터에서 parentCategory 의 자식(하위) 카테고리가 아무것도 선택되지 않았을 때, 자식 전체를 선택
            if (dynamicCategoryCodeList.length === 1) {
                categoryList.forEach(({ parentCode, categoryCode }) => {
                    if (parentCode === parentCategory) {
                        dynamicCategoryCodeList.push(categoryCode);
                    }
                });
            }

            defaultParams = { ...defaultParams, categoryCodes: dynamicCategoryCodeList.toString() };

            if (!status) {
                delete defaultParams.status;
            }
            if (!target) {
                delete defaultParams.target;
            }
            if (prevState.page && prevState.page !== defaultParams.page) {
                return defaultParams;
            }
            return { ...defaultParams, ...DEFAULT_PARAMETERS };
        });
    }, [filterParam, status, target, parentCategory, topCategoryList]);

    // 갱신 안함 시 호출
    useEffect(() => {
        if (!monitoring || globalIntervalTime === TIMES.ZERO) {
            getAssetCount();
            getIotItems();
        }
    }, [dynamicParam]);

    return (
        <>
            <IotItemStatusCount widgetInfo={widgetInfo} iotItemCount={iotItemCount} />
            <IotItemStatusList
                widgetInfo={widgetInfo}
                dynamicParam={dynamicParam}
                handleIotItemListParam={handlePageChange}
                handleIotItemList={setIotItemList}
                iotItemList={iotItemList}
                bookmarkSuccessCallback={bookmarkSuccessCallback}
            />
        </>
    );
};

export default IotItemWrapper;
