import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Map, Button, RotatedImageOverlay } from '@components';
import Control from 'react-leaflet-control';
import DetectedTargetPopup from './DetectedTargetPopup';
import { useTranslation } from '@hooks';
import { useHeatmapContext, useHeatmapDispatch } from '../index';
import HeatmapLayer from './HeatmapLayer';
import { setHeatmapPointsLocUpdate } from '../heatMapSlice';

const HeatMap = ({ settings }, ref) => {
    const t = useTranslation('HeatMap');
    const state = useHeatmapContext();
    const dispatch = useHeatmapDispatch();
    const { selectedFloor, play, playTime, resultHeatmapPointsLoc } = state;
    const { hiddenMapTile } = settings;
    const confirmedTile = hiddenMapTile !== undefined ? !hiddenMapTile : false;

    useEffect(() => {
        if (play) {
            dispatch(setHeatmapPointsLocUpdate(playTime));
        }
    }, [playTime, play]);

    const mapRef = useRef();

    const heatMapConfig = {
        radius: 15,
        blur: 15,
        gradient: {
            0.1: 'blue',
            0.2: 'lime',
            0.3: 'yellow',
            0.4: 'lightsalmon',
        },
    };

    const [togglePopup, setTogglePopup] = useState(false);
    const handleModalToggle = () => {
        setTogglePopup(!togglePopup);
    };

    return (
        <div className={'w-100 h-75'}>
            <Map tile={confirmedTile} rotation={selectedFloor?.rotation} ref={mapRef} zoom={13}>
                <Control position="topleft" className={'control-container'}>
                    <Button iconName="info_outline" onClick={handleModalToggle} className={'leaflet-map-btn btn-icon'}>
                        {t('Detail')}
                    </Button>
                </Control>
                {!!selectedFloor ? (
                    selectedFloor.imgURL && (
                        <RotatedImageOverlay
                            key={selectedFloor.floorId}
                            url={selectedFloor.imgURL}
                            deg={selectedFloor.deg}
                            bounds={selectedFloor.bounds}
                            onLoad={() => {
                                const map = mapRef?.current?.leafletElement;
                                if (map) {
                                    map.fitBounds(selectedFloor.bounds);
                                }
                            }}
                        />
                    )
                ) : (
                    <div
                        style={{
                            fontSize: '1.5rem',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}
                        className={'pnt-txt'}
                    >
                        {t('No Floor')}
                    </div>
                )}
                <HeatmapLayer
                    blur={heatMapConfig.blur}
                    radius={heatMapConfig.radius}
                    gradient={heatMapConfig.gradient}
                    points={resultHeatmapPointsLoc.flat(1)}
                    max={20} // 낮으면 낮을 수록 진해지는 속도가 빨라짐
                    longitudeExtractor={m => m[1]}
                    latitudeExtractor={m => m[0]}
                    intensityExtractor={m => parseFloat(m[2])}
                />
                <DetectedTargetPopup
                    modal={togglePopup}
                    toggleModal={handleModalToggle}
                    widgetRef={ref}
                    mapRef={mapRef}
                />
            </Map>
        </div>
    );
};

export default forwardRef(HeatMap);
