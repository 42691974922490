import { useState } from 'react';
import { ConfirmModalProps } from '@components/type';

interface UseConfirmParam extends ConfirmModalProps {
    toggleModal: () => void;
}

const useConfirm = (param: Partial<Omit<ConfirmModalProps, 'toggleModal'>>): UseConfirmParam => {
    const [open, setOpen] = useState(param.initModal);
    const toggleModal = () => {
        setOpen(!open);
    };
    return {
        ...param,
        initModal: open,
        toggleModal,
    };
};

export default useConfirm;
