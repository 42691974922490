import { createSlice } from '@reduxjs/toolkit';
import { SOCKET_INSTANCE } from '@util/symbol/window';
import { getLsUserInfo, initUUIDv4 } from '@util/common/util';
import { AnySocketData } from '@util/socket/socketData';
import { ObjectUnionType } from '@util/type/util';
import { WritableDraft } from 'immer/dist/types/types-external';

// 서비스, 프로젝트 구분
const SCOPE_TYPE = { COMMON: 'common', SH: 'sh01' } as const;
type ScopeType = ObjectUnionType<typeof SCOPE_TYPE>;
const NAME_DELIMITER = ':';
type NameSlice = string | number | null;
function joinNameSlices(...nameSlice: NameSlice[]) {
    return Array.from(arguments).join(NAME_DELIMITER);
}

// 응답 데이터 타입 - 위치 측위
export const DATA_TYPE_LOCATION = 'REALTIME_IOT_ITEM_LOCATION';
// 응답 데이터 타입 - 수치형 센싱
export const DATA_TYPE_NUMERIC_SENSING = 'REALTIME_IOT_ITEM_NUMERIC_SENSING';
// 응답 데이터 타입 - 파형 센싱
export const DATA_TYPE_WAVEFORM_SENSING = 'REALTIME_IOT_ITEM_WAVEFORM_SENSING';
// 응답 데이터 타입 - 태그 위치 이벤트
export const DATA_TYPE_TAG_LOCATION_EVENT = 'REALTIME_IOT_ITEM_TAG_LOCATION_EVENT';
// 응답 데이터 타입 - 태그 상태 이벤트
export const DATA_TYPE_TAG_STATUS_EVENT = 'REALTIME_IOT_ITEM_TAG_STATUS_EVENT';
// 응답 데이터 타입 - 센서 상태 이벤트
export const DATA_TYPE_SENSOR_STATUS_EVENT = 'REALTIME_IOT_ITEM_SENSOR_STATUS_EVENT';
// 응답 데이터 타입 - 센서 아이템 상태 이벤트
export const DATA_TYPE_SENSOR_ITEM_STATUS_EVENT = 'REALTIME_IOT_ITEM_SENSOR_ITEM_STATUS_EVENT';
// 응답 데이터 타입 - 위급 상황 센서 아이템 상태 이벤트
export const DATA_TYPE_EMERGENCY_SENSOR_ITEM_STATUS_EVENT = 'REALTIME_EMERGENCY_SENSOR_ITEM_STATUS_EVENT';
// 응답 데이터 타입 - 수액 센싱
export const DATA_TYPE_IV_INJECTION_SENSING = 'REALTIME_IOT_ITEM_IV_INJECTION_SENSING';
// 응답 데이터 타입 - 검사 환자 모니터링 정보
export const DATA_TYPE_SUBJECT_PATIENT_DATA = 'REALTIME_SUBJECT_PATIENT_DATA';
// 응답 데이터 타입 - 대상 정보
export const DATA_TYPE_TARGET_INFO = 'REALTIME_TARGET_INFO';

const DATA_TYPE_TO_SERVICE: Record<DataType, ScopeType> = {
    [DATA_TYPE_IV_INJECTION_SENSING]: SCOPE_TYPE.SH,
    [DATA_TYPE_SUBJECT_PATIENT_DATA]: SCOPE_TYPE.SH,
    [DATA_TYPE_LOCATION]: SCOPE_TYPE.COMMON,
    [DATA_TYPE_NUMERIC_SENSING]: SCOPE_TYPE.COMMON,
    [DATA_TYPE_WAVEFORM_SENSING]: SCOPE_TYPE.COMMON,
    [DATA_TYPE_TAG_LOCATION_EVENT]: SCOPE_TYPE.COMMON,
    [DATA_TYPE_TAG_STATUS_EVENT]: SCOPE_TYPE.COMMON,
    [DATA_TYPE_SENSOR_STATUS_EVENT]: SCOPE_TYPE.COMMON,
    [DATA_TYPE_SENSOR_ITEM_STATUS_EVENT]: SCOPE_TYPE.COMMON,
    [DATA_TYPE_EMERGENCY_SENSOR_ITEM_STATUS_EVENT]: SCOPE_TYPE.COMMON,
    [DATA_TYPE_TARGET_INFO]: SCOPE_TYPE.COMMON,
};

// string 상수로 해야 socket 이벤트 핸들러에서 정확한 타입 추론 가능. 함수 리턴이 유니크 하도록 제네릭등을 주면 괜찮아 보이지만 리턴타입을 한번씩 더 선언 해줘야 함.
// 이벤트 타입 - 훅 응답
export const EVENT_TYPE_HOOK_RESPONSE = 'hook-response';
// 이벤트 타입 - 위치 측위 데이터
export const EVENT_TYPE_LOCATION = `${SCOPE_TYPE.COMMON}:${DATA_TYPE_LOCATION}` as const;
// 이벤트 타입 - 수치형 센싱 데이터
export const EVENT_TYPE_NUMERIC_SENSING = `${SCOPE_TYPE.COMMON}:${DATA_TYPE_NUMERIC_SENSING}` as const;
// 이벤트 타입 - 파형 센싱 데이터
export const EVENT_TYPE_WAVEFORM_SENSING = `${SCOPE_TYPE.COMMON}:${DATA_TYPE_WAVEFORM_SENSING}` as const;
// 이벤트 타입 - 태그 위치 이벤트 데이터
export const EVENT_TYPE_TAG_LOCATION_EVENT = `${SCOPE_TYPE.COMMON}:${DATA_TYPE_TAG_LOCATION_EVENT}` as const;
// 이벤트 타입 - 태그 상태 이벤트 데이터
export const EVENT_TYPE_TAG_STATUS_EVENT = `${SCOPE_TYPE.COMMON}:${DATA_TYPE_TAG_STATUS_EVENT}` as const;
// 이벤트 타입 - 센서 상태 이벤트 데이터
export const EVENT_TYPE_SENSOR_STATUS_EVENT = `${SCOPE_TYPE.COMMON}:${DATA_TYPE_SENSOR_STATUS_EVENT}` as const;
// 이벤트 타입 - 센서 아이템 상태 이벤트 데이터
export const EVENT_TYPE_SENSOR_ITEM_STATUS_EVENT = `${SCOPE_TYPE.COMMON}:${DATA_TYPE_SENSOR_ITEM_STATUS_EVENT}` as const;
// 이벤트 타입 - 위급 상황 센서 아이템 상태 이벤트 데이터
export const EVENT_TYPE_EMERGENCY_SENSOR_ITEM_STATUS_EVENT = `${SCOPE_TYPE.COMMON}:${DATA_TYPE_EMERGENCY_SENSOR_ITEM_STATUS_EVENT}` as const;
export const EVENT_TYPE_IV_INJECTION_SENSING = `${SCOPE_TYPE.SH}:${DATA_TYPE_IV_INJECTION_SENSING}` as const;
// 이벤트 타입 - 검사 환자 모니터링 정보 데이터
export const EVENT_TYPE_SUBJECT_PATIENT_DATA = `${SCOPE_TYPE.SH}:${DATA_TYPE_SUBJECT_PATIENT_DATA}` as const;
// 이벤트 타입 - 대상 정보 데이터
export const EVENT_TYPE_TARGET_INFO = `${SCOPE_TYPE.COMMON}:${DATA_TYPE_TARGET_INFO}` as const;

// 요청 메시지 타입 - 구독 설정
export const MESSAGE_TYPE_HOOK = 'hook';
// 요청 메시지 타입 - 구독 해제
export const MESSAGE_TYPE_UNHOOK = 'unhook';
// 요청 메시지 타입 - 필터 설정
export const MESSAGE_TYPE_HOOK_FILTER_SETUP = 'hook-filter-setup';
// 요청 메시지 타입 - 필터 해제
export const MESSAGE_TYPE_HOOK_FILTER_UNSET = 'hook-filter-unset';
// 요청 메시지 타입 - 구독 시작(데이터 전송 시작)
export const MESSAGE_TYPE_HOOK_OPEN_SETUP = 'hook-open-setup';

export const DATA_TYPE = {
    DATA_TYPE_LOCATION,
    DATA_TYPE_NUMERIC_SENSING,
    DATA_TYPE_WAVEFORM_SENSING,
    DATA_TYPE_TAG_LOCATION_EVENT,
    DATA_TYPE_TAG_STATUS_EVENT,
    DATA_TYPE_SENSOR_STATUS_EVENT,
    DATA_TYPE_SENSOR_ITEM_STATUS_EVENT,
    DATA_TYPE_EMERGENCY_SENSOR_ITEM_STATUS_EVENT,
    DATA_TYPE_IV_INJECTION_SENSING,
    DATA_TYPE_SUBJECT_PATIENT_DATA,
    DATA_TYPE_TARGET_INFO,
};
type DataType = ObjectUnionType<typeof DATA_TYPE>;

export const EVENT_TYPE = {
    EVENT_TYPE_LOCATION,
    EVENT_TYPE_NUMERIC_SENSING,
    EVENT_TYPE_WAVEFORM_SENSING,
    EVENT_TYPE_TAG_LOCATION_EVENT,
    EVENT_TYPE_TAG_STATUS_EVENT,
    EVENT_TYPE_SENSOR_STATUS_EVENT,
    EVENT_TYPE_SENSOR_ITEM_STATUS_EVENT,
    EVENT_TYPE_EMERGENCY_SENSOR_ITEM_STATUS_EVENT,
    EVENT_TYPE_IV_INJECTION_SENSING,
    EVENT_TYPE_SUBJECT_PATIENT_DATA,
    EVENT_TYPE_TARGET_INFO,
} as const;

// EVENT_TYPE_LOCATION | EVENT_TYPE_NUMERIC_SENSING | EVENT_TYPE_WAVEFORM_SENSING | EVENT_TYPE_IV_INJECTION_SENSING
export type EventType = ObjectUnionType<typeof EVENT_TYPE>;

type ComNum = null | number;

interface Channels {
    locatingChannelName?: null | string;
    numericSensingChannelName?: null | string;
    waveformSensingChannelName?: null | string;
    tagLocationEventChannelName?: null | string;
    tagStatusEventChannelName?: null | string;
    sensorStatusEventChannelName?: null | string;
    sensorItemStatusEventChannelName?: null | string;
    emergencySensorItemStatusEventChannelName?: null | string;
    IVInjectionSensingChannelName?: null | string;
    subjectPatientDataChannelName?: null | string;
    targetInfoChannelName?: null | string;
}

const makeDataChannelName = (dataType: DataType, comNum: ComNum) => {
    return joinNameSlices(MESSAGE_TYPE_HOOK, DATA_TYPE_TO_SERVICE[dataType], dataType, comNum);
};
const eventNameToDataType = (eventName: string) => {
    const splitWords = eventName.split(NAME_DELIMITER);
    return splitWords[1];
};

const makeChannelNames = (comNum: ComNum): Channels => {
    const channels: Channels = {};
    if (comNum) {
        return {
            locatingChannelName: makeDataChannelName(DATA_TYPE_LOCATION, comNum),
            numericSensingChannelName: makeDataChannelName(DATA_TYPE_NUMERIC_SENSING, comNum),
            waveformSensingChannelName: makeDataChannelName(DATA_TYPE_WAVEFORM_SENSING, comNum),
            tagLocationEventChannelName: makeDataChannelName(DATA_TYPE_TAG_LOCATION_EVENT, comNum),
            tagStatusEventChannelName: makeDataChannelName(DATA_TYPE_TAG_STATUS_EVENT, comNum),
            sensorStatusEventChannelName: makeDataChannelName(DATA_TYPE_SENSOR_STATUS_EVENT, comNum),
            sensorItemStatusEventChannelName: makeDataChannelName(DATA_TYPE_SENSOR_ITEM_STATUS_EVENT, comNum),
            emergencySensorItemStatusEventChannelName: makeDataChannelName(
                DATA_TYPE_EMERGENCY_SENSOR_ITEM_STATUS_EVENT,
                comNum,
            ),
            IVInjectionSensingChannelName: makeDataChannelName(DATA_TYPE_IV_INJECTION_SENSING, comNum),
            subjectPatientDataChannelName: makeDataChannelName(DATA_TYPE_SUBJECT_PATIENT_DATA, comNum),
            targetInfoChannelName: makeDataChannelName(DATA_TYPE_TARGET_INFO, comNum),
        };
    }
    return {
        locatingChannelName: null,
        numericSensingChannelName: null,
        waveformSensingChannelName: null,
        tagLocationEventChannelName: null,
        tagStatusEventChannelName: null,
        sensorStatusEventChannelName: null,
        sensorItemStatusEventChannelName: null,
        emergencySensorItemStatusEventChannelName: null,
        IVInjectionSensingChannelName: null,
        subjectPatientDataChannelName: null,
        targetInfoChannelName: null,
    };
};

const PREFIX_SOCKET_FILTER_ID = 'filter_';
export const generateSocketFilterId = () => `${PREFIX_SOCKET_FILTER_ID}${initUUIDv4()}`;

interface SocketHookResponse {
    event: string;
    channel: string;
    code: number;
}

const prepareSocketHook = (socket: SocketIOClient.Socket, channels: (string | null)[]) => {
    if (socket) {
        socket.on(EVENT_TYPE_HOOK_RESPONSE, ({ event, channel, code }: SocketHookResponse) => {
            // 채널 구독 완료 체크
            if (event === MESSAGE_TYPE_HOOK && channels.includes(channel) && code === 200) {
                // 구독 시작(데이터 전송 시작)
                socket.emit(MESSAGE_TYPE_HOOK_OPEN_SETUP, {
                    channel: channel,
                    isOpen: true,
                });
            }
        });
        channels.forEach(channelName => {
            socket.emit(MESSAGE_TYPE_HOOK, channelName);
        });
    }
};

const removeSocketHook = (socket: SocketIOClient.Socket) => {
    if (socket) {
        socket.off(EVENT_TYPE_HOOK_RESPONSE);
    }
};

const addSocketFilter = (
    socket: null | SocketIOClient.Socket,
    socketFilterInfo: SocketFilterInfo,
    state: WritableDraft<SocketInfo>,
) => {
    if (socket) {
        const {
            messageType,
            callback,
            filterInfo: { filterId, filterConfig },
        } = socketFilterInfo;

        if (typeof callback === 'function') {
            socket.on(messageType, callback);
        }
        const channel = makeDataChannelName(eventNameToDataType(messageType), state.comNum);

        socket.emit(MESSAGE_TYPE_HOOK_OPEN_SETUP, {
            channel,
            isOpen: false,
        });

        socket.emit(MESSAGE_TYPE_HOOK_FILTER_SETUP, {
            channel,
            filterId: filterId,
            filterConfig: filterConfig,
        });

        socket.emit(MESSAGE_TYPE_HOOK_OPEN_SETUP, {
            channel,
            isOpen: true,
        });

        state.filterList[filterId] = socketFilterInfo;
    }
};

const removeSocketFilter = (
    socket: null | SocketIOClient.Socket,
    socketFilterInfo: RemoveSocketFilterInfo,
    state: WritableDraft<SocketInfo>,
) => {
    if (socket) {
        const { messageType, callback, filterId } = socketFilterInfo;
        const offCallback = state.filterList[filterId]?.callback ?? callback;

        if (typeof offCallback === 'function') {
            socket.off(messageType, offCallback);
        } else {
            socket.off(messageType);
        }

        if (filterId) {
            socket.emit(MESSAGE_TYPE_HOOK_FILTER_UNSET, {
                channel: makeDataChannelName(eventNameToDataType(messageType), state.comNum),
                filterId: filterId,
            });
            state.filterList[filterId] = null;
            delete state.filterList[filterId];
        }
    }
};

const refreshSocketFilter = (socket: SocketIOClient.Socket, state: WritableDraft<SocketInfo>) => {
    const { filterList } = state;
    Object.entries(filterList).forEach(([filterId, socketFilterInfo]) => {
        if (socketFilterInfo) {
            const removeFilterInfo = { ...socketFilterInfo, filterId };
            removeSocketFilter(socket, removeFilterInfo, state);
            addSocketFilter(socket, socketFilterInfo, state);
        }
    });
};

export interface SocketInfo {
    comNum: ComNum;
    channels: Channels;
    filterList: SocketFilterList;
}

interface SocketFilterList {
    [key: string]: null | SocketFilterInfo;
}

interface SocketFilterInfo {
    messageType: string;
    callback: null | ((data: any) => void);
    filterInfo: {
        filterId: string;
        filterConfig: object;
    };
}

interface RemoveSocketFilterInfo extends Omit<SocketFilterInfo, 'filterInfo'> {
    filterId: string;
}

const initialState: SocketInfo = {
    comNum: null,
    channels: {
        locatingChannelName: null,
        numericSensingChannelName: null,
        waveformSensingChannelName: null,
        tagLocationEventChannelName: null,
        tagStatusEventChannelName: null,
        sensorStatusEventChannelName: null,
        sensorItemStatusEventChannelName: null,
        emergencySensorItemStatusEventChannelName: null,
        IVInjectionSensingChannelName: null,
    },
    filterList: {},
};

export const removeSocket = (socketSymbol: symbol) => {
    const socket = (window as any)[socketSymbol];
    if (socket) {
        socket.removeAllListeners();
        socket.close();
        (window as any)[socketSymbol] = null;
    }
};

const { actions, reducer } = createSlice({
    name: 'socketInfo',
    initialState,
    reducers: {
        initSocket: state => {
            const { userInfo } = getLsUserInfo();
            const socket = window[SOCKET_INSTANCE];
            if (socket) {
                state.comNum = userInfo?.companyInfo?.comNum;
                state.channels = makeChannelNames(state.comNum);
                if (socket?.connected) {
                    prepareSocketHook(socket, Object.values(state.channels));
                    refreshSocketFilter(socket, state);
                } else {
                    removeSocketHook(socket);
                }
            }
        },
        destroySocket: () => {
            removeSocket(SOCKET_INSTANCE);
            return initialState;
        },
        emitMessage: (state, action) => {
            const { eventName, data } = action.payload;
            if (window[SOCKET_INSTANCE]) {
                (window[SOCKET_INSTANCE] as SocketIOClient.Socket).emit(eventName, data);
            }
        },
        setEventHandler: (state, action) => {
            const { messageType, callback } = action.payload;
            const socket = window[SOCKET_INSTANCE];
            if (socket) {
                if (typeof callback === 'function') {
                    socket.on(messageType, callback);
                } else {
                    // Deprecated in the 2023.03 release
                    // Use the 'removeEventHandler' reducer.
                    socket.off(messageType);
                }
            }
        },
        setEventHandlerWithFilter: (state, action) => {
            const socketFilterInfo = action.payload;
            const {
                callback,
                filterInfo: { filterId },
            } = socketFilterInfo;

            if (typeof callback === 'function') {
                socketFilterInfo.callback = (data: AnySocketData) => {
                    if (data.hooksMetadata.filterIds.includes(filterId)) {
                        callback(data);
                    }
                };
            } else {
                socketFilterInfo.callback = () => {};
            }

            addSocketFilter(window[SOCKET_INSTANCE], socketFilterInfo, state);
        },
        removeEventHandler: (state, action) => {
            removeSocketFilter(window[SOCKET_INSTANCE], action.payload, state);
        },
    },
});

export const {
    initSocket,
    destroySocket,
    emitMessage,
    setEventHandler,
    setEventHandlerWithFilter,
    removeEventHandler,
} = actions;
export default reducer;
