import { Table } from '@components';
import { useAsync, useTranslation } from '@util/hooks';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ASSET_MANAGEMENT } from '../../../constants';
import * as column from '@util/grid/column';
import useModal from '../../../util/useModal';
import CellButton from '../CellButton';
import RentalActionModal from '../Modals/RentalActionModal';
import cx from 'classnames';
import { checkRentalStatusResponse, isOverdue, RENTAL_STATUS_RESPONSE } from '../utils/check';
import Search from '../../Components/Search';
import { patchHandOverApprove } from '@api/am/management';
import AssetDetailModal from '../Modals/AssetDetailModal';
import moment from 'moment';
import RentalDenyModal from '../Modals/RentalDenyModal';

export const HAND_OVER = 'hand over';
export const RETURN = 'return';

const Lend = ({ data, getRentalList, onKeywordChange, onResetClick, onPageChange, settingIdleAsset }) => {
    const t = useTranslation(ASSET_MANAGEMENT);

    const [rentalInfo, setRentalInfo] = useState();
    const [isHandOverOpen, handleHandOverToggle, handleHandOverOpen] = useModal();
    const [isDenyOpen, handleDenyToggle, handleDenyOpen] = useModal();
    const [isDetailOpen, handleDetailToggle, handleDetailOpen] = useModal();
    const [applyNum, setApplyNum] = useState(null);

    const { promise: approve } = useAsync({
        promise: patchHandOverApprove,
        resolve: () => {
            getRentalList();
        },
    });

    const handleApproveClick = useCallback(
        (e, applyNum) => {
            e.stopPropagation();
            approve({
                applyNum,
            });
        },
        [approve],
    );

    const handleRejectClick = useCallback(
        (e, applyNum) => {
            e.stopPropagation();
            setApplyNum(applyNum);
            handleDenyOpen();
        },
        [handleDenyOpen],
    );

    const handleHandOverClick = useCallback(
        (e, rowInfo) => {
            e.stopPropagation();
            setRentalInfo({ type: HAND_OVER, ...rowInfo });
            handleHandOverOpen();
        },
        [handleHandOverOpen],
    );

    const handleReturnClick = useCallback(
        (e, rowInfo) => {
            e.stopPropagation();
            setRentalInfo({ type: RETURN, ...rowInfo });
            handleHandOverOpen();
        },
        [handleHandOverOpen],
    );

    const columns = useMemo(
        () => [
            column.index({ width: 40 }),
            {
                Header: t('Category'),
                accessor: 'categoryName',
            },
            {
                Header: t('Asset Name'),
                Cell: ({ row }) => <span>{row.original.targetName ?? '-'}</span>,
            },
            {
                Header: t('Asset Code'),
                accessor: 'assetCode',
                Cell: ({ value }) => <span>{value || '-'}</span>,
            },
            {
                Header: t('Owner Ward'),
                accessor: 'ownDepName',
            },
            {
                Header: t('Rent Ward'),
                accessor: 'applyDepName',
            },
            {
                Header: t('Applicant'),
                accessor: 'applyUserName',
                width: 80,
            },
            {
                Header: t('Request Date'),
                Cell: ({ row }) => <span>{moment.unix(row.original.regDate).format('YYYY-MM-DD HH:mm:ss')}</span>,
            },
            {
                Header: t('Request Rental Period'),
                className: 'p-0',
                Cell: ({ row }) => (
                    <div
                        className={cx(
                            'd-flex align-items-center justify-content-center h-100',
                            row.original.requestEndDate &&
                                isOverdue(row.original.requestEndDate, moment().unix()) &&
                                'bg-warning',
                        )}
                    >
                        {row.original.requestStartDate
                            ? moment.unix(row.original.requestStartDate).format('YYYY-MM-DD')
                            : '-'}{' '}
                        ~{' '}
                        {row.original.requestEndDate
                            ? moment.unix(row.original.requestEndDate).format('YYYY-MM-DD')
                            : '-'}
                    </div>
                ),
            },
            {
                Header: t('Status'),
                accessor: 'status',
                Cell: ({ value }) => <span>{t(checkRentalStatusResponse(value))}</span>,
                width: 70,
            },
            {
                Header: t('Process'),
                className: 'd-flex align-items-center justify-content-center',
                Cell: ({ row }) => {
                    const { status } = row.original;
                    if (status === RENTAL_STATUS_RESPONSE.CHECK_OUT) {
                        return <CellButton onClick={e => handleReturnClick(e, row.original)} name={t('Return')} />;
                    }
                    if (status === RENTAL_STATUS_RESPONSE.APPLY) {
                        return (
                            <div className="d-flex gap-1">
                                <CellButton
                                    onClick={e => handleApproveClick(e, row.original.applyNum)}
                                    name={t('Approve')}
                                />
                                <CellButton
                                    onClick={e => handleRejectClick(e, row.original.applyNum)}
                                    name={t('Reject')}
                                />
                            </div>
                        );
                    }
                    if (status === RENTAL_STATUS_RESPONSE.APPROVE) {
                        return <CellButton onClick={e => handleHandOverClick(e, row.original)} name={t('Hand over')} />;
                    }
                    return <></>;
                },
            },
        ],
        [handleApproveClick, handleHandOverClick, handleRejectClick, handleReturnClick, t],
    );

    return (
        <>
            <span className="pnt-txt txt-bold s-5 mb-2">{t('Lending Assets')}</span>
            <Search
                handleReset={onResetClick}
                handleSearchClick={onKeywordChange}
                placeholder={t('Enter the search keyword')}
            />
            <div>
                <TableWrapper>
                    <Table
                        columns={columns}
                        data={{ ...data, pageSize: 15 }}
                        noDataMessage={t('There are no assets to manage the rental.')}
                        onTrClick={tr => {
                            setApplyNum(tr.applyNum);
                            handleDetailOpen();
                        }}
                        onPageChange={onPageChange}
                    />
                </TableWrapper>
            </div>
            {isDetailOpen && (
                <AssetDetailModal initModal={isDetailOpen} toggle={handleDetailToggle} applyNum={applyNum} />
            )}
            {isHandOverOpen && (
                <RentalActionModal
                    initModal={isHandOverOpen}
                    toggle={handleHandOverToggle}
                    rentalInfo={rentalInfo}
                    getRentalList={getRentalList}
                    settingIdleAsset={settingIdleAsset}
                />
            )}
            {isDenyOpen && (
                <RentalDenyModal
                    initModal={isDenyOpen}
                    toggle={handleDenyToggle}
                    applyNum={applyNum}
                    getRentalList={getRentalList}
                />
            )}
        </>
    );
};

const TableWrapper = styled.div`
    height: 38rem;
`;

export default Lend;
