import React, { useRef } from 'react';
import { Button, ConfirmModal } from '@components';
import { useAsync, useConfirm, useTranslation } from '@hooks';
import { fetchExcelLogApi } from '@api/common/log';
import { excelExport } from '../../../util/excelExport';
import { NullableNumber } from '@util/type/util';
import { getEmergencyAlertOpenLogExcelApi } from '@api/sh/biometricInformationMonitoring';

const INTERVAL_TIME = 1000;
const ExcelExport = ({ param }: { param: any }) => {
    const t = useTranslation('BiometricInformationMonitoring');
    const excelTimerRef = useRef<{ [key: string | number]: NullableNumber }>({});

    const { promise: createNewLogFile } = useAsync({
        promise: getEmergencyAlertOpenLogExcelApi,
        fixedParam: {
            ...param,
            isAll: 'Y',
            excelDownloadRequest: {
                zipFileName: t('Patient_Anomaly_Alert_History'),
                fileName: t('Patient_Anomaly_Alert_History'),
                columnMetas: [
                    {
                        key: 'dateStr',
                        name: t('Date'),
                    },
                    {
                        key: 'wardName',
                        name: t('Ward'),
                    },
                    {
                        key: 'room',
                        name: t('Room'),
                    },
                    {
                        key: 'bed',
                        name: t('Bed'),
                    },
                    {
                        key: 'targetName',
                        name: t('Patient Name'),
                    },
                    {
                        key: 'heartbeat',
                        name: t('Heartbeat', 'SensingType'),
                    },
                    {
                        key: 'respirationRate',
                        name: t('Respiration Rate', 'SensingType'),
                    },
                    {
                        key: 'temperature',
                        name: t('Body Temperature', 'SensingType'),
                    },
                    {
                        key: 'timeStr',
                        name: t('Alert Popup Open Time'),
                    },
                    {
                        key: 'location',
                        name: t('Location In Alerts'),
                    },
                ],
            },
        },
        resolve: res => {
            const { filePath, reqNum } = res;
            if (filePath) {
                tryExcelDown({ reqNum });
            }
        },
        reject: err => {
            excelConfirmModalProps.toggleModal();
            console.error(err);
        },
    });

    const { promise: checkExcelComplete } = useAsync({
        promise: fetchExcelLogApi,
        resolve: res => {
            const { dataSettingStatusType, reqNum, filePath, fileName } = res;
            switch (dataSettingStatusType) {
                case 'COMPLETED':
                    excelExport(filePath, fileName);
                    clearExcelTimer({ reqNum });
                    break;
                case 'FAILED':
                    excelDownloadResultModalProps.toggleModal();
                    clearExcelTimer({ reqNum });
                    break;
            }
        },
        reject: err => console.error(err),
    });

    const tryExcelDown = ({ reqNum }: { reqNum: number }) => {
        excelTimerRef.current[reqNum] = window.setInterval(() => {
            checkExcelComplete({ reqNum });
        }, INTERVAL_TIME);
    };

    const clearExcelTimer = ({ reqNum }: { reqNum: number }) => {
        clearInterval(excelTimerRef.current[reqNum]!);
        excelTimerRef.current[reqNum] = null;
        delete excelTimerRef.current[reqNum];
    };

    const handleExportFileClick = () => {
        createNewLogFile();
    };

    const excelDownloadResultModalProps = useConfirm({
        initModal: false,
        header: { title: t('Result', 'Report') },
        confirmText: t('Failed to download the excel.', 'Report'),
    });

    const excelConfirmModalProps = useConfirm({
        initModal: false,
        removeCancel: true,
        header: { title: t('Excel Download', 'Report') },
        confirmText: t('No data', 'Report'),
    });

    return (
        <>
            <Button className="btn-lightgray btn-icon" onClick={handleExportFileClick}>
                <span className={'material-icons-round'}>file_upload</span>
                {t('Export', 'Button')}
            </Button>
            <ConfirmModal {...excelDownloadResultModalProps} />
            <ConfirmModal {...excelConfirmModalProps} />
        </>
    );
};

export default ExcelExport;
