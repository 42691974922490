import { Button, Table } from '@components';
import { useAsync, useTranslation } from '@util/hooks';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';
import CellButton from '../CellButton';
import moment from 'moment';
import * as column from '@util/grid/column';
import RentalRequest from './RentalRequest';
import Search from '../../Components/Search';
import cx from 'classnames';
import useModal from '../../../util/useModal';
import AssetDetailModal from '../Modals/AssetDetailModal';
import { deleteRentalCancel } from '@api/am/management';
import { checkRentalStatusResponse, isOverdue, RENTAL_STATUS } from '../utils/check';
import TakeOverCancelModal from '../Modals/TakeOverCancelModal';

const Borrow = ({
    data,
    getRentalList,
    isRequestShow,
    onRequestShowChange,
    onKeywordChange,
    onResetClick,
    onPageChange,
    settingIdleAsset,
}) => {
    const t = useTranslation('AssetManagement');
    const [isDetailOpen, handleDetailToggle, handleDetailOpen] = useModal();
    const [isWaitCancelOpen, handleWaitCancelToggle, handleWaitCancelOpen] = useModal();
    const [applyNum, setApplyNum] = useState(null);

    const { promise: deleteRequest } = useAsync({
        promise: deleteRentalCancel,
        resolve: () => {
            getRentalList();
        },
        reject: err => console.error(err),
    });

    const handleRequestCancelClick = useCallback(
        (e, applyNum) => {
            e.stopPropagation();
            deleteRequest({ applyNum, rentalStatus: RENTAL_STATUS.APPLY });
        },
        [deleteRequest],
    );

    const handleHandOverCancelClick = useCallback(
        (e, applyNum) => {
            e.stopPropagation();
            setApplyNum(applyNum);
            handleWaitCancelOpen();
        },
        [handleWaitCancelOpen],
    );

    const columns = useMemo(
        () => [
            column.index({ width: 40 }),
            {
                Header: t('Category'),
                accessor: 'categoryName',
            },
            {
                Header: t('Asset Name'),
                Cell: ({ row }) => <span>{row.original.targetName ?? '-'}</span>,
            },
            {
                Header: t('Asset Code'),
                accessor: 'assetCode',
                Cell: ({ value }) => <span>{value || '-'}</span>,
            },
            {
                Header: t('Owner Ward'),
                accessor: 'ownDepName',
            },
            {
                Header: t('Rent Ward'),
                accessor: 'applyDepName',
            },
            {
                Header: t('Applicant'),
                accessor: 'applyUserName',
                width: 80,
            },
            {
                Header: t('Request Date'),
                Cell: ({ row }) => <span>{moment.unix(row.original.regDate).format('YYYY-MM-DD HH:mm:ss')}</span>,
            },
            {
                Header: t('Request Rental Period'),
                className: 'p-0',
                Cell: ({ row }) => (
                    <div
                        className={cx(
                            'd-flex align-items-center justify-content-center h-100',
                            row.original.requestEndDate &&
                                isOverdue(row.original.requestEndDate, moment().unix()) &&
                                'bg-warning',
                        )}
                    >
                        {row.original.requestStartDate
                            ? moment.unix(row.original.requestStartDate).format('YYYY-MM-DD')
                            : '-'}{' '}
                        ~{' '}
                        {row.original.requestEndDate
                            ? moment.unix(row.original.requestEndDate).format('YYYY-MM-DD')
                            : '-'}
                    </div>
                ),
            },
            {
                Header: t('Status'),
                accessor: 'status',
                Cell: ({ value }) => <span>{t(checkRentalStatusResponse(value))}</span>,
                width: 70,
            },
            {
                Header: t('Process'),
                className: 'd-flex align-items-center justify-content-center',
                Cell: ({ row }) => {
                    const { status } = row.original;
                    if (status === 'apply') {
                        return (
                            <CellButton
                                onClick={e => handleRequestCancelClick(e, row.original.applyNum)}
                                name={t('Cancel')}
                            />
                        );
                    }
                    if (status === 'approve') {
                        return (
                            <CellButton
                                onClick={e => handleHandOverCancelClick(e, row.original.applyNum)}
                                name={t('Cancel')}
                            />
                        );
                    }
                    return <></>;
                },
            },
        ],
        [handleHandOverCancelClick, handleRequestCancelClick, t],
    );

    return (
        <>
            <div className="d-flex justify-content-between mb-2">
                <span className="pnt-txt txt-bold s-5">
                    {isRequestShow ? t('Rental Request') : t('Borrowing Assets')}
                </span>
                {!settingIdleAsset && (
                    <Button className="btn-secondary" onClick={onRequestShowChange}>
                        {isRequestShow ? t('Back') : t('Rental Request')}
                    </Button>
                )}
            </div>
            {isRequestShow ? (
                <RentalRequest
                    onSuccess={() => {
                        getRentalList();
                        onRequestShowChange();
                    }}
                />
            ) : (
                <>
                    <Search
                        handleReset={onResetClick}
                        handleSearchClick={onKeywordChange}
                        placeholder={t('Enter the search keyword')}
                    />
                    <div>
                        <TableWrapper>
                            <Table
                                columns={columns}
                                data={{ ...data, pageSize: 15 }}
                                noDataMessage={t('There are no assets to manage the rental.')}
                                onTrClick={tr => {
                                    setApplyNum(tr.applyNum);
                                    handleDetailOpen();
                                }}
                                onPageChange={onPageChange}
                            />
                        </TableWrapper>
                    </div>
                </>
            )}
            {isDetailOpen && (
                <AssetDetailModal initModal={isDetailOpen} toggle={handleDetailToggle} applyNum={applyNum} />
            )}
            {isWaitCancelOpen && (
                <TakeOverCancelModal
                    initModal={isWaitCancelOpen}
                    toggle={handleWaitCancelToggle}
                    applyNum={applyNum}
                    getRentalList={getRentalList}
                />
            )}
        </>
    );
};

const TableWrapper = styled.div`
    height: 38rem;
`;

export default Borrow;
