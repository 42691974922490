import TemporaryWidget, { WIDGET_TYPE_TEMP } from './Components/TemporaryWidget';
import Widget, { WIDGET_CATEGORY } from './staticInfo';
import soWidgetList from './so';
import amWidgetList from './am';
import shWidgetList from './sh';
import commonWidgetList from './common';

export const temp: Widget = {
    type: WIDGET_TYPE_TEMP,
    category: WIDGET_CATEGORY.TEMP,
    name: 'Widget Name',
    desc: '',
    thumbnailClassName: null,
    Component: TemporaryWidget,
    SettingComponent: null,
    config: { defaultSettings: { name: 'New widget' } },
    layout: {
        w: 4,
        h: 15,
        minW: 2,
        minH: 9,
    },
};

const widgetList = {
    ...shWidgetList,
    ...soWidgetList,
    ...amWidgetList,
    ...commonWidgetList,
    /* etc */
    temp,
};

export default widgetList;
