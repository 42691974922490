import { ObjectUnionType } from '@util/type/util';

export const SENSOR_TYPE = {
    TEMPERATURE: 'TEMPERATURE',
    TEMPERATURE2: 'TEMPERATURE2',
    HUMIDITY: 'HUMIDITY',
    R_HUMIDITY: 'R_HUMIDITY',
    ANBIENT_LIGHT: 'ANBIENT_LIGHT',
    BAROM_PRESURE: 'BAROM_PRESURE',
    SEISMIC_INTENS: 'SEISMIC_INTENS',
    SOUND_NOISE: 'SOUND_NOISE',
    DISCOMFORT_IDX: 'DISCOMFORT_IDX',
    O2: 'O2',
    LEL: 'LEL',
    H2S: 'H2S',
    CO: 'CO',
    CO2: 'CO2',
    SO2: 'SO2',
    ETVOC: 'ETVOC',
    ECO2: 'ECO2',
    ETCO2: 'ETCO2',
    INCO2: 'INCO2',
    HEARTBEAT: 'HEARTBEAT',
    H_BLOODPRESSURE: 'H_BLOODPRESSURE',
    L_BLOODPRESSURE: 'L_BLOODPRESSURE',
    BLOODPRESSURE: 'BLOODPRESSURE',
    PULSE_RATE: 'PULSE_RATE',
    HEAT_STROKE: 'HEAT_STROKE',
    ACCELER_X_AXIS: 'ACCELER_X_AXIS',
    ACCELER_Y_AXIS: 'ACCELER_Y_AXIS',
    ACCELER_Z_AXIS: 'ACCELER_Z_AXIS',
    ANGULAR_X_AXIS: 'ANGULAR_X_AXIS',
    ANGULAR_Y_AXIS: 'ANGULAR_Y_AXIS',
    ANGULAR_Z_AXIS: 'ANGULAR_Z_AXIS',
    MOVEMENT: 'MOVEMENT',
    BATTERY: 'BATTERY',
    NAPPY_BIG: 'NAPPY_BIG',
    NAPPY_GAS: 'NAPPY_GAS',
    NAPPY_SMALL: 'NAPPY_SMALL',
    CALORIES: 'CALORIES',
    STEP: 'STEP',
    DISTANCE: 'DISTANCE',
    SLEEP: 'SLEEP',
    HEIGHT: 'HEIGHT',
    WEIGHT: 'WEIGHT',
    FIRE: 'FIRE',
    STRESS: 'STRESS',
    CAPACITY: 'CAPACITY',
    CRACK: 'CRACK',
    DUST: 'DUST',
    DOSAGE: 'DOSAGE',
    TOTAL_DOSAGE: 'TOTAL_DOSAGE',
    DROP_COUNT: 'DROP_COUNT',
    DROP_RATE: 'DROP_RATE',
    PACKET_COUNTER: 'PACKET_COUNTER',
    SPO2: 'SPO2',
    RESPIRATION_RATE: 'RESPIRATION_RATE',
    RESPIRATION_RATE2: 'RESPIRATION_RATE2',
    IPI: 'IPI',
    IH_BLOODPRESSURE: 'IH_BLOODPRESSURE',
    IL_BLOODPRESSURE: 'IL_BLOODPRESSURE',
    I_BLOODPRESSURE: 'I_BLOODPRESSURE',
    IH_BLOODPRESSURE2: 'IH_BLOODPRESSURE2',
    IL_BLOODPRESSURE2: 'IL_BLOODPRESSURE2',
    I_BLOODPRESSURE2: 'I_BLOODPRESSURE2',
    AF_BURDEN: 'AF_BURDEN',
    TOTAL_WEAR_TIME_SEC: 'TOTAL_WEAR_TIME_SEC',
    ANALYZABLE_WEAR_TIME_SEC: 'ANALYZABLE_WEAR_TIME_SEC',
    AF_EPISODE_CNT: 'AF_EPISODE_CNT',
    MEAN_AF_DUR_SEC: 'MEAN_AF_DUR_SEC',
    MIN_AF_DUR_SEC: 'MIN_AF_DUR_SEC',
    MEDIAN_AF_DUR_SEC: 'MEDIAN_AF_DUR_SEC',
    MAX_AF_DUR_SEC: 'MAX_AF_DUR_SEC',
    TOTAL_AF_DUR_SEC: 'TOTAL_AF_DUR_SEC',
    BUTTON1_COUNT: 'BUTTON1_COUNT',
    BUTTON2_COUNT: 'BUTTON2_COUNT',
    ECG: 'ECG',
    RATE: 'RATE',
    VTBI: 'VTBI',
    INFUSED_VOL: 'INFUSED_VOL',
    BI: 'BI',
    CONC: 'CONC',
    DOSE: 'DOSE',
    PCA_BOLUS: 'PCA_BOLUS',
    PI: 'PI',
    BLOOD_GLUCOSE: 'BLOOD_GLUCOSE',
    STRIDE: 'STRIDE',
    SPEED: 'SPEED',
} as const;

export type SensorType = ObjectUnionType<typeof SENSOR_TYPE>;

export const SENSOR_TYPE_TO_NICKNAME: { [key in SensorType]?: string } = {
    HEARTBEAT: 'HR',
    RESPIRATION_RATE: 'RR',
    RESPIRATION_RATE2: 'RR 2',
    TEMPERATURE: 'BT',
} as const;

export const SENSOR_TYPE_TO_NAME: { [key in SensorType]?: string } = {
    RESPIRATION_RATE: 'Respiration Rate',
    RESPIRATION_RATE2: 'Respiration Rate 2',
    SPO2: 'SpO2',
    TEMPERATURE: 'Temperature',
    HEARTBEAT: 'Heartbeat',
    BATTERY: 'Battery',
} as const;

export const MODEL_CODE = { MEZOO_SMARTPATCH: '3601' } as const;

type ModelCode = ObjectUnionType<typeof MODEL_CODE>;

export const MODEL_TO_SENSOR_TYPE: { [key in ModelCode]?: readonly Partial<SensorType>[] } = {
    [MODEL_CODE.MEZOO_SMARTPATCH]: [SENSOR_TYPE.HEARTBEAT, SENSOR_TYPE.RESPIRATION_RATE, SENSOR_TYPE.TEMPERATURE],
} as const;
