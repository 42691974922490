import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const initialState = {
    selectedCategory: null,
    selectedFloor: '',
    startDate: moment().startOf('day').valueOf(),
    endDate: moment().endOf('day').valueOf(),
};

const topFiveCongestedGeofenceReducer = createSlice({
    name: 'topFiveCongestedGeofence',
    initialState,
    reducers: {
        setSelectedCategory: (state, action) => {
            state.selectedCategory = action.payload;
        },
        setSelectedFloor: (state, action) => {
            state.selectedFloor = action.payload;
        },
        setStartDate: (state, action) => {
            state.startDate = action.payload;
        },
        setEndDate: (state, action) => {
            state.endDate = action.payload;
        },
    },
});
export const {
    setSelectedCategory,
    setSelectedFloor,
    setStartDate,
    setEndDate,
} = topFiveCongestedGeofenceReducer.actions;
export default topFiveCongestedGeofenceReducer;
