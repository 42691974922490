import React, { useEffect, useMemo } from 'react';
import cx from 'classnames';
import { FilterList } from '../../../../../MainPages/Components/FilterSearchGroup/Components/Part';
import FilterSearchGroup from '../../../../../MainPages/Components/FilterSearchGroup';
import FilterGroup from '../../../../../MainPages/Components/FilterSearchGroup/Components/FilterGroup';
import { useAppSelector, useAsync, useTranslation } from '@hooks';
import SearchableSelect from '@components/Select/SearchableSelect';
import {
    setMonitoringFilter,
    useBiometricInformationMonitoringDispatchContext,
    useBiometricInformationMonitoringStateContext,
} from '../../slice';
import { getRoomListApi } from '@api/sh/biometricInformationMonitoring';
import Select from '@components/Select';
import { SingleValue } from 'react-select';
import { YN } from '@util/type/util';
import BookmarkToggle from '../../../../../MainPages/Components/FilterWidget/Option/BookmarkToggle';

const Search = () => {
    const t = useTranslation('BiometricInformationMonitoring');
    const dispatch = useBiometricInformationMonitoringDispatchContext();
    const { monitoringFilter } = useBiometricInformationMonitoringStateContext();

    const userInfo: { groupNums?: number[] } = useAppSelector(state => state.UserInfo.userInfo);
    const wardList = useAppSelector(state =>
        Object.entries(state.DepartmentsInfo.wardMapInfo).map(([wardNum, wardName]) => ({ wardNum, wardName })),
    );

    const {
        state: { response: roomListInfo },
    } = useAsync({
        promise: getRoomListApi,
        fixedParam: { wardList: monitoringFilter.wardList ?? [] },
        deps: [monitoringFilter.wardList],
        immediate: true,
    });

    const roomList = useMemo(() => {
        return (roomListInfo?.rows ?? []).reduce((acc: { roomNum: string; roomName: string }[], roomInfo) => {
            if (
                !monitoringFilter.wardList ||
                !monitoringFilter.wardList.length ||
                monitoringFilter.wardList?.includes(roomInfo.ward)
            ) {
                acc.push({
                    roomNum: roomInfo.room,
                    roomName: roomInfo.room,
                });
            }
            return acc;
        }, []);
    }, [roomListInfo, monitoringFilter.wardList]);

    useEffect(() => {
        if (userInfo.groupNums && userInfo.groupNums.length) {
            dispatch(setMonitoringFilter({ wardList: userInfo.groupNums.map(groupNum => groupNum.toString()) }));
        }
    }, [userInfo]);

    const isMappingOptions = useMemo<{ value: YN; label: string }[]>(() => {
        return [
            { value: 'Y', label: t('View device mapped patients') },
            { value: 'N', label: t('View all patients') },
        ];
    }, [t]);

    return (
        <FilterSearchGroup className={cx('absolute-filter', 'w-100')}>
            <FilterGroup className={''} label={t('Filter', 'Filter')}>
                <FilterList className={''}>
                    <SearchableSelect
                        data={wardList}
                        labelKey={'wardName'}
                        valueKey={'wardNum'}
                        selected={monitoringFilter.wardList}
                        onChange={selected => {
                            dispatch(
                                setMonitoringFilter({
                                    wardList: (selected ?? []).map((ward: { wardNum: string }) => ward.wardNum),
                                }),
                            );
                        }}
                    />
                    <SearchableSelect
                        data={roomList}
                        labelKey={'roomName'}
                        valueKey={'roomNum'}
                        selected={monitoringFilter.roomList}
                        onChange={selected => {
                            dispatch(
                                setMonitoringFilter({
                                    roomList: (selected ?? []).map((room: { roomNum: string }) => room.roomNum),
                                }),
                            );
                        }}
                    />
                    <Select
                        customContainerStyles={{ width: '200px' }}
                        options={isMappingOptions}
                        value={isMappingOptions.find(option => option.value === monitoringFilter.isMapping)}
                        onChange={(selected: SingleValue<any>) => {
                            dispatch(setMonitoringFilter({ isMapping: selected?.value ?? 'N' }));
                        }}
                    />
                    <BookmarkToggle
                        selected={[monitoringFilter.isBookmark]}
                        handleChange={(checked: YN[]) => {
                            dispatch(setMonitoringFilter({ isBookmark: checked[0] }));
                        }}
                    />
                </FilterList>
            </FilterGroup>
        </FilterSearchGroup>
    );
};

export default Search;
