import { ChartOptions } from 'chart.js';

const GRAPH_AREA = {
    WIDTH: 1250,
    HEIGHT: 300,
    MARGIN_TOP: 5,
    MARGIN_RIGHT: 20,
    MARGIN_BOTTOM: 40,
    MARGIN_LEFT: 0,
};

const LABEL_AREA = {
    TOP: 32,
};

export const TICK_LIMITS = 16;

export const LINE_CHART_COLOR = '#82ca9d';
export const WHITE_COLOR = '#fff';
const GRID_GRAY_COLOR = '#ccc';
const TEXT_BOLD = 'bold';

export const DEFAULT_CHART_OPTIONS: ChartOptions<'line'> = {
    animation: false,
    // parser: false,
    spanGaps: true,
    layout: {
        padding: {
            top: GRAPH_AREA.MARGIN_TOP,
            left: GRAPH_AREA.MARGIN_LEFT,
            right: GRAPH_AREA.MARGIN_RIGHT,
        },
    },
    plugins: {
        legend: {
            position: 'top',
            align: 'end',
        },
        tooltip: {
            mode: 'index',
            yAlign: 'bottom',
            intersect: false,
            animation: { duration: 0 },
            displayColors: false,
            padding: 12,
            titleFont: {
                weight: TEXT_BOLD,
            },
            bodyFont: {
                weight: TEXT_BOLD,
            },
        },
    },
    scales: {
        x: {
            type: 'time',
            time: {
                unit: 'second',
                tooltipFormat: 'HH:mm:ss',
                displayFormats: { second: 'HH:mm:ss' },
            },
            display: true,
            grid: {
                borderDash: [4, 4],
                display: true,
                tickLength: LABEL_AREA.TOP,
                offset: false,
                color: GRID_GRAY_COLOR,
            },
            ticks: {
                minRotation: 45,
                source: 'labels',
            },
        },
        y: {
            grid: {
                borderWidth: 1,
                borderDash: [4, 4],
                color: GRID_GRAY_COLOR,
            },
            ticks: {
                maxTicksLimit: 5,
            },
        },
    },
    datasets: {
        line: {
            pointRadius: 0,
            borderWidth: 1,
            // offset: true,
        },
    },
};
