import React from 'react';
import moment from 'moment';

// Location-Log Table
export const number = ({ ...restProps } = {}) => {
    return {
        Header: 'No.',
        headerClassName: 'text-ellipsis',
        accessor: 'logNum',
        className: 'd-flex justify-content-center text-ellipsis',
        width: 70,
        ...restProps,
    };
};

export const targetName = ({ ...restProps } = {}) => {
    return {
        Header: 'Target Name',
        headerClassName: 'text-ellipsis',
        accessor: 'targetName',
        className: 'flex-center text-ellipsis',
        ...restProps,
    };
};

export const deviceName = ({ ...restProps } = {}) => {
    return {
        Header: 'Device Name',
        accessor: 'tagName',
        className: 'flex-center text-ellipsis',
        ...restProps,
    };
};

export const floor = ({ ...restProps } = {}) => {
    return {
        Header: 'Floor',
        headerClassName: 'text-ellipsis',
        accessor: 'floorId',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const location = ({ handleLatLngClick, t, ...restProps } = {}) => {
    return {
        Header: 'Location',
        accessor: 'latLng',
        className: 'flex-center',
        width: 250,
        Cell: ({ value, row: { original } }) => {
            const [lat, lng] = value;
            const letter = ['Latitude', 'Longitude'];
            return [lat, lng].map((item, idx) => {
                return (
                    <div
                        className="pnt-txt txt-border txt-bold custom-action-btn"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            if (typeof handleLatLngClick === 'function') {
                                handleLatLngClick(original);
                            }
                        }}
                    >
                        {t(letter[idx])} {parseFloat(item).toFixed(6)}
                    </div>
                );
            });
        },
        t,
        ...restProps,
    };
};

export const registeredDate = ({ ...restProps } = {}) => {
    return {
        Header: 'Registered Date',
        headerClassName: 'text-ellipsis',
        accessor: 'regDate',
        className: 'd-flex justify-content-center text-ellipsis',
        Cell: ({ value }) => {
            const date = moment.unix(value).format('YYYY-MM-DD HH:mm:ss');
            return <span title={date}>{date}</span>;
        },
        ...restProps,
    };
};

//Location-Log-File Table
export const downloadLog = ({ t, ...restProps }) => {
    return {
        Header: 'Download',
        className: 'flex-center',
        accessor: 'filePath',
        Cell: ({ value, row }) => {
            const { original } = row;
            if (original.dataSettingStatusType !== 'COMPLETED') {
                return <div className={'pnt-txt txt-bold'}>-</div>;
            } else {
                return (
                    <a href={value} download={original.fileName}>
                        <div className={'pnt-txt txt-border txt-bold custom-action-btn'} style={{ cursor: 'pointer' }}>
                            {t('Download')}
                        </div>
                    </a>
                );
            }
        },
        t,
        ...restProps,
    };
};

export const reqNum = ({ ...restProps } = {}) => {
    return {
        Header: 'No.',
        className: 'flex-center',
        accessor: 'reqNum',
        width: 50,
        ...restProps,
    };
};

export const fileName = ({ ...restProps } = {}) => {
    return {
        Header: 'File Name',
        className: 'flex-center text-ellipsis',
        accessor: 'fileName',
        ...restProps,
    };
};

export const dataStatus = ({ t, ...restProps } = {}) => {
    return {
        Header: 'Data Status',
        accessor: 'dataSettingStatusType',
        width: 50,
        Cell: ({ value, row }) => {
            if (value === 'COMPLETED')
                return (
                    <div className={'pnt-txt txt-border txt-bold'} style={{ color: '#000' }}>
                        {t('COMPLETED')}
                    </div>
                );
            else if (value === 'FAILED')
                return (
                    <div className={'pnt-txt txt-border txt-bold'} style={{ color: '#ff3333' }}>
                        {t('FAILED')}
                    </div>
                );
            else if (value === 'APPENDING')
                return (
                    <div className={'pnt-txt txt-border txt-bold'} style={{ color: '#FFC107' }}>
                        {t('APPENDING')}
                    </div>
                );
            else if (value === 'PROGRESSING') {
                return (
                    <div className={'pnt-txt txt-border txt-bold'} style={{ color: '#3E66FB' }}>
                        {t('PROGRESSING')}
                    </div>
                );
            }
        },
        t,
        ...restProps,
    };
};
// geofence log table
export const categoryName = ({ ...restProps } = {}) => {
    return {
        Header: 'Category Name',
        className: 'flex-center text-ellipsis',
        accessor: 'categoryCode',
    };
};
export const targetId = ({ ...restProps } = {}) => {
    return {
        Header: 'Target ID',
        className: 'flex-center text-ellipsis',
        accessor: 'targetId',
        ...restProps,
    };
};
export const geofence = ({ ...restProps } = {}) => {
    return {
        Header: 'Geofence',
        className: 'flex-center text-ellipsis',
        accessor: 'fcName',
    };
};
export const authorizedNotAuthorized = ({ ...restProps } = {}) => {
    return {
        Header: 'Authorized Not Authorized',
        className: 'flex-center text-ellipsis',
        accessor: 'permitted',
    };
};
export const inDate = ({ ...restProps } = {}) => {
    return {
        Header: 'In Date',
        className: 'flex-center text-ellipsis',
        accessor: 'inDate',
        Cell: ({ value }) => {
            return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
    };
};
export const inScannerName = ({ ...restProps } = {}) => {
    return {
        Header: 'In Scanner Name',
        className: 'flex-center text-ellipsis',
        accessor: 'inScannerName',
    };
};
// 스캐너 상태 로그
export const scannerName = ({ ...restProps } = {}) => {
    return {
        Header: 'Scanner Name',
        headerClassName: 'flex-center',
        accessor: 'scannerName',
        className: 'flex-center',
        ...restProps,
    };
};
export const macAddress = ({ ...restProps } = {}) => {
    return {
        Header: 'Mac Address',
        headerClassName: 'flex-center',
        accessor: 'macAddr',
        className: 'flex-center',
        ...restProps,
    };
};

export const startDate = ({ ...restProps } = {}) => {
    return {
        Header: 'Start Date',
        headerClassName: 'flex-center',
        accessor: 'startDate',
        className: 'flex-center',
        Cell: ({ value }) => {
            return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
        ...restProps,
    };
};
export const endDate = ({ ...restProps } = {}) => {
    return {
        Header: 'End Date',
        headerClassName: 'flex-center',
        accessor: 'endDate',
        className: 'flex-center',
        Cell: ({ value }) => {
            return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
        ...restProps,
    };
};

//sensor log
export const sensorName = ({ ...restProps } = {}) => {
    return {
        Header: 'Sensor Name',
        accessor: 'sensorName',
        className: 'flex-center text-ellipsis',
        ...restProps,
    };
};

export const sensorId = ({ ...restProps } = {}) => {
    return {
        Header: 'Sensor Id',
        accessor: 'sensorId',
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const sensorType = ({ ...restProps } = {}) => {
    return {
        Header: 'Sensor Type',
        accessor: 'sensorType',
        className: 'flex-center text-ellipsis',
        ...restProps,
    };
};

export const injectionVolume = ({ ...restProps } = {}) => {
    return {
        Header: 'Injection Volume',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'VTBI',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const totalDose = ({ ...restProps } = {}) => {
    return {
        Header: 'Total Dose',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'TOTAL_DOSAGE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const stepCount = ({ ...restProps } = {}) => {
    return {
        Header: 'Step Count',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'STEP',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const bloodOxygenSaturation = ({ ...restProps } = {}) => {
    return {
        Header: 'Blood Oxygen Saturation',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'SPO2',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const noise = ({ ...restProps } = {}) => {
    return {
        Header: 'Noise',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'SOUND_NOISE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const so2 = ({ ...restProps } = {}) => {
    return {
        Header: 'SO2',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'SO2',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const sleepIndex = ({ ...restProps } = {}) => {
    return {
        Header: 'Sleep Index',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'SLEEP',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const earthquakeIntensity = ({ ...restProps } = {}) => {
    return {
        Header: 'Earthquake Intensity',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'SEISMIC_INTENS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const relativeHumidity = ({ ...restProps } = {}) => {
    return {
        Header: 'Relative Humidity',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'R_HUMIDITY',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const respiratoryRate = ({ ...restProps } = {}) => {
    return {
        Header: 'Respiratory Rate',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'RESPIRATION_RATE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};

export const infusionRate = ({ ...restProps } = {}) => {
    return {
        Header: 'Infusion Rates',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'RATE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const pulseRate = ({ ...restProps } = {}) => {
    return {
        Header: 'Pulse Rate',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'PULSE_RATE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const oneTimePcaInjection = ({ ...restProps } = {}) => {
    return {
        Header: 'One Time PCA Injection',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'PCA_BOLUS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const numberOfPacketTransmissions = ({ ...restProps } = {}) => {
    return {
        Header: 'Number Of Packet Transmissions',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'PACKET_COUNTER',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const o2 = ({ ...restProps } = {}) => {
    return {
        Header: 'O2',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'O2',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const diaperUrine = ({ ...restProps } = {}) => {
    return {
        Header: 'Diaper-Urine',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'NAPPY_GAS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const diaperFart = ({ ...restProps } = {}) => {
    return {
        Header: 'Diaper-Fart',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'NAPPY_SMALL',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const diaperFeces = ({ ...restProps } = {}) => {
    return {
        Header: 'Diaper-Feces',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'NAPPY_BIG',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const movement = ({ ...restProps } = {}) => {
    return {
        Header: 'Movement',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'MOVEMENT',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const diastolic = ({ ...restProps } = {}) => {
    return {
        Header: 'Diastolic',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'H_BLOODPRESSURE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};

export const geofenceName = ({ ...restProps } = {}) => {
    return {
        Header: 'Geofence Name',
        headerClassName: 'text-ellipsis',
        className: ' text-ellipsis',
        accessor: 'fcName',
        ...restProps,
    };
};

export const countOfEntries = ({ ...restProps } = {}) => {
    return {
        Header: 'Count of entries',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        accessor: 'entryCount',
        ...restProps,
    };
};

export const countOfUnique = ({ ...restProps } = {}) => {
    return {
        Header: 'Count of unique',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        accessor: 'uniqueCount',
        ...restProps,
    };
};

export const averageStayOfEntry = ({ ...restProps } = {}) => {
    return {
        Header: 'Average length of stay per entry',
        headerClassName: 'text-ellipsis',
        className: ' text-ellipsis',
        accessor: 'avgStayEntryTime',
        Cell: ({ value }) => {
            return <span>{`${moment(value * 1000).format('m')}m ${moment(value * 1000).format('ss')}s`}</span>;
        },
        ...restProps,
    };
};

export const averageStayOfUnique = ({ ...restProps }) => {
    return {
        Header: 'Average length of stay per unique',
        headerClassName: 'text-ellipsis',
        className: 'text-ellipsis',
        accessor: 'avgStayUniqueTime',
        Cell: ({ value }) => {
            return <span>{`${moment(value * 1000).format('m')}m ${moment(value * 1000).format('ss')}s`}</span>;
        },
        ...restProps,
    };
};

// iot Item Information Log Table
export const dataType = ({ ...restProps }) => {
    return {
        Header: 'Type of Data Revision',
        headerClassName: 'text-ellipsis',
        accessor: 'manipulationType',
        className: 'd-flex justify-content-center text-ellipsis',
        ...restProps,
    };
};

export const targetNum = ({ ...restProps }) => {
    return {
        Header: 'IoT Item No.',
        headerClassName: 'text-ellipsis',
        accessor: 'targetNum',
        className: 'flex-center text-ellipsis',
        ...restProps,
    };
};

export const iotItemId = ({ ...restProps }) => {
    return {
        Header: 'IoT Item ID',
        headerClassName: 'text-ellipsis',
        accessor: 'targetId',
        className: 'flex-center text-ellipsis',
        ...restProps,
    };
};

export const metaData = ({ ...restProps }) => {
    return {
        Header: 'Meta Data',
        headerClassName: 'text-ellipsis',
        accessor: 'metadata',
        className: '',
        ...restProps,
    };
};

export const deviceType = ({ ...restProps }) => {
    return {
        Header: 'Device Type',
        headerClassName: 'text-ellipsis',
        accessor: 'deviceType',
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const deviceNum = ({ ...restProps }) => {
    return {
        Header: 'Device No.',
        headerClassName: 'text-ellipsis',
        accessor: 'deviceNum',
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const deviceId = ({ ...restProps } = {}) => {
    return {
        Header: 'Device ID',
        headerClassName: 'text-ellipsis',
        accessor: 'deviceId',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const userId = ({ ...restProps } = {}) => {
    return {
        Header: 'User ID',
        headerClassName: 'text-ellipsis',
        accessor: 'userID',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const userName = ({ ...restProps } = {}) => {
    return {
        Header: 'User Name',
        headerClassName: 'text-ellipsis',
        accessor: 'userName',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const type = ({ ...restProps } = {}) => {
    return {
        Header: 'Log Type',
        headerClassName: 'text-ellipsis',
        accessor: 'type',
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const description = ({ ...restProps }) => {
    return {
        Header: 'Reason for Login Failure',
        headerClassName: 'text-ellipsis',
        accessor: 'description',
        className: 'text-ellipsis',
        ...restProps,
    };
};
export const ip = ({ ...restProps } = {}) => {
    return {
        Header: 'IP',
        headerClassName: 'text-ellipsis',
        accessor: 'ip',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const lel = ({ ...restProps } = {}) => {
    return {
        Header: 'LEL',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'LEL',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const injectedAmount = ({ ...restProps } = {}) => {
    return {
        Header: 'Injected Amount',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'INFUSED_VOL',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const systolicBloodPressure = ({ ...restProps } = {}) => {
    return {
        Header: 'Systolic Blood Pressure',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'L_BLOODPRESSURE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const humidity = ({ ...restProps } = {}) => {
    return {
        Header: 'Humidity',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'HUMIDITY',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const height = ({ ...restProps } = {}) => {
    return {
        Header: 'Height',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'HEIGHT',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const heatstroke = ({ ...restProps } = {}) => {
    return {
        Header: 'Heatstroke',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'HEAT_STROKE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const heartRate = ({ ...restProps } = {}) => {
    return {
        Header: 'Heart Rate',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'HEART_RATE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const weight = ({ ...restProps } = {}) => {
    return {
        Header: 'Weight',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'WEIGHT',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const h2s = ({ ...restProps } = {}) => {
    return {
        Header: 'H2S',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'H2S',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};

export const volatileOrganicCompounds = ({ ...restProps } = {}) => {
    return {
        Header: 'Volatile Organic Compounds',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'ETVOC',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const equivalentCarbonDioxide = ({ ...restProps } = {}) => {
    return {
        Header: 'Equivalent Carbon Dioxide',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'ECO2',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const dust = ({ ...restProps } = {}) => {
    return {
        Header: 'Dust',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'DUST',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const dropRaid = ({ ...restProps } = {}) => {
    return {
        Header: 'Drop Raid',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'DROP_RATE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const dropWater = ({ ...restProps } = {}) => {
    return {
        Header: 'Drop Water',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'DROP_COUNT',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const dosage = ({ ...restProps } = {}) => {
    return {
        Header: 'Dosage',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'DOSAGE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const distance = ({ ...restProps } = {}) => {
    return {
        Header: 'Distance',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'DISTANCE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const discomfortIndex = ({ ...restProps } = {}) => {
    return {
        Header: 'Discomfort Index',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'DISCOMFORT_IDX',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const crack = ({ ...restProps } = {}) => {
    return {
        Header: 'Crack',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'CRACK',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const density = ({ ...restProps } = {}) => {
    return {
        Header: 'Density',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'CONC',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const co = ({ ...restProps } = {}) => {
    return {
        Header: 'CO',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'CO',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const capacity = ({ ...restProps } = {}) => {
    return {
        Header: 'Capacity',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'CAPACITY',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const calories = ({ ...restProps } = {}) => {
    return {
        Header: 'Calories',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'CALORIES',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const buttonTwoClicks = ({ ...restProps } = {}) => {
    return {
        Header: 'Button Two Clicks',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'BUTTON2_COUNT',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const buttonOneClicks = ({ ...restProps } = {}) => {
    return {
        Header: 'Button One Clicks',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'BUTTON1_COUNT',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const battery = ({ ...restProps } = {}) => {
    return {
        Header: 'Battery',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'BATTERY',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const atmosphericPressure = ({ ...restProps } = {}) => {
    return {
        Header: 'Atmospheric Pressure',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'BAROM_PRESURE',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const zAxisAngularVelocity = ({ ...restProps } = {}) => {
    return {
        Header: 'Z-Axis Angular Velocity',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'ACCELER_Z_AXIS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const yAxisAngularVelocity = ({ ...restProps } = {}) => {
    return {
        Header: 'Y-Axis Angular Velocity',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'ACCELER_Y_AXIS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const xAxisAngularVelocity = ({ ...restProps } = {}) => {
    return {
        Header: 'X-Axis Angular Velocity',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'ACCELER_X_AXIS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const illuminance = ({ ...restProps } = {}) => {
    return {
        Header: 'Ambient Light',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'ANBIENT_LIGHT',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const zSideAcceleration = ({ ...restProps } = {}) => {
    return {
        Header: 'Z Side Acceleration',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'ANGULAR_Z_AXIS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const YSideAcceleration = ({ ...restProps } = {}) => {
    return {
        Header: 'Y Side Acceleration',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'ANGULAR_Y_AXIS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const xSideAcceleration = ({ ...restProps } = {}) => {
    return {
        Header: 'X Side Acceleration',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'ANGULAR_X_AXIS',
        Cell: ({ value }) => {
            return value ? <span title={value}>{value}</span> : '-';
        },
        ...restProps,
    };
};
export const lastReceivedDate = ({ ...restProps } = {}) => {
    return {
        Header: 'Last Received Date',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: '',
        ...restProps,
    };
};
export const temperature = ({ ...restProps } = {}) => {
    return {
        Header: 'Temperature',
        headerClassName: 'flex-center',
        className: 'flex-center text-ellipsis',
        accessor: 'TEMPERATURE',
        ...restProps,
    };
};

export const outDate = ({ ...restProps } = {}) => {
    return {
        Header: 'Out Date',
        className: 'flex-center text-ellipsis',
        accessor: 'outDate',
        Cell: ({ value }) => {
            return value ? moment(value * 1000).format('YYYY-MM-DD HH:mm:ss') : '-';
        },
        ...restProps,
    };
};
export const outScannerName = ({ ...restProps } = {}) => {
    return {
        Header: 'Out Scanner Name',
        className: 'flex-center text-ellipsis',
        accessor: 'outScannerName',
    };
};

export const sensorMacAddress = ({ ...restProps }) => {
    return {
        Header: 'Sensor Mac Address',
        headerClassName: 'text-ellipsis',
        accessor: 'sensorId',
        className: 'd-flex justify-content-center text-ellipsis',
        ...restProps,
    };
};

export const sensorItems = ({ ...restProps }) => {
    return {
        Header: 'Measurement Item',
        headerClassName: 'text-ellipsis',
        accessor: 'sensorItems',
        ...restProps,
    };
};

export const data = ({ ...restProps }) => {
    return {
        Header: 'Value',
        headerClassName: 'text-ellipsis',
        accessor: 'data',
        ...restProps,
    };
};

export const remarks = ({ ...restProps }) => {
    return {
        Header: 'Remarks',
        headerClassName: 'text-ellipsis',
        accessor: 'remarks',
        className: 'text-ellipsis p-1',
        ...restProps,
    };
};

export const bookmark = ({ ...restProps }) => {
    return {
        Header: 'Bookmark',
        headerClassName: 'text-ellipsis',
        accessor: 'bookmark',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const modDate = ({ ...restProps } = {}) => {
    return {
        Header: 'Modified Date',
        headerClassName: 'flx-center',
        className: 'd-flex flx-center',
        accessor: 'modDate',
        width: 100,
        Cell: ({ value, row: { original } }) => {
            const modDate = value || original.regDate;
            return modDate ? moment((value || original.regDate) * 1000).format('YYYY-MM-DD HH:mm:ss') : '';
        },
        ...restProps,
    };
};

export const ranking = ({ ...restProps } = {}) => {
    return {
        Header: 'Ranking',
        headerClassName: 'text-ellipsis',
        accessor: 'ranking',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const name = ({ ...restProps } = {}) => {
    return {
        Header: 'Name',
        headerClassName: 'text-ellipsis',
        accessor: 'name',
        className: 'text-ellipsis',
        ...restProps,
    };
};

export const count = ({ ...restProps } = {}) => {
    return {
        Header: 'Count',
        headerClassName: 'text-ellipsis',
        accessor: 'count',
        className: 'text-ellipsis',
        ...restProps,
    };
};
