import { createContext, Dispatch, useContext } from 'react';
import { ActionType } from '@components/ContextProvider';
import { createSlice } from '@reduxjs/toolkit';
import { Nullable, NullableNumber } from '@util/type/util';
import { MODEL_CODE } from '@util/staticData/sensorMeta';

export const initialState: {
    patientInfo: Nullable<{ targetNum: number; mappedSensorList: { deviceNum: number }[]; [key: string]: any }>;
    mappedSensorNum: NullableNumber;
} = {
    patientInfo: null,
    mappedSensorNum: null,
};

export const PatientDetailsStateContext = createContext(initialState);
export const PatientDetailsDispatchContext = createContext<Dispatch<ActionType> | null>(null);

export const usePatientDetailsStateContext = () => useContext(PatientDetailsStateContext);
export const usePatientDetailsDispatchContext = () => useContext(PatientDetailsDispatchContext)!;

const patientDetailsSlice = createSlice({
    name: 'sh/patientDetails',
    initialState,
    reducers: {
        setPatientInfo: (state, action) => {
            state.patientInfo = action.payload;
            state.mappedSensorNum = action.payload.mappedSensorList.find(
                (sensor: { modelCode: string; deviceNum: number }) => sensor.modelCode === MODEL_CODE.MEZOO_SMARTPATCH,
            )?.deviceNum;
        },
    },
});

export const { setPatientInfo } = patientDetailsSlice.actions;

export default patientDetailsSlice;
