import { Chart } from 'react-chartjs-2';
import { UpdateMode } from 'chart.js';
import { EmptyObject } from 'chart.js/types/basic';

export interface LoadingOptions {
    afterLoad?: (loading: boolean) => void;
    install?(chart: Chart, args: object, options: LoadingOptions): void;
    beforeDatasetsUpdate?(chart: Chart, args: { mode: UpdateMode }): boolean | void;
    afterDatasetsUpdate?(chart: Chart, args: { mode: UpdateMode; cancelable: true }): void;
    afterRender?(chart: Chart, args: EmptyObject): void;
}

export default {
    id: 'loading',
    install(chart: Chart, args: object, options: LoadingOptions) {
        if (typeof options.install === 'function') {
            return options.install(chart, args, options);
        }
        if (typeof options.afterLoad === 'function') {
            options.afterLoad(false);
        }
    },
    uninstall(chart: Chart, args: object, options: LoadingOptions) {
        if (typeof options.afterLoad === 'function') {
            options.afterLoad(true);
        }
    },
    beforeDatasetsUpdate(chart: Chart, args: { mode: UpdateMode }, options: LoadingOptions): boolean | void {
        if (typeof options.beforeDatasetsUpdate === 'function') {
            return options.beforeDatasetsUpdate(chart, args);
        }
    },
    afterDatasetsUpdate(chart: Chart, args: { mode: UpdateMode; cancelable: true }, options: LoadingOptions) {
        if (typeof options.afterDatasetsUpdate === 'function') {
            options.afterDatasetsUpdate(chart, args);
        }
    },
    afterRender(chart: Chart, args: EmptyObject, options: LoadingOptions) {
        if (typeof options.afterRender === 'function') {
            options.afterRender(chart, args);
        }
    },
};
