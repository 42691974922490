import React, { useMemo, useState } from 'react';
import { useAsync, useConfirm, useTranslation } from '@hooks';
import { Label, Select, SearchableSelect, ConfirmModal, TextInput } from '@components';
import { WidgetProps } from '../../staticInfo';
import WidgetCard from '../../Components/WidgetCard';
import { Trans } from 'react-i18next';
import {
    getPatientAnomalyAlertSettingsApi,
    PatientAnomalyAlertSettings,
    updatePatientAnomalyAlertSettingsApi,
} from '@api/sh/patientAnomalyAlertSetting';
import { YN } from '@util/type/util';
import Button from '@components/Button';
import styled from 'styled-components';
import cx from 'classnames';

const OPTIONS_USAGE = [
    { value: 'Y', label: 'Enabled' },
    { value: 'N', label: 'Disabled' },
];

const OPTIONS_SELECT_RESTRICTION_ZONES = [
    { value: 'Y', label: 'Select' },
    { value: 'N', label: 'Not Select' },
];

const OPTIONS_OPEN_TIME = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
];

const PatientAnomalyAlertSetting = ({ widgetInfo, children, ...restProps }: WidgetProps) => {
    const t = useTranslation('PatientAnomalyAlertSetting');
    const [settings, setSettings] = useState<PatientAnomalyAlertSettings>({
        alertActive: 'Y',
        alertOpenTime: 1,
        alertOpenCount: 10,
        alertCloseCount: 10,
        alertRestrictionActive: 'N',
        alertRestrictionZones: [],
    });

    const usageOptions = useMemo(() => {
        return OPTIONS_USAGE.map(({ value, label }) => ({ value, label: t(label) }));
    }, [t]);

    const selectRestrictionZonesOptions = useMemo(() => {
        return OPTIONS_SELECT_RESTRICTION_ZONES.map(({ value, label }) => ({ value, label: t(label) }));
    }, [t]);

    useAsync({
        promise: getPatientAnomalyAlertSettingsApi,
        immediate: true,
        resolve: res => {
            setSettings(res);
        },
    });

    const { promise: updatePatientAnomalyAlertSettings } = useAsync({
        promise: updatePatientAnomalyAlertSettingsApi,
        resolve: () => {
            saveSuccessModalProps.toggleModal();
        },
        reject: () => {
            saveFailureModalProps.toggleModal();
        },
    });

    const saveConfirmModalProps = useConfirm({
        confirmText: t('Do you want to save?'),
        callbackParam: settings,
        okCallback: (settings: PatientAnomalyAlertSettings) => {
            if (validAlertOpenCount(settings) && validAlertCloseCount(settings)) {
                updatePatientAnomalyAlertSettings(settings);
            } else {
                validationFailureModalProps.toggleModal();
            }
        },
    });

    const validationFailureModalProps = useConfirm({
        confirmText: t("Sorry, we didn't get the value right, please check and try again."),
        removeCancel: true,
    });

    const saveSuccessModalProps = useConfirm({
        confirmText: t('Save is complete.'),
        removeCancel: true,
    });

    const saveFailureModalProps = useConfirm({
        confirmText: t('The save failed.'),
        removeCancel: true,
    });

    return (
        <WidgetCard widgetInfo={widgetInfo} {...restProps}>
            <ContentWrapper>
                <section>
                    <Label
                        name={
                            <span>
                                <div>{t('Enable or disable the patient anomaly alert popup')}</div>
                                <small>
                                    * {t('You can choose to enable or disable the patient anomaly alert popup.')}
                                </small>
                            </span>
                        }
                        value={
                            <Select
                                options={usageOptions}
                                value={usageOptions.find(({ value }) => value === settings.alertActive)}
                                onChange={selected => {
                                    const selectedItem = selected as { value: YN; label: string };
                                    if (selected) {
                                        setSettings({ ...settings, alertActive: selectedItem.value });
                                    }
                                }}
                            />
                        }
                    />
                    <div className="pnt-divider horizon-line" />
                    <Label
                        name={
                            <span>
                                <div>{t('Patient anomaly alert popup open time')}</div>
                                <small>
                                    * {t('Set the anomaly persistence criteria that triggers the alert popup.')}
                                </small>
                            </span>
                        }
                        value={
                            <span>
                                <div className={'d-flex align-items-center'}>
                                    <Trans
                                        i18nKey={'PatientAnomalyAlertSetting'}
                                        components={[
                                            <TextInput
                                                maxlength={4}
                                                inputGroupClassName={cx(
                                                    'mx-1 w-narrow',
                                                    !validAlertOpenCount(settings) && 'input-error',
                                                )}
                                                disabled={settings.alertActive === 'N'}
                                                value={settings.alertOpenCount}
                                                handleChange={e => {
                                                    const { value } = e.currentTarget;
                                                    if (/^\d*$/.test(value)) {
                                                        setSettings({
                                                            ...settings,
                                                            alertOpenCount: Number(value),
                                                        });
                                                    }
                                                }}
                                                errorMsg={
                                                    settings.alertOpenCount === 0
                                                        ? t('Please enter a number greater than or equal to 1.')
                                                        : t('Cannot exceed 60 times per minute. Current limit:') +
                                                          ' ' +
                                                          settings.alertOpenTime * 60
                                                }
                                            />,
                                            <Select
                                                disabled={settings.alertActive === 'N'}
                                                className={'mx-1'}
                                                options={OPTIONS_OPEN_TIME}
                                                value={OPTIONS_OPEN_TIME.find(
                                                    ({ value }) => value === settings.alertOpenTime,
                                                )}
                                                onChange={selected => {
                                                    const selectedItem = selected as { value: number; label: number };
                                                    if (selected) {
                                                        setSettings({
                                                            ...settings,
                                                            alertOpenTime: selectedItem.value,
                                                        });
                                                    }
                                                }}
                                            />,
                                        ]}
                                    >
                                        {t(
                                            'Pop up an alert when a patient has <0></0> abnormalities in <1></1> minutes.',
                                        )}
                                    </Trans>
                                </div>
                            </span>
                        }
                    />
                    <div className="pnt-divider horizon-line" />
                    <Label
                        name={
                            <span>
                                <div>{t('Patient anomaly alert popup closing time')}</div>
                                <small>
                                    * {t('Set the number of steady-state durations to close the notification popup.')}
                                </small>
                            </span>
                        }
                        value={
                            <span>
                                <div className={'d-flex align-items-center'}>
                                    <Trans
                                        i18nKey={'PatientAnomalyAlertSetting'}
                                        components={[
                                            <TextInput
                                                maxlength={4}
                                                inputGroupClassName={cx(
                                                    'mx-1 w-narrow',
                                                    !validAlertCloseCount(settings) && 'input-error',
                                                )}
                                                disabled={settings.alertActive === 'N'}
                                                value={settings.alertCloseCount}
                                                handleChange={e => {
                                                    const { value } = e.currentTarget;
                                                    if (/^\d*$/.test(value)) {
                                                        setSettings({
                                                            ...settings,
                                                            alertCloseCount: Number(value),
                                                        });
                                                    }
                                                }}
                                                errorMsg={t('Please enter a number greater than or equal to 1.')}
                                            />,
                                        ]}
                                    >
                                        {t(
                                            'Close the notification popup after <0></0> consecutive patient steady states.',
                                        )}
                                    </Trans>
                                </div>
                            </span>
                        }
                    />
                    <div className="pnt-divider horizon-line" />
                    <Label
                        name={
                            <span>
                                <div>{t('Select an patient anomaly alert restricted area')}</div>
                                <small>* {t("Disable patient anomaly alert when you're in certain areas.")}</small>
                            </span>
                        }
                        value={
                            <>
                                <Select
                                    disabled={settings.alertActive === 'N'}
                                    options={selectRestrictionZonesOptions}
                                    value={usageOptions.find(({ value }) => value === settings.alertRestrictionActive)}
                                    onChange={selected => {
                                        const selectedItem = selected as { value: YN; label: string };
                                        if (selected) {
                                            setSettings({ ...settings, alertRestrictionActive: selectedItem.value });
                                        }
                                    }}
                                />
                                <SearchableSelect
                                    disabled={settings.alertActive === 'N' || settings.alertRestrictionActive === 'N'}
                                    valueKey={'fcGroupNum'}
                                    labelKey={'fcGroupName'}
                                    data={settings.alertRestrictionZones}
                                    value={settings.alertRestrictionZones.filter(fcGroup => fcGroup.isRestrict === 'Y')}
                                    onChange={selected => {
                                        const selectedItems = selected as PatientAnomalyAlertSettings['alertRestrictionZones'];
                                        setSettings({
                                            ...settings,
                                            alertRestrictionZones: settings.alertRestrictionZones.map(fcGroup => {
                                                if (
                                                    selectedItems.find(
                                                        selected => selected.fcGroupNum === fcGroup.fcGroupNum,
                                                    )
                                                ) {
                                                    return { ...fcGroup, isRestrict: 'Y' };
                                                } else {
                                                    return { ...fcGroup, isRestrict: 'N' };
                                                }
                                            }),
                                        });
                                    }}
                                />
                            </>
                        }
                    />
                </section>
                <section className={'d-flex justify-content-end gap-2'}>
                    <Button
                        className={'btn-secondary'}
                        onClick={() => {
                            saveConfirmModalProps.toggleModal();
                        }}
                    >
                        {t('Save')}
                    </Button>
                </section>
            </ContentWrapper>
            <ConfirmModal {...saveConfirmModalProps} />
            <ConfirmModal {...saveSuccessModalProps} />
            <ConfirmModal {...saveFailureModalProps} />
            <ConfirmModal {...validationFailureModalProps} />
            {children}
        </WidgetCard>
    );
};

const ContentWrapper = styled.div`
    display: grid;
    row-gap: 2rem;
    .label-main {
        flex: 0 0 25rem;
    }
    .pnt-input--group.w-narrow {
        min-width: 90px;
        width: 90px;
        &.input-error {
            margin-bottom: 0;
            .input-error-txt {
                white-space: nowrap;
                margin-top: 0;
            }
        }
    }
`;

const validAlertOpenCount = (settings: PatientAnomalyAlertSettings) => {
    return !(settings.alertOpenCount === 0 || settings.alertOpenTime * 60 < settings.alertOpenCount);
};

const validAlertCloseCount = (settings: PatientAnomalyAlertSettings) => {
    return settings.alertCloseCount !== 0;
};

export default PatientAnomalyAlertSetting;
