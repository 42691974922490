import { Button, Table } from '@components';
import { useTranslation } from '@util/hooks';
import React, { useMemo, useState } from 'react';
import Search from '../Components/Search';
import * as column from '@util/grid/column';
import moment from 'moment';
import styled from 'styled-components';
import useModal from '../../util/useModal';
import DetailModal from './DetailModal';

interface Props {
    onKeywordChange: (keyword: string) => void;
    onResetClick: () => void;
    data: any;
    onDownloadClick: () => void;
    onPageChange: (pageIndex: number) => void;
}

const HistoryList = ({ data, onKeywordChange, onResetClick, onDownloadClick, onPageChange }: Props) => {
    const t = useTranslation('AssetManagement');
    const [isDetailOpen, handleDetailToggle, handleDetailOpen] = useModal();
    const [logNum, setLogNum] = useState<number | null>(null);

    const columns = useMemo(
        () => [
            column.index({ width: 40 }),
            {
                Header: t('Category'),
                accessor: 'categoryName',
            },
            {
                Header: t('Asset Name'),
                Cell: ({ row }: any) => <span>{row.original.targetName ?? '-'}</span>,
            },
            {
                Header: t('Asset Code'),
                accessor: 'assetCode',
                Cell: ({ value }: { value: any }) => <span>{value || '-'}</span>,
            },
            {
                Header: t('Owner Ward'),
                accessor: 'ownDepName',
            },
            {
                Header: t('Rent Ward'),
                accessor: 'applyDepName',
            },
            {
                Header: t('Applicant'),
                accessor: 'applyUserName',
                width: 60,
            },
            {
                Header: t('Lending Period'),
                Cell: ({ row }: any) => (
                    <span>
                        {row.original.giveDate ? moment.unix(row.original.giveDate).format('YYYY-MM-DD') : '-'} ~{' '}
                        {row.original.returnDate ? moment.unix(row.original.returnDate).format('YYYY-MM-DD') : '-'}
                    </span>
                ),
            },
            {
                Header: t('Request Date'),
                Cell: ({ row }: any) => (
                    <span>{moment.unix(row.original.applyDate).format('YYYY-MM-DD HH:mm:ss')}</span>
                ),
            },
            {
                Header: t('Receiver'),
                Cell: ({ row }: any) => <span>{row.original.takeUserName ?? '-'}</span>,
                width: 60,
            },
            {
                Header: t('Return Date'),
                Cell: ({ row }: any) => (
                    <span>
                        {row.original.returnDate
                            ? moment.unix(row.original.returnDate).format('YYYY-MM-DD HH:mm:ss')
                            : '-'}
                    </span>
                ),
            },
            {
                Header: t('Returner'),
                Cell: ({ row }: any) => <span>{row.original.returnUserName ?? '-'}</span>,
                width: 60,
            },
        ],
        [t],
    ) as any;

    return (
        <>
            <div>
                <div className="pnt-txt txt-bold s-5 mb-2">{t('Rental History')}</div>
                <div className="d-flex justify-content-between">
                    <Search
                        handleSearchClick={onKeywordChange}
                        handleReset={onResetClick}
                        placeholder={t('Enter the search keyword')}
                        isLoading={false}
                    />
                    <Button className="btn-secondary" onClick={onDownloadClick}>
                        {t('Download')}
                    </Button>
                </div>
                <TableWrapper>
                    <Table
                        data={{ ...data, pageSize: 15 }}
                        columns={columns}
                        noDataMessage={t('There is no asset history to display.')}
                        onTrClick={({ rentalCompleteNum }: { rentalCompleteNum: number }) => {
                            setLogNum(rentalCompleteNum);
                            handleDetailOpen();
                        }}
                        onPageChange={onPageChange}
                    />
                </TableWrapper>
            </div>
            {isDetailOpen && <DetailModal initModal={isDetailOpen} toggle={handleDetailToggle} logNum={logNum} />}
        </>
    );
};

const TableWrapper = styled.div`
    height: 33rem;
`;

export default HistoryList;
