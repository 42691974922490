import React, { useState, useRef, useMemo } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useAsync, useTranslation } from '@hooks';
import { fetchIotItemList } from '@api/common/asset';
import MenuItem from './MenuItem';
import { useRealTimeLocationStatusContext } from '../../index';

// 자동완성 API 호출 keyword 길이
const SEARCH_MIN_LENGTH = 2;

// 자동완성 타이핑 이벤트 debounce 시간
const AUTO_COMPLETE_DEBOUNCE_TIME = 400;

// 자동완성 리스트 페이지 크기
const SEARCH_PAGE_SIZE = 20;

const TargetSearchSelect = ({ handleChangeSelect }) => {
    const typeaheadRef = useRef();
    const t = useTranslation('RealTimeLocationStatus');
    const [searching, setSearching] = useState(false);
    const [allItemList, setAllItemList] = useState([]);
    const { selectedItem } = useRealTimeLocationStatusContext();

    const selected = useMemo(() => {
        return selectedItem?.targetId ? [selectedItem] : [];
    }, [selectedItem]);

    const { promise: getIotItemList, state: IoTItemInfo } = useAsync({
        promise: fetchIotItemList,
        keepState: true,
        fixedParam: { pageSize: SEARCH_PAGE_SIZE },
        resolve: (response, request) => {
            setSearching(true);
            const { rows, totalPage, page } = response;
            const hasNext = totalPage > page;
            if (hasNext) {
                rows.push({ targetId: '', targetName: request.keyword, tempNodeForPage: true });
            }

            if (response.page === 1) {
                setAllItemList(rows);
            } else {
                setAllItemList(allItemList.slice(0, -1).concat(rows));
            }
        },
    });

    return (
        <AsyncTypeahead
            inputProps={{ className: 'pnt-input disable-rbt-hint' }}
            isLoading={IoTItemInfo.isLoading}
            placeholder={t('Search Item Name or Item ID')}
            paginationText={t('Display additional results...', 'Search')}
            emptyLabel={!searching ? t('Please Search for the Item.') : t('There are no search results', 'Search')}
            id="targetId"
            multiple={false}
            allowNew={false}
            options={allItemList}
            valueKey={'targetId'}
            labelKey={'targetName'}
            filterBy={['targetId', 'targetName']}
            renderMenuItemChildren={(option, { text }) => <MenuItem option={option} searchText={text} />}
            onChange={selected => {
                const item = selected[0];
                if (!item) {
                    setSearching(false);
                }
                if (typeof handleChangeSelect === 'function') {
                    handleChangeSelect(item);
                }
            }}
            paginate
            onPaginate={(e, shownResults) => {
                const page = Math.ceil(shownResults / SEARCH_PAGE_SIZE);
                getIotItemList({
                    page,
                    opt: 'targetSearch',
                    keyword: IoTItemInfo?.request?.keyword,
                });
            }}
            onSearch={query => {
                getIotItemList({
                    opt: 'targetSearch',
                    keyword: query,
                });
            }}
            minLength={SEARCH_MIN_LENGTH}
            maxResults={SEARCH_PAGE_SIZE}
            delay={AUTO_COMPLETE_DEBOUNCE_TIME}
            useCache={false}
            clearButton
            ref={typeaheadRef}
            selected={selected}
        />
    );
};

export default TargetSearchSelect;
