import { createContext, Dispatch, useContext } from 'react';
import { createSlice } from '@reduxjs/toolkit';
import { ActionType } from '@components/ContextProvider';
import Widget from '../../staticInfo';
import { TagKey, TagType, TYPE_TO_TAGS } from '../../tags';

export interface WidgetSearchPopupState {
    originList: Widget[];
    prevList: Widget[];
}

const initialState: WidgetSearchPopupState = {
    originList: [],
    prevList: [],
};

export const WidgetSearchStateContext = createContext(initialState);
export const WidgetSearchDispatchContext = createContext<Dispatch<ActionType> | null>(null);

export const useWidgetSearchStateContext = () => useContext(WidgetSearchStateContext);
export const useWidgetSearchDispatchContext = () => useContext(WidgetSearchDispatchContext)!;

const tagTypes = Object.keys(TYPE_TO_TAGS) as TagType[];

const widgetSearchSlice = createSlice({
    name: 'common/widgetSearch',
    initialState,
    reducers: {
        setPrevList: (state, action) => {
            const { keyword, t, selectedTags } = action.payload;
            const selectedTagsByType = tagTypes.reduce((acc: TagKey[][], currType) => {
                const tagsByType = selectedTags.filter((tagInfo: { type: TagType }) => tagInfo.type === currType);
                if (tagsByType.length) {
                    acc.push(tagsByType.map(({ tag }: { tag: TagKey }) => tag));
                }
                return acc;
            }, []);

            state.prevList = state.originList.filter(({ name, tags }) => {
                const tagFilter =
                    !selectedTagsByType.length ||
                    selectedTagsByType.every(tagsByType => tagsByType.some(tag => (tags ?? []).includes(tag)));
                return t(name).toUpperCase().indexOf(keyword.toUpperCase()) >= 0 && tagFilter;
            });
        },
    },
});

export const { setPrevList } = widgetSearchSlice.actions;

export default widgetSearchSlice;
