import React from 'react';
import { Button, LineChart } from '@components';
import InnerItemCard from '@components/Card/InnerItemCard';
import { useAppSelector, useTranslation } from '@hooks';
import { OUTPATIENT_REPORT, OUTPATIENT_WAIT_CHART_TITLE, TIME_LABELS } from '../../constants';
import styled from 'styled-components';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { hoverBackgroundPlugin } from '../util/chartPlugin';
import { createTooltipBase, dataLabelsColorByTheme } from '../util';

const Chart = ({ data, onDownloadClick }) => {
    const t = useTranslation(OUTPATIENT_REPORT);
    const { colorScheme } = useAppSelector(state => state.ThemeOptions);

    const lineData = {
        labels: TIME_LABELS,
        datasets: data.map(({ departmentName, wait }) => ({
            label: departmentName,
            data: wait,
            datalabels: {
                display: data.length === 1,
                // 이 데이터셋에만 데이터 레이블 표시
                anchor: 'end',
                align: 'top',
                color: dataLabelsColorByTheme(colorScheme),
                formatter: function (value) {
                    return `${value} ${t('Minute')}`; // 표시할 값
                },
            },
        })),
    };

    return (
        <InnerItemCard
            className={'m-2 h-90'}
            bodyClassName={'h-100'}
            header={{ title: t(OUTPATIENT_WAIT_CHART_TITLE) }}
        >
            <div className={'h-90 flx-row position-relative'}>
                <div className={'w-100 h-100 '}>
                    <LineChart
                        data={lineData}
                        plugins={[ChartDataLabels, hoverBackgroundPlugin]}
                        options={{
                            plugins: {
                                tooltip: {
                                    intersect: false,
                                    mode: 'index',
                                    enabled: false,
                                    external: function (context) {
                                        createTooltipBase(context, (dataPoints, tooltipEl) => {
                                            dataPoints.forEach(dataPoint => {
                                                const {
                                                    label: datasetLabel = '',
                                                    backgroundColor: color,
                                                } = dataPoint.dataset;
                                                const value = dataPoint.raw;

                                                tooltipEl.insertAdjacentHTML(
                                                    'beforeend',
                                                    `
                                                       <div class="d-flex align-items-center gap-1">
                                                          <div style="height: 15px; width: 15px; background-color: ${color};"></div>
                                                          <div>${datasetLabel}: ${value}${t('Minute')}</div>
                                                       </div>`,
                                                );
                                            });
                                        });
                                    },
                                },
                            },
                            scales: {
                                y: {
                                    beginAtZero: true,
                                    suggestedMax: data.length === 1 ? Math.max(...data[0].wait) + 10 : 10,
                                },
                            },
                        }}
                    />
                </div>
                <DownloadButton className={'btn-secondary'} onClick={onDownloadClick}>
                    {t('Download')}
                </DownloadButton>
            </div>
        </InnerItemCard>
    );
};

const DownloadButton = styled(Button)`
    position: absolute;
    top: 0;
    right: 0;
`;

export default Chart;
