import React, { FC } from 'react';
import cx from 'classnames';

import type { TextAreaProps } from '@components/type';

const TextArea: FC<TextAreaProps> = ({
    id,
    name,
    inputGroupClassName,
    disabled,
    textAreaClassName,
    resize,
    value,
    handleChange,
    required,
    readonly,
    placeholder,
    autoFocus,
}) => {
    return (
        <div className={cx('pnt-input--group', inputGroupClassName, disabled && 'form-disable')}>
            <textarea
                id={id}
                name={name}
                className={cx('pnt-input pnt-textarea', !resize && 'resize-none', textAreaClassName)}
                disabled={disabled}
                value={value}
                required={required}
                onChange={handleChange}
                readOnly={readonly}
                placeholder={placeholder}
                autoFocus={autoFocus}
            />
        </div>
    );
};

export default TextArea;
