import React, { useState } from 'react';
import { Button, ConfirmModal, Modal, Table, TextInput } from '@components';
import { ModalFooter } from 'reactstrap';
import { useAsync, useColumns, useConfirm, useTranslation } from '@hooks';
import { getDeviceListApi, postDeviceMappingApi } from '@api/sh/deviceManagement';
import * as col from '../../columns';
import { useDeviceMappingManagementStateContext } from '../../slice';
import { MODEL_CODE } from '@util/staticData/sensorMeta';

interface Props {
    isOpen: boolean;
    toggleModal: () => void;
    closeCallback: () => void;
}

const DeviceSearchModal = ({ isOpen, toggleModal, closeCallback }: Props) => {
    const t = useTranslation('DeviceMappingManagement');
    const {
        targetInfo: { targetNum },
    } = useDeviceMappingManagementStateContext();
    const [keyword, setKeyword] = useState('');
    const [error, setError] = useState({
        code: '',
        message: '',
    });

    const handleReset = () => {
        setKeyword('');
        setError({ code: '', message: '' });
    };

    const handleSearchClick = () => {
        // 텍스트 기준 목록 조회
        getDeviceList({ keyword });
    };

    const mappingSuccessModalProps = useConfirm({
        initModal: false,
        confirmText: t('The mapping is complete.'),
        removeCancel: true,
        okCallback: () => {
            handleReset();
            closeCallback();
            toggleModal();
        },
    });

    const mappingFailedModalProps = useConfirm({
        initModal: false,
        confirmText: (
            <div>
                <div className={'mb-1'}>{t('The mapping failed, please try again in a moment.')}</div>
                {(error?.message || error?.code) && (
                    <details>
                        <summary>
                            {t('Error Code')}: {error.code}
                        </summary>
                        <div>{error.message}</div>
                    </details>
                )}
            </div>
        ),
        removeCancel: true,
    });

    // 매핑 가능 기기 목록 조회 API
    const { promise: getDeviceList, state: deviceList } = useAsync({
        promise: getDeviceListApi,
        fixedParam: {
            opt: 'sensorName',
            modelCodeList: [MODEL_CODE.MEZOO_SMARTPATCH],
            pageSize: 5,
        },
        immediate: isOpen, // 초기 렌더링 시 API 호출 방지(모달을 열었을 때만 실행시키기 위함)
        deps: [isOpen],
    });

    // 매핑 API
    const { promise: postDeviceMapping } = useAsync({
        promise: postDeviceMappingApi,
        resolve: () => {
            mappingSuccessModalProps.toggleModal();
        },
        reject: error => {
            if (error?.data) {
                const { code, message } = error.data;
                setError({ code, message });
            }
            mappingFailedModalProps.toggleModal();
        },
    });

    const mappingColumns = useColumns(
        [
            col.sensorName(),
            col.mapping({
                Cell: ({
                    row: {
                        original: { deviceNum },
                    },
                }: {
                    row: { original: { deviceNum: number } };
                }) => {
                    return (
                        <Button
                            className="btn-icon-only btn-trans br-5 icon-big"
                            iconName={'link'}
                            onClick={() => {
                                postDeviceMapping({
                                    targetNum,
                                    sensorList: [{ deviceNum, modelCode: MODEL_CODE.MEZOO_SMARTPATCH }],
                                });
                            }}
                        />
                    );
                },
            }),
        ],
        t,
        [targetNum],
    );

    const deviceListResponse = deviceList?.response || { rows: [], page: 1 };

    return (
        <>
            <Modal
                initModal={isOpen}
                toggleModal={toggleModal}
                removeModalClose={false}
                cancelCallback={closeCallback}
                headerTitle={t('Search device')}
                bodyText={
                    <div className={'d-flex flex-column gap-3'}>
                        <div className={'pnt-txt txt-dot'}>
                            {t('Select the name of the label on your device or search for it.')}
                        </div>
                        <div className={'w-100 d-flex'}>
                            <TextInput
                                inputGroupClassName={'w-100'}
                                placeholder={t('Please enter the device name.')}
                                value={keyword}
                                handleChange={e => {
                                    setKeyword(e.target.value);
                                }}
                            />
                            <Button className={'btn-secondary'} onClick={handleSearchClick}>
                                {t('Search', 'Search')}
                            </Button>
                        </div>
                        <div style={{ height: '180px' }}>
                            <Table
                                columns={mappingColumns}
                                data={{
                                    ...deviceListResponse,
                                    pageSize: 5,
                                }}
                                onPageChange={page => {
                                    getDeviceList({ page });
                                }}
                            />
                        </div>
                    </div>
                }
                modalFooter={
                    <ModalFooter className={'d-flex justify-content-end'}>
                        <Button
                            className={'btn-secondary'}
                            onClick={() => {
                                handleReset();
                                closeCallback();
                                toggleModal();
                            }}
                        >
                            {t('Close', 'Button')}
                        </Button>
                    </ModalFooter>
                }
            />
            <ConfirmModal {...mappingSuccessModalProps} />
            <ConfirmModal {...mappingFailedModalProps} />
        </>
    );
};

export default DeviceSearchModal;
