import React from 'react';
import { Label } from '@components';

const LocationLabel = ({ name, value }) => {
    return (
        <div className="mb-3">
            <Label name={name} value={value} labelValueClassName={'pnt-txt txt-bold txt-dot'} />
        </div>
    );
};

export default LocationLabel;
