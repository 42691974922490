import {
    COLOR_CRITICAL,
    COLOR_MOVEMENT,
    COLOR_NONE_ACTIVE,
    COLOR_NORMAL,
    COLOR_OUT_OR_RANGE,
    COLOR_WARNING,
} from './constants';
import { DisplayedMonitoringPatient } from './slice';
import bed from '@asset/images/hicardi-status/bed.svg';
import leadOff from '@asset/images/hicardi-status/leadOff.svg';
import noSignal from '@asset/images/hicardi-status/noSignal.svg';
import lostSignal from '@asset/images/hicardi-status/lostSignal.svg';
import run from '@asset/images/hicardi-status/run.svg';
import outOfRange from '@asset/images/hicardi-status/outOfRange.svg';
import { MODEL_CODE, MODEL_TO_SENSOR_TYPE } from '@util/staticData/sensorMeta';
import { MappedSensor } from '@api/sh/biometricInformationMonitoring';
import { checkLostSignal } from '@util/mappInfo';

const SENSOR_TYPE_ORDER = MODEL_TO_SENSOR_TYPE[MODEL_CODE.MEZOO_SMARTPATCH] ?? [];
type UsageSensorType = keyof MappedSensor['recentSensorValues'];

const STATUS_NORMAL = 'NORMAL';
export const STATUS_WARNING = 'WARNING';
export const STATUS_CRITICAL = 'CRITICAL';
export const STATUS_OUTOFRANGE = 'OUTOFRANGE';
export const TEMP_LOST_SIGNAL_TIME = 60000;
export const isLostSignal = (patient: DisplayedMonitoringPatient) => {
    return !!patient.lostSignalState && checkLostSignal(patient.lostSignalState);
};
const isNoData = (patient: DisplayedMonitoringPatient) =>
    SENSOR_TYPE_ORDER.every(
        sensorType => typeof patient.recentSensorValues?.[sensorType as UsageSensorType] !== 'number',
    );
const isLeadOff = (patient: DisplayedMonitoringPatient) => patient.info?.leadOff === 1;
const isMoving = (patient: DisplayedMonitoringPatient) => [2, 3].includes(patient.info?.motion ?? NaN);
const isBed = (patient: DisplayedMonitoringPatient) => [0, 1].includes(patient.info?.motion ?? NaN);
const isCritical = (patient: DisplayedMonitoringPatient) =>
    SENSOR_TYPE_ORDER.some(
        sensorType => patient.recentSensorStates?.[sensorType as UsageSensorType] === STATUS_CRITICAL,
    );
const isWarning = (patient: DisplayedMonitoringPatient) =>
    SENSOR_TYPE_ORDER.some(
        sensorType => patient.recentSensorStates?.[sensorType as UsageSensorType] === STATUS_WARNING,
    );
const isOutOfRange = (patient: DisplayedMonitoringPatient) =>
    SENSOR_TYPE_ORDER.some(
        sensorType => patient.recentSensorStates?.[sensorType as UsageSensorType] === STATUS_OUTOFRANGE,
    );
const isNormal = (patient: DisplayedMonitoringPatient) =>
    SENSOR_TYPE_ORDER.every(
        sensorType => patient.recentSensorStates?.[sensorType as UsageSensorType] === STATUS_NORMAL,
    );

export const CLASSNAME_LOST_SIGNAL = 'status-lostsignal';
export const CLASSNAME_NONE_ACTIVE = 'status-none-active';
export const CLASSNAME_OUT_OF_RANGE = 'status-outofrange';
export const CLASSNAME_MOVEMENT = 'status-movement';
export const CLASSNAME_CRITICAL = 'status-critical';
export const CLASSNAME_WARNING = 'status-warning';
export const CLASSNAME_NORMAL = 'status-normal';

export const getStatusClassName = (patient: DisplayedMonitoringPatient) => {
    if (isNoData(patient) || isLeadOff(patient) || isLostSignal(patient)) {
        return CLASSNAME_NONE_ACTIVE;
    }
    if (isMoving(patient)) {
        return CLASSNAME_MOVEMENT;
    }
    if (isCritical(patient)) {
        return CLASSNAME_CRITICAL;
    }
    if (isWarning(patient)) {
        return CLASSNAME_WARNING;
    }
    if (isOutOfRange(patient)) {
        return CLASSNAME_NONE_ACTIVE;
    }
    if (isNormal(patient)) {
        return CLASSNAME_NORMAL;
    }
    return CLASSNAME_NORMAL;
};

export const getStatusStyle = (colorSelector: string = '&') => `
    &.${CLASSNAME_NORMAL} {
        background-color: ${COLOR_NORMAL};
        ${colorSelector}, ${colorSelector} * {
            color: #fff;
        }
    }
    &.${CLASSNAME_WARNING} {
        background-color: ${COLOR_WARNING};
        ${colorSelector}, ${colorSelector} * {
            color: #000;
        }
    }
    &.${CLASSNAME_CRITICAL} {
        background-color: ${COLOR_CRITICAL};
        ${colorSelector}, ${colorSelector} * {
            color: #fff;
        }
    }
    &.${CLASSNAME_OUT_OF_RANGE} {
        background-color: ${COLOR_OUT_OR_RANGE};
        ${colorSelector}, ${colorSelector} * {
            color: #fff;
        }
    }
    &.${CLASSNAME_MOVEMENT} {
        background-color: ${COLOR_MOVEMENT};
        ${colorSelector}, ${colorSelector} * {
            color: #fff;
        }
    }
    &.${CLASSNAME_NONE_ACTIVE} {
        background-color: ${COLOR_NONE_ACTIVE};
        ${colorSelector}, ${colorSelector} * {
            color: #ffffff;
        }
    }
    &.${CLASSNAME_LOST_SIGNAL} {
        background-color: ${COLOR_NONE_ACTIVE};
        border-color: ${COLOR_CRITICAL};
        ${colorSelector}, ${colorSelector} * {
            color: var(--base-txt);
        }
    }
`;

const ICON_BED = 'status-ico-bed';
export const ICON_LEAD_OFF = 'status-ico-lead-off';
export const ICON_OUT_OF_RANGE = 'status-ico-outofrange';
export const ICON_LOST_SIGNAL = 'status-ico-lostsignal';
const ICON_NO_SIGNAL = 'status-ico-no-signal';
export const ICON_RUN = 'status-ico-run';
const ICON_NONE = 'status-ico-none';

export const ICON_CLASS_TO_LABEL = {
    [ICON_LOST_SIGNAL]: 'Lost Signal',
    [ICON_NO_SIGNAL]: 'No Signal',
    [ICON_LEAD_OFF]: 'Lead Off',
    [ICON_RUN]: 'Movement',
    [ICON_OUT_OF_RANGE]: 'Out Of Range',
    [ICON_NONE]: '',
};

export const getIconClassName = (patient: DisplayedMonitoringPatient) => {
    if (isLostSignal(patient)) {
        return ICON_LOST_SIGNAL;
    }
    if (isNoData(patient)) {
        return ICON_NO_SIGNAL;
    }
    if (isLeadOff(patient)) {
        return ICON_LEAD_OFF;
    }
    if (isMoving(patient)) {
        return ICON_RUN;
    }
    if (isCritical(patient) || isWarning(patient) || isNormal(patient)) {
        return ICON_NONE;
    }
    if (isOutOfRange(patient)) {
        return ICON_OUT_OF_RANGE;
    }
    // if (isBed(patient)) {
    //     return ICON_BED;
    // }
    return ICON_NONE;
};

export const getStatusIcon = () => `
        &.${ICON_BED} {
            mask-image: url(${bed});
        }
        &.${ICON_LEAD_OFF} {
            mask-image: url(${leadOff});
        }
        &.${ICON_OUT_OF_RANGE} {
            mask-image: url(${outOfRange});
        }
        &.${ICON_LOST_SIGNAL} {
            mask-image: url(${lostSignal});
        }
        &.${ICON_NO_SIGNAL} {
            mask-image: url(${noSignal});
        }
        &.${ICON_RUN} {
            mask-image: url(${run});
        }
        &.${ICON_NONE} {
            display: none;
        }`;
