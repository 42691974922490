import React, { createContext, useContext } from 'react';
import ContextProvider from '@components/ContextProvider';

import HourlyCongestionStatusContainer from './HourlyCongestionStatusContainer';
import hourlyCongestionStatusSlice from './hourlyCongestionStatusSlice';

export const HourlyCongestionStatusStateContext = createContext(null);
export const HourlyCongestionStatusDispatchContext = createContext(null);

export const useHourlyCongestionStatusContext = () => useContext(HourlyCongestionStatusStateContext);

export const useHourlyCongestionStatusDispatch = () => useContext(HourlyCongestionStatusDispatchContext);

const HourlyCongestionStatus = ({ children, widgetInfo, ...restProps }) => {
    return (
        <ContextProvider
            StateContext={HourlyCongestionStatusStateContext}
            DispatchContext={HourlyCongestionStatusDispatchContext}
            slice={hourlyCongestionStatusSlice}
        >
            <HourlyCongestionStatusContainer widgetInfo={widgetInfo} children={children} {...restProps} />
        </ContextProvider>
    );
};

export default HourlyCongestionStatus;
