import { reqDelete, reqGet, reqPost } from '@api/index';

// 대상 별 장치 목록 리스트 조회
export const getTargetDeviceListApi = param => reqGet('/v3/api/sh01/target/device', param);

// 생체정보 모니터링 병실 목록 조회
export const getRoomListApi = param => reqGet('/v3/api/sh01/realtime/patients/monitoring/rooms', param);

// 매핑 가능 센서 목록 조회
export const getDeviceListApi = param => reqGet('/v3/api/common/target/device/available/sensors', param);

// 기기 매핑 상세 조회
export const getTargetInfoApi = param => reqGet(`/v3/api/sh01/target/device/${param.targetNum}`, param);

// 디바이스 매핑 등록
export const postDeviceMappingApi = param => reqPost('/v3/api/common/target/device/sensor', param);

// 디바이스 매핑 해제
export const deleteDeviceMappingApi = param => reqDelete(`/v3/api/common/target/device/${param.targetNum}`, param);
